import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useQuery } from 'react-query'
import { fetchMeetingsForProfile } from '~/actions/meetings'
import { getDiscoverServiceProviderProfile } from '~/actions/serviceProviders'
import * as api from '~/api'
import Loading from '../Loading'
import MandateQuestionnaireResponses from '../MandateQuestionnaireResponses'
import ProfileBodyWithTabs from '../ProfileBodyWithTabs'
import ProfileHeaderWithButtons from '../ProfileHeaderWithButtons'
import ServiceProviderClients from '../ServiceProviderPeople/ServiceProviderClients'
import ServiceProviderContact from '../ServiceProviderPeople/ServiceProviderContact'
import ServiceProviderContent from '../ServiceProviderPeople/ServiceProviderContent'
import ServiceProviderTestimonials from '../ServiceProviderPeople/ServiceProviderTestimonials'
import UpcomingMeetingRequests from '../UpcomingMeetingRequests'
import DiscoverServiceProviderProfile from './DiscoverServiceProviderProfile'
import './DiscoverServiceProviderDetailsModal.less'

const defaultFullServiceProviderProfile = {}

const DiscoverServiceProviderDetailsModal = ({
  serviceProvider,
  companyId,
  visible,
  canSendMeetingRequest,
  onSendRequest,
  onSendMessage,
  hideActions = false,
  isOnPreview = false,
  selectedFundId,
  fullServiceProviderProfile = defaultFullServiceProviderProfile,
  mandateApplicationId = null,
}) => {
  const { campaignsModule } = useFlags()
  const [loading, setLoading] = useState(false)
  const [companyProfile, setCompanyProfile] = useState(
    fullServiceProviderProfile
  )
  const [refresh, setRefresh] = useState(false)
  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [meetings, setMeetings] = useState(null)

  const {
    data: applicationQuestionnaireResponses = [],
    isLoading: isQuestionnaireResponsesLoading,
  } = useQuery(
    ['applicationQuestionnaireResponses', mandateApplicationId],
    () => api.mandate.getMandateQuestionnaireResponses(mandateApplicationId),
    {
      enabled: !!mandateApplicationId,
    }
  )

  useEffect(() => {
    if (visible && companyId) {
      fetchMeetingsForProfile(companyId, {
        fundId: selectedFundId,
      })
        .then((res) => {
          setMeetings(res.data.result)
        })
        .finally(() => {
          setLoadingMeetings(false)
        })
    }
  }, [companyId, visible, refresh, selectedFundId])

  useEffect(() => {
    if (visible && isEmpty(fullServiceProviderProfile)) {
      setLoading(true)
      setCompanyProfile({})
      if (isOnPreview) {
        setCompanyProfile(serviceProvider)
        setLoading(false)
      } else {
        getDiscoverServiceProviderProfile(companyId)
          .then((response) => {
            const profile = response.data.result
            setCompanyProfile(profile)
          })
          .catch(() => {
            setCompanyProfile({})
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [
    companyId,
    visible,
    isOnPreview,
    serviceProvider,
    fullServiceProviderProfile,
  ])

  const getTabs = useCallback(() => {
    const tabs = [
      !isEmpty(applicationQuestionnaireResponses)
        ? {
            title: 'Responses',
            key: 'Responses',
            component: (
              <MandateQuestionnaireResponses
                questionnaireResponses={applicationQuestionnaireResponses}
              />
            ),
          }
        : null,
      {
        title: 'Overview',
        component: (
          <DiscoverServiceProviderProfile
            company={companyProfile}
            companyId={companyId}
          />
        ),
      },
      !hideActions
        ? {
            title: 'Meetings',
            component: (
              <UpcomingMeetingRequests
                onRefresh={() => {
                  setRefresh((prevRefresh) => !prevRefresh)
                }}
                meetings={meetings}
                loadingMeetings={loadingMeetings}
              />
            ),
          }
        : null,
      !isEmpty(companyProfile.testimonials)
        ? {
            title: 'Testimonials',
            component: (
              <ServiceProviderTestimonials
                serviceProviders={companyProfile.testimonials}
              />
            ),
          }
        : null,
      !isEmpty(companyProfile.clients)
        ? {
            title: 'Clients',
            component: (
              <ServiceProviderClients
                serviceProviders={companyProfile.clients}
              />
            ),
          }
        : null,
      !isNil(companyProfile.blogUrl) ||
      !isNil(companyProfile.caseStudiesUrl) ||
      !isNil(companyProfile.webCastsUrl) ||
      !isNil(companyProfile.whitepapersAndGuidesUrl)
        ? {
            title: 'Content',
            component: (
              <ServiceProviderContent serviceProviders={companyProfile} />
            ),
          }
        : null,
      {
        title: 'Contact Us',
        component: (
          <ServiceProviderContact
            company={companyProfile}
            onSendRequest={onSendRequest}
            onSendMessage={onSendMessage}
            canSendMeetingRequest={canSendMeetingRequest}
            hideActions={hideActions}
          />
        ),
      },
    ]

    return filter(tabs, (t) => !isNil(t))
  }, [
    canSendMeetingRequest,
    companyId,
    companyProfile,
    hideActions,
    loadingMeetings,
    meetings,
    onSendMessage,
    onSendRequest,
    applicationQuestionnaireResponses,
  ])

  return (
    <div className="discover-service-provider-details-container">
      <Loading spinning={loading || isQuestionnaireResponsesLoading}>
        <ProfileHeaderWithButtons
          name={serviceProvider.companyName}
          address={serviceProvider.address}
          city={serviceProvider.city}
          state={serviceProvider.state}
          hideActions={hideActions}
          canSendMessage={!campaignsModule}
          canSendMeetingRequest={canSendMeetingRequest}
          onSendRequest={onSendRequest}
          onSendMessage={onSendMessage}
        />
        <ProfileBodyWithTabs
          tabs={getTabs()}
          activeTabKey={
            isEmpty(applicationQuestionnaireResponses) ? null : 'Responses'
          }
        />
      </Loading>
    </div>
  )
}

DiscoverServiceProviderDetailsModal.propTypes = {
  onSendRequest: PropTypes.func,
  visible: PropTypes.bool,
  companyId: PropTypes.number,
  canSendMeetingRequest: PropTypes.bool,
  serviceProvider: PropTypes.object,
  onSendMessage: PropTypes.func,
  hideActions: PropTypes.bool,
  isOnPreview: PropTypes.bool,
  selectedFundId: PropTypes.number,
  mandateApplicationId: PropTypes.number,
  fullServiceProviderProfile: PropTypes.object,
}

export default DiscoverServiceProviderDetailsModal
