import { useState } from 'react'
import PropTypes from 'prop-types'
import { Empty, Timeline } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import partition from 'lodash/partition'
import moment from 'moment'
import { ApplyToMandateButton } from '../Application'
import FileCollection from '../FileCollection'
import ProfileBodyWithTabs from '../ProfileBodyWithTabs'
import ProfileHeaderWithButtons from '../ProfileHeaderWithButtons'
import ProfileOverviewTab from '../ProfileOverviewTab'

const PROFILE_LABELS = {
  overview: ['MandateType', 'TargetCompletionDate', 'MinSize'],
  details: ['Size', 'TargetInvestmentRegion'],
  plan: ['PlanAssets'],
}

const MandateProfileContainer = ({
  mandate,
  openDataroom = false,
  hideActions = false,
  onApplyToMandate,
  onAskQuestion,
  canAskQuestion,
  canApplyToMandate,
}) => {
  const [activeTab, setActiveTab] = useState(
    openDataroom ? 'documentation' : 'overview'
  )

  const { campaignsModule } = useFlags()

  const getMandatesTabsData = () =>
    map(mandate.profile, (p) => {
      const key = camelCase(p.groupName)
      const [sideSections, sections] = partition(p.sections, (s) =>
        PROFILE_LABELS[key]?.includes(s.key)
      )
      return {
        title: p.groupName,
        sections,
        sideSections,
      }
    })

  const getTabs = () => {
    const tabsData = getMandatesTabsData()
    const documentationTab = tabsData.find((t) => t.title === 'Documentation')

    const tabs = [
      ...map(
        tabsData,
        (t) =>
          t.title !== 'Documentation' &&
          t.title !== 'Updates' && {
            key: camelCase(t.title),
            title: t.title,
            component: (
              <ProfileOverviewTab
                sections={t.sections}
                sideSections={t.sideSections}
              />
            ),
          }
      ),
      !isEmpty(documentationTab) && {
        key: 'documentation',
        title: 'Documentation',
        component: (
          <MandateDocuments
            mandate={mandate}
            documentationTab={documentationTab.sections[0]}
          />
        ),
      },
      {
        key: 'updates',
        title: 'Updates',
        component: <MandateUpdates mandate={mandate} />,
      },
    ]
    return compact(tabs)
  }

  return (
    <>
      <ProfileHeaderWithButtons
        name={mandate.title}
        hideActions={hideActions}
        canSendMessage={canAskQuestion && !campaignsModule}
        canSendMeetingRequest={canApplyToMandate}
        sendMessageText="Ask a Question"
        status={mandate.status}
        onSendMessage={onAskQuestion}
        customMeetingButton={
          <ApplyToMandateButton
            mandate={mandate}
            onApply={(fundId) => {
              onApplyToMandate(fundId)
            }}
          />
        }
      />
      <ProfileBodyWithTabs
        onTabChange={(tabKey) => {
          setActiveTab(tabKey)
        }}
        activeTabKey={activeTab}
        tabs={getTabs()}
      />
    </>
  )
}

MandateProfileContainer.propTypes = {
  mandate: PropTypes.shape({
    mandateId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    title: PropTypes.string,
    profile: PropTypes.array,
    status: PropTypes.string,
    campaignApplications: PropTypes.array,
    documentUpload: PropTypes.string,
    documentText: PropTypes.string,
    documentAddMean: PropTypes.string,
    isAnonymous: PropTypes.bool,
    contacts: PropTypes.array,
  }),
  hideActions: PropTypes.bool,
  openDataroom: PropTypes.bool,
  onApplyToMandate: PropTypes.func,
  onAskQuestion: PropTypes.func,
  canAskQuestion: PropTypes.bool,
  canApplyToMandate: PropTypes.bool,
}

export default MandateProfileContainer

function MandateUpdates({ mandate }) {
  const updates = [
    ...mandate.mandateUpdates,
    {
      content: 'Mandate added to Context365 by Apex',
      addedOn: mandate.addedOn,
    },
  ]
  return (
    <Timeline style={{ marginTop: '24px' }}>
      {map(updates, (update, i) => (
        <Timeline.Item key={i} color="grey">
          <p>{moment.utc(update.addedOn).format('MMMM DD, YYYY')}</p>
          <p>{update.content}</p>
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

function MandateDocuments({ mandate, documentationTab }) {
  switch (mandate.documentAddMean) {
    case 'Upload it':
      return isNil(mandate.documentUpload) ? (
        <Empty description="No documents attached." />
      ) : (
        <FileCollection
          files={[{ mediaUrl: mandate.documentUpload }]}
          title={mandate.title}
          entityId={mandate.mandateId}
          type="mandate"
        />
      )
    case 'Write it':
    default:
      return isNil(mandate.documentText) ? (
        <Empty description="No documents linked." />
      ) : (
        <div style={{ marginBottom: '24px' }}>
          <span className="type-subtitle-sm text-header">
            {documentationTab?.label}
          </span>
          <div className="cc-profile-info">{documentationTab?.value}</div>
        </div>
      )
  }
}
