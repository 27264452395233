import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import some from 'lodash/some'
import startsWith from 'lodash/startsWith'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { fetchEvents } from '~/actions/events'
import { getConversationValidation } from '~/actions/messages'
import * as api from '~/api'
import ComposeMessageModal from '~/components/ComposeMessageModal'
import {
  AddAllocatorToListModal,
  AddFundToListModal,
  AddServiceProviderToListModal,
} from '~/components/DiscoverListModals'
import {
  BOOLEAN_FILTERS,
  CURRENCY_FIELDS,
  LIST_FIELDS,
  NUMBER_FIELDS,
  PERCENTAGE_FIELDS,
} from '~/components/FiltersView/FiltersView'
import Loading from '~/components/Loading'
import PremiumTierModal from '~/components/PremiumTierModal/PremiumTierModal'
import {
  useDiscoverLists,
  usePrefetchDiscoverLists,
} from '~/hooks/discoverLists'
import useVisibility from '~/hooks/useVisibility'
import { getTier } from '~/selectors/auth'
import { selectedEventHasEnded } from '~/selectors/events'
import SummitMeetingRequestContainer from '../SummitMeetingRequestContainer'
import SummitAttendeeProfilesContainer from './SummitAttendeeProfilesContainer'
import SummitAttendeeTable from './SummitAttendeeTable'
import {
  getAllocatorColumns,
  getFundColumns,
  getSPColumns,
} from './SummitColumns'
import './SummitAttendees.less'

const MEETING_REQUEST_MODAL = 'MEETING_REQUEST'
const SEND_MESSAGE_MODAL = 'SEND_MESSAGE'
const UPGRADE_MODAL = 'UPGRADE_MODAL'

const SummitAttendeeList = ({
  eventId,
  creditsAvailable,
  title,
  category,
  contextPoints,
  isOptimized,
}) => {
  const dispatch = useDispatch()
  const role = useSelector((state) => state.auth.role)
  const pastSummit = useSelector(selectedEventHasEnded)
  const [detailsVisible, setDetailsVisibility] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [filterQueryStrings, setFilterQueryStrings] = useState(null)
  const [filterArray, setFilterArray] = useState([])
  const [activeModal, setActiveModal] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [filterOptions, setFilterOptions] = useState(null)

  useEffect(() => {
    api.filters.getSummitFiltersData(category).then((res) => {
      const opts = reduce(
        res.data.result,
        (obj, param) => {
          if (!isNil(param.values)) {
            obj[param.field] = param.values
          }
          return obj
        },
        {}
      )
      setFilterOptions(opts)
    })
  }, [category])

  const { Track, trackEvent } = useTracking({
    component: 'SummitAttendeeList',
  })
  const tier = useSelector(getTier)

  const handleSendMessageClick = useCallback(
    (company, conversationId) => {
      trackEvent({
        eventName: 'click',
        element: 'send message from dropdown menu',
      })
      if (
        role === 'Allocator' ||
        role === 'Context' ||
        (role === 'Manager' && category !== 'allocators')
      ) {
        if (isNil(conversationId) || conversationId === 0) {
          setSelectedCompany(company)
          setActiveModal(SEND_MESSAGE_MODAL)
        } else {
          getConversationValidation(conversationId)
            .then((response) => {
              const validationResponse = response.data.result
              if (validationResponse.canSendMessage) {
                setSelectedCompany(company)
                setActiveModal(SEND_MESSAGE_MODAL)
              } else {
                message.error(validationResponse.reason)
              }
            })
            .catch(() => {
              message.error(
                'There was an error while attempting to get conversation information'
              )
            })
        }
      } else {
        getConversationValidation(conversationId)
          .then((response) => {
            const validationResponse = response.data.result
            if (validationResponse.canSendMessage) {
              setSelectedCompany(company)
              setActiveModal(SEND_MESSAGE_MODAL)
            } else {
              message.error(validationResponse.reason)
            }
          })
          .catch(() => {
            message.error(
              'There was an error while attempting to get conversation information'
            )
          })
      }
    },
    [category, role, trackEvent]
  )

  const handleSendMeetingRequest = useCallback(
    (company) => {
      trackEvent({
        eventName: 'click',
        element: 'send meeting request from dropdown menu',
      })
      if (
        creditsAvailable > 0 ||
        role.toLowerCase() === 'allocator' ||
        role.toLowerCase() === 'context'
      ) {
        setActiveModal(MEETING_REQUEST_MODAL)
        setSelectedCompany(company)
      }
    },
    [creditsAvailable, role, trackEvent]
  )

  usePrefetchDiscoverLists()
  const { discoverLists } = useDiscoverLists()

  const addAllocatorToListModal = useVisibility()
  const addFundToListModal = useVisibility()
  const addSPToListModal = useVisibility()
  const [addToListAllocators, setAddToListAllocators] = useState([])
  const [addToListFunds, setAddToListFunds] = useState([])
  const [addToListCompanies, setAddToListCompanies] = useState([])
  const showAddAllocatorToListModal = useCallback(
    (allocator) => {
      setAddToListAllocators([allocator])
      addAllocatorToListModal.show()
    },
    [addAllocatorToListModal]
  )
  const showAddFundToListModal = useCallback(
    (fund) => {
      setAddToListFunds([fund])
      addFundToListModal.show()
    },
    [addFundToListModal]
  )
  const showAddCompanyToListModal = useCallback(
    (company) => {
      setAddToListCompanies([company])
      addSPToListModal.show()
    },
    [addSPToListModal]
  )
  const closeAddToListModal = useCallback(() => {
    addAllocatorToListModal.hide()
    addFundToListModal.hide()
    addSPToListModal.hide()
  }, [addAllocatorToListModal, addFundToListModal, addSPToListModal])

  const openDetailsModal = useCallback((company) => {
    setDetailsVisibility(true)
    setSelectedCompany(company)
  }, [])

  const handleOnClickRecord = useCallback(
    (company) => {
      trackEvent({
        eventName: 'click',
        element: 'attendee record - open profile',
        attendee: company,
        category,
      })

      openDetailsModal(company)
    },
    [category, openDetailsModal, trackEvent]
  )

  const getTableData = useCallback(
    (page, pageSize, searchQuery, orderBy, filter) => {
      setLoading(true)

      api.summitAttendees
        .getList(eventId, category, {
          page,
          pageSize,
          searchQuery,
          orderBy,
          filter,
        })
        .then((response) => {
          const { results, itemCount } = response.data.result
          setDataSource(results)
          setTotal(itemCount)
        })
        .catch((error) => {
          const msg = isNil(error.response)
            ? get(error, 'message', 'Could not retrieve attendee info.')
            : get(
                error,
                'response.data.message',
                'Could not retrieve attendee info.'
              )
          message.error(msg)
        })
        .finally(() => setLoading(false))
    },
    [category, eventId]
  )

  useEffect(() => {
    getTableData(page, pageSize, searchQuery, orderBy, filterQueryStrings)
  }, [filterQueryStrings, getTableData, orderBy, page, pageSize, searchQuery])

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current)
    setPageSize(pagination.pageSize)

    trackEvent({
      eventName: 'change',
      element: 'attendees table',
      category,
      pagination,
      filters,
      sorter,
    })

    if (!isEmpty(sorter)) {
      setOrderBy(`${sorter.order === 'descend' ? '-' : ''}${sorter.field}`)
    }
    if (isEmpty(filters)) {
      setFilterQueryStrings(null)
      setFilterArray(null)
    } else {
      setFilterArray(filters)
      const filterList = flatMap(filters, (v, k) => {
        if (
          (includes(NUMBER_FIELDS, k) ||
            includes(PERCENTAGE_FIELDS, k) ||
            includes(CURRENCY_FIELDS, k)) &&
          !isNil(v)
        ) {
          const [lower, upper] = v
          const fieldFilters = []

          if (!isNil(lower)) {
            const lowerValue =
              k === 'matchmakingScore' ||
              k === 'annualizedReturn' ||
              k === 'annualVolatility'
                ? lower / 100
                : lower
            fieldFilters.push(`${k} ge ${lowerValue}`)
          }

          if (!isNil(upper)) {
            const upperValue =
              k === 'matchmakingScore' ||
              k === 'annualizedReturn' ||
              k === 'annualVolatility'
                ? upper / 100
                : upper
            fieldFilters.push(`${k} le ${upperValue}`)
          }

          return fieldFilters
        } else if (some(LIST_FIELDS, (field) => startsWith(k, field))) {
          return isEmpty(v) ? [] : [`${k} in ${v.join(',')}`]
        } else {
          const operator = includes(BOOLEAN_FILTERS, k) ? 'eq' : 'like'
          const transformer = includes(BOOLEAN_FILTERS, k)
            ? (val) => val === 'Yes'
            : (val) => `%${val}%`
          return map(v, (val) => `${k} ${operator} ${transformer(val)}`)
        }
      })
      setFilterQueryStrings(filterList)
    }
  }

  const hideModal = useCallback(() => {
    setActiveModal(null)
  }, [])

  const showMeetingRequestModal = () => {
    //passed as prop for profile
    if (
      role.toLowerCase() === 'manager' &&
      pastSummit &&
      tier.toLowerCase() !== 'pro'
    ) {
      setActiveModal(UPGRADE_MODAL)
    } else {
      setActiveModal(MEETING_REQUEST_MODAL)
    }
  }

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery)
    setPage(1)
    trackEvent({
      eventName: 'change',
      element: 'search attendees table',
      category,
      searchQuery,
    })
  }

  const newColumns =
    category === 'funds'
      ? getFundColumns(
          handleOnClickRecord,
          handleSendMessageClick,
          handleSendMeetingRequest,
          discoverLists,
          showAddFundToListModal,
          dispatch,
          filterOptions,
          role,
          pastSummit
        )
      : category === 'allocators'
      ? getAllocatorColumns(
          handleOnClickRecord,
          handleSendMessageClick,
          handleSendMeetingRequest,
          discoverLists,
          showAddAllocatorToListModal,
          dispatch,
          filterOptions,
          role,
          creditsAvailable,
          pastSummit
        )
      : getSPColumns(
          handleOnClickRecord,
          handleSendMessageClick,
          handleSendMeetingRequest,
          discoverLists,
          showAddCompanyToListModal,
          dispatch,
          filterOptions,
          pastSummit,
          role
        )

  const newTableColumns = useMemo(() => newColumns, [newColumns])

  return (
    <Track>
      <AddAllocatorToListModal
        visible={addAllocatorToListModal.visible}
        allocators={addToListAllocators}
        onClose={closeAddToListModal}
      />
      <AddFundToListModal
        visible={addFundToListModal.visible}
        funds={addToListFunds}
        onClose={closeAddToListModal}
      />
      <AddServiceProviderToListModal
        visible={addSPToListModal.visible}
        serviceProviders={addToListCompanies}
        onClose={closeAddToListModal}
      />
      <div className="SummitAttendees">
        <Loading spinning={isLoading}>
          <SummitAttendeeTable
            useColumnCustomization
            columns={newTableColumns}
            data={dataSource}
            currentPage={page}
            pageSize={pageSize}
            total={total}
            onTableChange={handleTableChange}
            onSearch={handleSearch}
            tableTitle={title}
            loadPage={(page, size) => {
              handleTableChange(
                { current: page, pageSize: size, total },
                filterArray,
                null
              )
              return true
            }}
            staticColumns={filter(newTableColumns, 'fixed')}
          />
        </Loading>
        {activeModal === UPGRADE_MODAL && (
          <PremiumTierModal
            bypassContainer
            closable
            visible={true}
            onModalClosed={hideModal}
          />
        )}
        {activeModal === MEETING_REQUEST_MODAL && (
          <SummitMeetingRequestContainer
            visible={!isNil(selectedCompany)}
            onClose={hideModal}
            onRefresh={() => {
              getTableData(
                page,
                pageSize,
                searchQuery,
                orderBy,
                filterQueryStrings
              )
              dispatch(fetchEvents(eventId))
              setDetailsVisibility(false)
            }}
            company={selectedCompany}
            fund={category === 'funds' ? selectedCompany : null}
            eventId={eventId}
            creditsAvailable={creditsAvailable}
            isOptimized={isOptimized}
            contextPointsAvailable={contextPoints}
          />
        )}
        {activeModal === SEND_MESSAGE_MODAL && (
          <ComposeMessageModal
            visible={true}
            onClose={hideModal}
            companyID={selectedCompany.companyId}
            eventID={eventId}
            conversationId={selectedCompany?.meeting?.conversationId}
            companyName={selectedCompany?.name || selectedCompany?.companyName}
            showContactsAttendingEvent={!pastSummit}
          />
        )}
        {detailsVisible && !isNil(selectedCompany) && (
          <SummitAttendeeProfilesContainer
            category={category}
            role={role}
            selectedRecord={selectedCompany}
            visible={detailsVisible}
            hideModal={() => setDetailsVisibility(false)}
            onSendMessage={() => {
              handleSendMessageClick(
                selectedCompany,
                get(selectedCompany, 'meeting.conversationId', 0)
              )
            }}
            onSendMeetingRequest={showMeetingRequestModal}
            pastSummit={pastSummit}
            eventId={eventId}
          />
        )}
      </div>
    </Track>
  )
}

SummitAttendeeList.propTypes = {
  eventId: PropTypes.number.isRequired,
  creditsAvailable: PropTypes.number.isRequired,
  contextPoints: PropTypes.number.isRequired,
  title: PropTypes.oneOf([
    'Allocators',
    'Funds',
    'Service Providers',
    'Context',
  ]),
  category: PropTypes.oneOf([
    'allocators',
    'funds',
    'service-providers',
    'context',
  ]),
  isOptimized: PropTypes.bool,
}

export default SummitAttendeeList
