import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  InputNumber,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from 'antd'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import moment from 'moment'
import SimpleBar from 'simplebar-react'
import * as Yup from 'yup'
import * as api from '~/api'
import './ApproveReturns.less'

const { Title, Text } = Typography

const ApproveReturns = ({ fundUpdates, onAllApproved }) => {
  const [step, setStep] = useState(0)
  const [manualEdit, setManualEdit] = useState(false)

  const schema = Yup.array()
    .of(
      Yup.object().shape({
        year: Yup.number()
          .required('Year is required.')
          .min(1990, 'Please provide a recent year.'),
        month: Yup.number()
          .required('Month is required.')
          .min(
            1,
            'The month should be a number from 1 (January) to 12 (December).'
          )
          .max(
            12,
            'The month should be a number from 1 (January) to 12 (December).'
          ),
        return: Yup.number('Return should be a number.')
          .typeError('Return should be a number.')
          .required('Return is required.'),
        aum: Yup.number('AUM should be a number.')
          .typeError('AUM should be a number.')
          .required('AUM is required.')
          .positive('AUM cannot be negative or zero.'),
      })
    )
    .required()
    .min(1)

  const formattedReturns = (data) =>
    map(data.month, (month, i) => ({
      date: moment(`${month}-${data.year[i]}`, 'MM-YYYY')
        .endOf('month')
        .format('MMM Do, YYYY'),
      month: data.month[i],
      year: data.year[i],
      return: data.return[i],
      aum: data.aum[i],
    }))

  const handleManualEdit = useCallback(
    (values) => {
      const returns = {
        month: [],
        year: [],
        return: [],
        aum: [],
      }

      forEach(values, (val) => {
        returns.month.push(val.month)
        returns.year.push(val.year)
        returns.return.push(val.return)
        returns.aum.push(val.aum)
      })

      api.fundCharts.logInIfNeeded().then(() => {
        api.fundCharts
          .updateMultipleMonthReturns(fundUpdates[step].fundid, returns)
          .then(() => {
            message.success('Successfully updated returns')
            api.fundCharts.updateFundStats(fundUpdates[step].fundid)
            api.fundCharts.updateFundReturns(fundUpdates[step].fundid, true, {
              month: returns.month,
              year: returns.year,
            })
            if (step < fundUpdates.length - 1) {
              setStep(step + 1)
              setManualEdit(false)
            } else onAllApproved()
          })
          .catch(() => {
            message.error('An error occurred while updating returns')
          })
      })
    },
    [fundUpdates, step, onAllApproved]
  )

  const approveEmailReturns = () => {
    const dates = {
      month: fundUpdates[step].data.month,
      year: fundUpdates[step].data.year,
    }

    api.fundCharts.logInIfNeeded().then(() => {
      api.fundCharts
        .approveEmailReturnMultiMonth(fundUpdates[step].fundid, dates)
        .then(() => {
          message.success('Successfully updated returns')
          api.fundCharts.updateFundStats(fundUpdates[step].fundid)
          api.fundCharts.updateFundReturns(
            fundUpdates[step].fundid,
            true,
            dates
          )
          if (step < fundUpdates.length - 1) setStep(step + 1)
          else onAllApproved()
        })
        .catch(() => {
          message.error('An error occurred while updating returns')
        })
    })
  }

  return (
    <div className="ApproveReturns">
      <div className="ApproveReturns-title">
        {`Please review the updated data for the fund (${step + 1} of ${
          fundUpdates.length
        }):`}
      </div>
      <div className="ApproveReturns-name">{fundUpdates[step].fundname}</div>
      <div className="ApproveReturns-light-text">
        Time of update:{' '}
        <strong style={{ color: 'black' }}>
          {moment
            .utc(fundUpdates[step].data.lastdateupdated_utc[0])
            .local()
            .format('MM/DD/YYYY h:mm a')}
        </strong>
      </div>
      <div className="ApproveReturns-light-text">Updated Data</div>
      <Formik
        enableReinitialize
        initialValues={formattedReturns(fundUpdates[step].data)}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleManualEdit}
      >
        {({ values, submitForm, isSubmitting, setTouched }) => (
          <Form>
            <Row>
              <Col span={8}>
                <Title level={5}>Month End Date</Title>
              </Col>
              <Col span={8}>
                <Title level={5}>Return (%)</Title>
              </Col>
              <Col span={8}>
                <Title level={5}>AUM</Title>
              </Col>
            </Row>
            <hr
              style={{
                border: 0,
                height: 0,
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            />
            <SimpleBar style={{ maxHeight: 480 }}>
              <FieldArray
                style={{ overflowX: 'hidden' }}
                name="returns"
                render={() => (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {values.map((ret, i) => (
                      <Row key={ret.date}>
                        <Col span={8}>
                          <Text strong>{ret.date}</Text>
                        </Col>
                        <Col span={8}>
                          {!manualEdit ? (
                            <Text>
                              {ret.return.toString().replace(/[^-\d.]/g, '')}
                            </Text>
                          ) : (
                            <>
                              <Field name={`${i}.return`}>
                                {({ field, form }) => (
                                  <InputNumber
                                    formatter={(value) =>
                                      value !== '' ? `${value}%` : value
                                    }
                                    parser={(value) =>
                                      value.replace(/[^-\d.]/g, '')
                                    }
                                    className="full-width"
                                    values={field.value}
                                    defaultValue={ret.return}
                                    onChange={(value) =>
                                      form.setFieldValue(`${i}.return`, value)
                                    }
                                    onBlur={() =>
                                      form.setFieldTouched(`${i}.return`)
                                    }
                                  />
                                )}
                              </Field>
                              <ErrorMessage name={`${i}.return`}>
                                {(msg) => <Text type="danger">{msg}</Text>}
                              </ErrorMessage>
                            </>
                          )}
                        </Col>
                        <Col span={8}>
                          {!manualEdit ? (
                            <Text>
                              $
                              {!isNil(ret.aum)
                                ? ret.aum
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''}
                            </Text>
                          ) : (
                            <>
                              <Field name={`${i}.aum`}>
                                {({ field, form }) => (
                                  <InputNumber
                                    formatter={(value) =>
                                      `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                      )
                                    }
                                    parser={(value) =>
                                      value.replace(/\$\s?|(,*)/g, '')
                                    }
                                    className="full-width"
                                    value={field.value}
                                    defaultValue={ret.aum}
                                    onChange={(value) => {
                                      form.setFieldValue(`${i}.aum`, value)
                                      form.setFieldTouched(`${i}.aum`)
                                    }}
                                    onBlur={() =>
                                      form.setFieldTouched(`${i}.aum`)
                                    }
                                  />
                                )}
                              </Field>
                              <ErrorMessage name={`${i}.aum`}>
                                {(msg) => <Text type="danger">{msg}</Text>}
                              </ErrorMessage>
                            </>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Space>
                )}
              />
            </SimpleBar>
            <hr
              style={{
                border: 0,
                height: 0,
                borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            />
            <Col span={8}>
              <Tooltip
                overlayClassName="ApproveReturns-Disclaimer-Tooltip"
                title={
                  <div>
                    You have requested that Apex US Distribution LLC input
                    certain information that you have provided, including
                    updated fund(s) performance information, into the Context365
                    by Apex software platform. Accordingly, you agree to
                    promptly review such information as it appears in the
                    Context365 by Apex software platform and immediately notify
                    Apex US Distribution LLC of any errors or omissions
                    resulting from Apex US Distribution LLC&apos;s entry of the
                    associated data. You further acknowledge that your sole
                    remedy for any such errors or omissions is for Apex US
                    Distribution LLC to promptly correct such errors or
                    omissions upon receipt of notice from you and Apex US
                    Distribution LLC shall have no further liability with
                    respect thereto. You always retain the ability to update the
                    fund(s) information yourself, if you choose.
                  </div>
                }
              >
                <div className="Disclaimer">Disclaimer</div>
              </Tooltip>
            </Col>
            <Col span={16} />
            {manualEdit && (
              <Row>
                <Col span={16} />
                <Col span={8} className="right-align">
                  <Button
                    type="primary"
                    htmlType="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      const touchedFields = map(values, () => ({
                        return: true,
                        aum: true,
                      }))

                      submitForm().catch(setTouched(touchedFields))
                    }}
                  >
                    Save Returns
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
      {!manualEdit && (
        <>
          {fundUpdates[step].data.canapprove.some((x) => x === 0) ? (
            <Tooltip title="We have detected an error in your uploaded data, please edit manually.">
              <Button
                type="primary"
                disabled={true}
                className="ApproveReturns-publish"
              >
                Looks great, publish the results!
              </Button>
            </Tooltip>
          ) : (
            <Button
              type="primary"
              className="ApproveReturns-publish"
              onClick={() => approveEmailReturns()}
            >
              Looks great, publish the results!
            </Button>
          )}
          <div className="ApproveReturns-light-text">Adjustments needed?</div>
          <Button
            type="primary"
            onClick={() => setManualEdit(true)}
            className="ApproveReturns-edit"
          >
            Edit manually
          </Button>
        </>
      )}
    </div>
  )
}

ApproveReturns.propTypes = {
  fundUpdates: PropTypes.array,
  onAllApproved: PropTypes.func,
}

export default ApproveReturns
