import { Button, IconButton } from '@context365/button'
import { CheckCircleOutline, Launch, LocalPolice } from '@context365/icons'
import { Link } from 'react-router-dom'
import WidgetBlock from './WidgetBlock'

const Bullet = ({ text }) => (
  <div className="flex flex-row gap-4">
    <CheckCircleOutline className="text-secondary-100" />
    {text}
  </div>
)

const Subscriptions = () => {
  return (
    <WidgetBlock
      headerLeft={
        <div className="flex flex-row items-center gap-2">
          <LocalPolice className="text-gold-100" />
          Subscriptions
        </div>
      }
      headerRight={
        <IconButton
          label="subscriptions"
          variant="link"
          as={Link}
          to="/settings/subscriptions"
          icon={<Launch />}
          style={{ padding: 0 }}
          status="secondary"
        />
      }
    >
      <div>
        Get more out of 365 by upgrading your subscription to unlock premium
        features
      </div>
      <div className="mt-4 shadow-0">
        <div className="p-2 bg-secondary-5 text-center type-header-sm border-t-8 border-secondary-100 rounded-t">
          Starter
        </div>
        <div className="flex flex-col gap-4 p-4 border rounded-b">
          <Bullet text="Apply to unlimited mandates" />
          <Bullet text="Post content" />
          <Bullet text="Unlimited community interaction" />
          <div className="flex flex-row justify-center">
            <Button
              status="secondary"
              variant="filled"
              as={Link}
              to="/settings/subscriptions"
            >
              Upgrade to Starter
            </Button>
          </div>
        </div>
      </div>
    </WidgetBlock>
  )
}

export default Subscriptions
