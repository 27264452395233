import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { EmailOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import pick from 'lodash/pick'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { fetchMeetingsForProfile } from '~/actions/meetings'
import * as api from '~/api'
import { SP500_FUND_INDEX_ID } from '~/constants/indexFunds'
import { ROLE } from '~/constants/roles'
import { ApplyToCampaignButton } from '../Application'
import FundAttachments from '../FundAttachments'
import FundCharts from '../FundCharts'
import FundDetailsManagers from '../FundDetailsManagers'
import { FundDetailsProfile } from '../FundDetailsProfile'
import FundDetailsStaticValues from '../FundDetailsValues/FundDetailsStaticValues'
import FundMedia from '../FundMedia'
import FundPreviewList from '../FundPreviewList'
import IlliquidProfileFirm from '../IlliquidProfileFirm/IlliquidProfileFirm'
import IlliquidProfileOverview from '../IlliquidProfileOverview/IlliquidProfileOverview'
import MandateQuestionnaireResponses from '../MandateQuestionnaireResponses'
import ProfileBodyWithTabs from '../ProfileBodyWithTabs'
import ProfileDisclaimerTab from '../ProfileDisclaimerTab'
import ProfileHeaderWithButtons from '../ProfileHeaderWithButtons'
import ProfileModal from '../ProfileModal/ProfileModal'
import UpcomingMeetingRequests from '../UpcomingMeetingRequests'
import FundApplicationFeedback from './FundApplicationFeedback'
import profileToAnswers from './profileToAnswers'
import './FundDetailsModal.less'

export const DISCLAIMER =
  'The provision of any information regarding any fund or fund manager (the "Fund Information") to you is for informational purposes only and does not constitute any offer to sell or the solicitation of an offer to buy any securities or investments. Apex US Distribution LLC makes no representation about, and does not independently verify, the Fund Information and shall not be responsible for its accuracy, completeness or your reliance thereon. The Fund Information is self-reported by the associated fund or fund manager to Context. Context is not making any recommendation, express or implied, as to the suitability or otherwise of any investment with, and is not acting as agent, promoter, underwriter or placement agent for, any fund or the fund manager, or any other client represented by the fund manager. You must assess the suitability of any particular fund or strategy and carry out appropriate due diligence on your own behalf in relation to the same and Context provides no advice in that regard. Context makes no representation as to the fund’s or fund manager’s compliance with any applicable laws or regulations. Context shall not be liable for any losses that may arise, directly or indirectly, from your introduction to, or investment with, any fund or fund manager.'

const defaultFullFundProfile = {}

export const LiquidTabs = [
  {
    component: MandateQuestionnaireResponses,
    title: 'Responses',
    key: 'responses',
    getProps: (data) => {
      return {
        questionnaireResponses: get(
          data,
          'applicationQuestionnaireResponses',
          []
        ),
      }
    },
    visible: (data) => !isEmpty(data.applicationQuestionnaireResponses),
  },
  {
    component: FundApplicationFeedback,
    title: 'Feedback',
    key: 'feedback',
    getProps: (data) =>
      pick(data, [
        'applicationFeedbackResponses',
        'applicationFeedbackMessage',
      ]),
    visible: (data) => data.applicationFeedbackResponses,
  },
  {
    component: FundDetailsProfile,
    title: 'Fund Profile',
    key: 'profile',
    getProps: (data) => pick(data, ['fundProfile']),
    visible: () => true,
  },
  {
    component: UpcomingMeetingRequests,
    title: 'Meetings',
    key: 'meetings',
    getProps: (data) =>
      pick(data, ['onRefresh', 'eventId', 'meetings', 'loadingMeetings']),
    visible: (data) => !data.hideActions && !!data.eventId,
  },
  {
    component: FundDetailsStaticValues,
    title: 'Terms',
    key: 'terms',
    getProps: (data) => pick(data, ['answers']),
    visible: () => true,
  },
  {
    component: FundDetailsManagers,
    title: 'Team Bios',
    key: 'firm',
    getProps: (data) => pick(data, ['managerBio', 'loading']),
    visible: () => true,
  },
  {
    component: FundCharts,
    title: 'Performance',
    key: 'performance',
    getProps: (data) => pick(data, ['fundId', 'indexFund']),
    visible: (data) => data.showCharts,
  },
  {
    component: FundAttachments,
    title: 'Attachments',
    key: 'attachments',
    getProps: (data) => pick(data, ['fundId', 'companyName', 'eventId']),
    visible: () => true,
  },
  {
    component: FundMedia,
    title: 'Media',
    key: 'media',
    getProps: (data) => pick(data, ['media', 'links']),
    visible: (data) => data.showMedia,
  },
  {
    component: FundPreviewList,
    title: 'Similar Funds',
    key: 'similarFunds',
    getProps: (data) => {
      return {
        getFunds: data.getSimilarFunds,
        emptyDescription: 'There are no similar funds.',
        hideMatchmakingScore: true,
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    component: FundPreviewList,
    title: 'Family of Funds',
    key: 'otherFunds',
    getProps: (data) => {
      return {
        getFunds: data.getFundsAtCompany,
        emptyDescription: 'There are no other funds at this company.',
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    component: ProfileDisclaimerTab,
    title: 'Disclaimer',
    key: 'disclaimer',
    getProps: (data) => pick(data, ['disclaimer', 'customDisclaimerHtml']),
    visible: () => true,
  },
]

export const IlliquidTabs = [
  {
    component: MandateQuestionnaireResponses,
    title: 'Responses',
    key: 'responses',
    getProps: (data) => {
      return {
        questionnaireResponses: get(
          data,
          'applicationQuestionnaireResponses',
          []
        ),
      }
    },
    visible: (data) => !isEmpty(data.applicationQuestionnaireResponses),
  },
  {
    component: FundApplicationFeedback,
    title: 'Feedback',
    key: 'feedback',
    getProps: (data) =>
      pick(data, [
        'applicationFeedbackResponses',
        'applicationFeedbackMessage',
      ]),
    visible: (data) => !isEmpty(data.applicationFeedbackResponses),
  },
  {
    component: FundDetailsProfile,
    title: 'Fund Profile',
    key: 'profile',
    getProps: (data) => pick(data, ['fundProfile']),
    visible: (data) => !data.illiquidProfileFlag,
  },
  {
    component: IlliquidProfileOverview,
    title: 'Fund Profile',
    key: 'profile',
    getProps: (data) => pick(data, ['fundProfile', 'eventId']),
    visible: (data) => data.illiquidProfileFlag,
  },
  {
    component: UpcomingMeetingRequests,
    title: 'Meetings',
    key: 'meetings',
    getProps: (data) =>
      pick(data, ['onRefresh', 'eventId', 'meetings', 'loadingMeetings']),
    visible: (data) => !data.hideActions && !!data.eventId,
  },
  {
    component: FundDetailsStaticValues,
    title: 'Terms',
    key: 'terms',
    getProps: (data) => pick(data, ['answers']),
    visible: (data) => !data.illiquidProfileFlag,
  },
  {
    component: FundDetailsManagers,
    title: 'Team Bios',
    key: 'teamBios',
    getProps: (data) => pick(data, ['managerBio', 'loading']),
    visible: (data) => !data.illiquidProfileFlag,
  },
  {
    component: IlliquidProfileFirm,
    title: 'Firm',
    key: 'firm',
    getProps: (data) => pick(data, ['fundProfile']),
    visible: (data) => data.illiquidProfileFlag,
  },
  {
    component: FundCharts,
    title: 'Performance',
    key: 'performance',
    getProps: (data) => pick(data, ['fundId', 'indexFund']),
    visible: (data) => !data.illiquidProfileFlag && data.showCharts,
  },
  {
    component: FundAttachments,
    title: 'Attachments',
    key: 'attachments',
    getProps: (data) => pick(data, ['fundId', 'companyName', 'eventId']),
    visible: () => true,
  },
  {
    component: FundMedia,
    title: 'Media',
    key: 'media',
    getProps: (data) => pick(data, ['media', 'links']),
    visible: (data) => data.showMedia,
  },
  {
    component: FundPreviewList,
    title: 'Similar Funds',
    key: 'similarFunds',
    getProps: (data) => {
      return {
        getFunds: data.getSimilarFunds,
        emptyDescription: 'There are no similar funds.',
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    component: FundPreviewList,
    title: 'Family of Funds',
    key: 'otherFunds',
    getProps: (data) => {
      return {
        getFunds: data.getFundsAtCompany,
        emptyDescription: 'There are no other funds at this company.',
      }
    },
    visible: (data) => !data.hideActions,
  },
  {
    component: ProfileDisclaimerTab,
    title: 'Disclaimer',
    key: 'disclaimer',
    getProps: (data) => pick(data, ['disclaimer', 'customDisclaimerHtml']),
    visible: () => true,
  },
]

function getDefaultTab(showDataroom, mandateApplicationId) {
  return showDataroom
    ? 'attachments'
    : isNil(mandateApplicationId)
    ? 'profile'
    : 'responses'
}

const FundDetailsModal = ({
  fundId,
  fundName,
  fundThreshold,
  campaignApplications,
  campaignId,
  onSendRequest,
  onSendMessage,
  canSendMeetingRequest = true,
  canSendMessage = true,
  visible,
  meeting,
  contacts,
  pastSummit = false,
  eventId = null,
  blockedRequest = false,
  onRefresh = () => {},
  showDataroom = false,
  mandateApplicationId = null,
  hideActions = false,
  fullFundProfile = defaultFullFundProfile,
  isSubscribed = false,
  onSubscribe,
  onClose = () => {},
}) => {
  const { trackEvent } = useTracking({ component: 'FundDetailsModal' })
  const [fundProfile, setFundProfile] = useState(fullFundProfile)
  const [loading, setLoading] = useState(false)
  const [isValidSpMeetingRequest, setIsValidSpMeetingRequest] = useState(false)
  const [activeTabKey, setActiveTabKey] = useState(
    getDefaultTab(showDataroom, mandateApplicationId)
  )

  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [meetings, setMeetings] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const { role } = useSelector((state) => state.auth)
  const [notValidReason, setNotValidReason] = useState('')

  const { campaignsModule } = useFlags()

  const answers = profileToAnswers(fundProfile)

  useEffect(() => {
    if (fundProfile.companyId && fundId) {
      setLoadingMeetings(true)
      fetchMeetingsForProfile(fundProfile.companyId, {
        eventId: isNil(eventId) ? 0 : eventId,
        fundId,
      })
        .then((res) => {
          setMeetings(res.data.result)
        })
        .catch(() => {})
        .finally(() => {
          setLoadingMeetings(false)
        })
    }
  }, [eventId, fundId, visible, refresh, fundProfile])

  const loadFundProfile = mandateApplicationId
    ? api.funds.getFundProfileForMandateApplication
    : api.funds.getFundProfile

  useEffect(() => {
    if (visible) {
      if (isEmpty(fullFundProfile)) {
        setLoading(true)
        setFundProfile({})
        loadFundProfile(fundId, mandateApplicationId)
          .then((response) => {
            setLoading(false)
            const profile = response
            setFundProfile(profile)
          })
          .catch(() => {
            setLoading(false)
            setFundProfile({})
          })
      }
    }
  }, [
    visible,
    showDataroom,
    fundId,
    fullFundProfile,
    mandateApplicationId,
    loadFundProfile,
  ])

  useEffect(() => {
    if (role === ROLE.SERVICE_PROVIDER) {
      api.meeting
        .checkCanSendMeetingRequest({
          toCompanyId: meeting?.companyId,
          fundId: meeting?.fundId,
        })
        .then((validationResponse) => {
          if (validationResponse.isValid) {
            setIsValidSpMeetingRequest(true)
            setNotValidReason('')
          } else {
            setIsValidSpMeetingRequest(false)
            setNotValidReason(validationResponse.reason)
          }
        })
    }
  }, [meeting, role])

  const onTabChange = useCallback((tabKey) => {
    trackEvent({ eventName: 'click', element: tabKey, fundId })
    setActiveTabKey(tabKey)
  }, [])

  const handleRefresh = useCallback(() => {
    setRefresh((prevRefresh) => !prevRefresh)
    onRefresh()
  }, [onRefresh])

  const getSimilarFunds = useCallback(() => {
    return api.discovery.similarFunds(fundId)
  }, [fundId])

  const getFundsAtCompany = useCallback(() => {
    return api.discovery.funds({
      filter: [`companyId eq ${fundProfile.companyId}`, `fundId ne ${fundId}`],
    })
  }, [fundProfile.companyId, fundId])

  const data = {
    fundId,
    fundProfile,
    onRefresh: handleRefresh,
    eventId,
    meetings,
    loadingMeetings,
    answers,
    loading,
    managerBio: fundProfile.managerBio,
    indexFund: SP500_FUND_INDEX_ID,
    companyName: fundProfile.companyName,
    showCharts: !isEmpty(fundProfile) && !fundProfile.narrative,
    media: fundProfile.media,
    links: fundProfile.links,
    showMedia: !isEmpty(fundProfile.media) || !isEmpty(fundProfile.links),
    disclaimer: DISCLAIMER,
    customDisclaimerHtml: fundProfile.customDisclaimer,
    illiquidProfileFlag: fundProfile.utilizesNewIlliquidProfile,
    hideActions,
    getFundsAtCompany,
    getSimilarFunds,
    applicationFeedbackResponses: fundProfile.applicationFeedbackResponses,
    applicationFeedbackMessage: fundProfile.applicationFeedbackMessage,
    applicationQuestionnaireResponses:
      fundProfile.applicationQuestionnaireResponses,
  }

  const actions = [
    {
      text: 'View Tearsheet',
      clickHandle: () => window.open(`/tearsheet/${fundId}`),
      visible: true,
    },
    !campaignsModule && {
      text: 'Send Message',
      clickHandle: onSendMessage,
      visible: !hideActions && canSendMessage,
    },
  ]

  const tabs = fundProfile.isLiquid ? LiquidTabs : IlliquidTabs

  return (
    <ProfileModal
      visible={visible}
      onClose={onClose}
      header={
        <ProfileHeaderWithButtons
          hideActions={false}
          name={fundProfile.fundName}
          role={fundProfile.companyName}
          city={fundProfile.companyLocation}
          actions={filter(actions, 'visible')}
          canSendMessage={false}
          canSendMeetingRequest={!hideActions}
          subscribeButton={
            !hideActions &&
            !isNil(onSubscribe) && (
              <Tooltip
                text={
                  isSubscribed
                    ? 'You are already subscribed to this fund'
                    : 'Subscribe to this fund manager’s email distribution list'
                }
              >
                <Button
                  disabled={isSubscribed}
                  iconLeft={<EmailOutlined />}
                  onClick={() => {
                    trackEvent({
                      eventName: 'click',
                      element: 'Subscribe to fund',
                      fundId,
                    })
                    onSubscribe()
                  }}
                >
                  Subscribe
                </Button>
              </Tooltip>
            )
          }
          customMeetingButton={
            !hideActions && isNil(eventId) && campaignsModule ? (
              <ApplyToCampaignButton
                campaignEntity={{
                  campaignId,
                  campaignName: fundName,
                  campaignApplications,
                  campaignThreshold: fundThreshold,
                }}
                campaignType="funds"
              />
            ) : !isValidSpMeetingRequest &&
              role === ROLE.SERVICE_PROVIDER &&
              !hideActions ? (
              <Tooltip text={notValidReason}>
                <Button
                  variant="filled"
                  onClick={onSendRequest}
                  disabled={!isValidSpMeetingRequest}
                  data-testid="SpMeetingRequest"
                >
                  Send meeting request
                </Button>
              </Tooltip>
            ) : (
              !pastSummit &&
              canSendMeetingRequest &&
              !hideActions &&
              !!eventId && (
                <Tooltip
                  text={
                    blockedRequest &&
                    'You cannot send another meeting request because your first request has been declined'
                  }
                  className={cx(!blockedRequest && 'hidden')}
                >
                  <Button
                    variant="filled"
                    onClick={onSendRequest}
                    disabled={blockedRequest}
                    data-testid="MeetingRequest"
                  >
                    Send Meeting Request
                  </Button>
                </Tooltip>
              )
            )
          }
          contacts={map(contacts, (c) => ({
            ...c,
            name: c.contactName,
          }))}
        />
      }
      body={
        <ProfileBodyWithTabs
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
          tabs={map(
            filter(tabs, (x) => x.visible(data)),
            (t) => ({
              component: <t.component {...t.getProps(data)} />,
              title: t.title,
              key: t.key,
            })
          )}
        />
      }
    />
  )
}
FundDetailsModal.propTypes = {
  fundId: PropTypes.number,
  fundName: PropTypes.string,
  fundThreshold: PropTypes.number,
  campaignApplications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  campaignId: PropTypes.bool,
  onSendRequest: PropTypes.func,
  onSendMessage: PropTypes.func,
  meeting: PropTypes.object,
  visible: PropTypes.bool,
  contacts: PropTypes.array,
  pastSummit: PropTypes.bool,
  eventId: PropTypes.number,
  onRefresh: PropTypes.func,
  canSendMeetingRequest: PropTypes.bool,
  canSendMessage: PropTypes.bool,
  blockedRequest: PropTypes.bool,
  showDataroom: PropTypes.bool,
  mandateApplicationId: PropTypes.number,
  hideActions: PropTypes.bool,
  fullFundProfile: PropTypes.object,
  isSubscribed: PropTypes.bool,
  onSubscribe: PropTypes.func,
  onClose: PropTypes.func,
}
export default FundDetailsModal
