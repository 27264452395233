import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@context365/alert'
import { Button } from '@context365/button'
import { Select, TextArea } from '@context365/forms'
import { CheckCircle } from '@context365/icons'
import { Modal, Takeover } from '@context365/modals'
import { Tooltip } from '@context365/popovers'
import { message as response } from 'antd'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import some from 'lodash/some'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import * as api from '~/api'
import { MissingDetails } from '../../FundWizard'
import Loading from '../../Loading'

const ApplyToMandateModal = ({
  visible,
  onClose,
  mandate,
  mandateId,
  fundId = null,
  onApply = () => {},
}) => {
  const [message, setMessage] = useState('')
  const [isApplying, setIsApplying] = useState(false)
  const [applierFreshness, setApplierFreshness] = useState({
    isUpToDate: true,
  })
  const [fundUpdated, setFundUpdated] = useState(true)
  const [showFundUpdateForApplication, setShowFundUpdateForApplication] =
    useState(false)
  const [applyingFundId, setApplyingFundId] = useState(fundId)
  const [appliableFunds, setAppliableFunds] = useState([])
  const [questionAnswers, setQuestionAnswers] = useState([])
  const [attemptedApply, setAttemptedApply] = useState(false)
  const { contact, role } = useSelector((state) => state.auth)
  const isManager = role === 'Manager'

  //Make sure the correct fundId is selected if passed in
  useEffect(() => {
    setApplyingFundId(fundId)
  }, [fundId])

  useEffect(() => {
    if (visible && isManager) {
      api.funds.getAppliableFundsForMandate(mandateId).then((response) => {
        setAppliableFunds(response)
      })
    }
  }, [visible, mandateId, isManager])

  const { isLoading } = useQuery(
    ['mandateQuestions', mandateId],
    () => api.mandate.getMandateApplicationQuestions(mandateId),
    {
      enabled: visible,
      onSuccess: (mandateQuestions) => {
        if (isEmpty(questionAnswers)) {
          setQuestionAnswers(
            map(mandateQuestions, (q) => {
              return {
                mandateApplicationQuestionId: q.mandateApplicationQuestionId,
                category: q.category,
                text: q.text,
                response: null,
              }
            })
          )
        } else {
          //remove any answers for questions that no longer exist
          const newQuestionAnswers = filter(questionAnswers, (qa) =>
            find(
              mandateQuestions,
              (mq) =>
                mq.mandateApplicationQuestionId ===
                qa.mandateApplicationQuestionId
            )
          )
          //find any new questions that were created
          const questionsToAdd = filter(
            mandateQuestions,
            (mq) =>
              !find(
                questionAnswers,
                (qa) =>
                  mq.mandateApplicationQuestionId ===
                  qa.mandateApplicationQuestionId
              )
          )

          //only set the state if questions were added or removed since last fetch
          if (
            newQuestionAnswers.length !== questionAnswers.length ||
            !isEmpty(questionsToAdd)
          ) {
            forEach(questionsToAdd, (q) => {
              newQuestionAnswers.push({
                mandateApplicationQuestionId: q.mandateApplicationQuestionId,
                category: q.category,
                text: q.text,
                response: null,
              })
            })

            setQuestionAnswers(newQuestionAnswers)
          }
        }
      },
    }
  )

  useEffect(() => {
    if (applyingFundId && fundUpdated && visible) {
      api.funds.checkFundFreshness(applyingFundId).then((response) => {
        setApplierFreshness(response)
        setFundUpdated(false)
      })
    }
  }, [applyingFundId, fundUpdated, visible])

  const handleAnswerChange = useCallback(
    (mandateApplicationQuestionId, val) => {
      const newAnswers = questionAnswers.slice()
      const questionToChange = findIndex(
        newAnswers,
        (x) => x.mandateApplicationQuestionId === mandateApplicationQuestionId
      )
      newAnswers[questionToChange].response = val
      setQuestionAnswers(newAnswers)
    },
    [questionAnswers]
  )

  const handleApply = () => {
    setIsApplying(true)

    if (find(questionAnswers, (qa) => isEmpty(qa.response))) {
      response.error('Please fill out required fields.')
      setAttemptedApply(true)
      setIsApplying(false)
    } else {
      const data = questionAnswers.map((x) => {
        return {
          text: x.response,
          mandateApplicationQuestionId: x.mandateApplicationQuestionId,
        }
      })
      api.mandate
        .applyToMandate(mandateId, applyingFundId, message, data)
        .then((res) => {
          const applyingFund = find(
            appliableFunds,
            (af) => af.fundId === applyingFundId
          )
          if (applyingFund) {
            onApply(applyingFund.campaignId, applyingFund.fundName)
          } else if (contact.allocatorDetails?.generalInterestCampaignId) {
            onApply(
              contact.allocatorDetails.generalInterestCampaignId,
              'General Interest'
            )
          } else {
            onApply()
          }
          mandate.campaignApplications.push({
            campaignId:
              applyingFund?.campaignId ??
              contact.allocatorDetails?.generalInterestCampaignId,
            applicationId: res.data.result.campaignApplicationId,
            wasSent: true,
          })
        })
        .catch(() => {
          response.error('Could not indicate interest in this mandate.')
        })
        .finally(() => {
          setIsApplying(false)
          onClose()
        })
    }
  }

  const resetForm = () => {
    setShowFundUpdateForApplication(false)
    setFundUpdated(true)
    onClose()
  }

  return (
    <Takeover.Container
      style={{ zIndex: 1001 }}
      visible={visible}
      onDismiss={resetForm}
    >
      <Modal.Header>
        <div className="flex items-center justify-between">
          <Modal.Title>Indicate Interest</Modal.Title>
          <Modal.Close />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-32">
          <Loading spinning={isApplying || isLoading}>
            {isManager && (
              <>
                <div className="flex mb-2">
                  <Select
                    label="Fund"
                    required
                    value={applyingFundId}
                    onChange={(value) => {
                      setShowFundUpdateForApplication(false)
                      setApplyingFundId(value)
                      setFundUpdated(true)
                    }}
                    className="w-2/3"
                  >
                    {map(appliableFunds, (f) => {
                      const hasApplied = some(
                        mandate.campaignApplications,
                        (ma) => ma.campaignId === f.campaignId
                      )

                      return (
                        <Select.Option
                          key={f.fundId}
                          value={f.fundId}
                          disabled={hasApplied}
                          className="flex items-center"
                        >
                          <Tooltip
                            text={
                              hasApplied ? 'Already indicated interest' : null
                            }
                          >
                            {f.fundName}
                          </Tooltip>
                        </Select.Option>
                      )
                    })}
                  </Select>
                  {applyingFundId && applierFreshness.isUpToDate && (
                    <CheckCircle className="mx-2 mb-1.5 text-green-110 self-end" />
                  )}
                </div>
                <div className="type-body-regular-xs mb-4">
                  This fund will be added to the mandate poster’s pipeline
                </div>
                {!applierFreshness.isUpToDate && (
                  <Alert status="warning" className="mb-4">
                    <span>
                      {'This fund is missing information. Please '}
                      <Button
                        variant="link"
                        className="align-baseline"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => setShowFundUpdateForApplication(true)}
                      >
                        {'fill in the missing information'}
                      </Button>
                      {' to indicate interest in this mandate.'}
                    </span>
                  </Alert>
                )}
              </>
            )}
            {showFundUpdateForApplication && applyingFundId && (
              <MissingDetails
                fundId={applyingFundId}
                fundFreshness={applierFreshness}
                onComplete={() => {
                  setShowFundUpdateForApplication(false)
                  setFundUpdated(true)
                }}
              />
            )}
            <TextArea
              label="Send Message (optional)"
              rows={4}
              onChange={({ target: { value } }) => {
                setMessage(value)
              }}
              value={message}
            />
            {map(
              groupBy(questionAnswers, 'category'),
              (questions, category) => {
                return (
                  <div key={category}>
                    <div className="type-subtitle-sm mt-4 mb-2">{category}</div>
                    {category === 'Custom Questions' && (
                      <div className="italic type-body-regular-sm mb-4">
                        Specially created by the mandate poster
                      </div>
                    )}
                    <div className="space-y-8">
                      {map(questions, (q) => (
                        <TextArea
                          key={q.mandateApplicationQuestionId}
                          label={q.text}
                          required
                          rows={4}
                          errorMessage={
                            attemptedApply && isEmpty(q.response)
                              ? 'Required'
                              : null
                          }
                          onChange={(e) =>
                            handleAnswerChange(
                              q.mandateApplicationQuestionId,
                              e.target.value
                            )
                          }
                          value={q.response}
                        />
                      ))}
                    </div>
                  </div>
                )
              }
            )}
          </Loading>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-end">
        <div className="space-x-4">
          <Button onClick={resetForm}>Cancel</Button>
          <Button
            variant="filled"
            disabled={
              (isManager && !applyingFundId) || !applierFreshness.isUpToDate
            }
            onClick={handleApply}
          >
            Indicate Interest
          </Button>
        </div>
      </Modal.Footer>
    </Takeover.Container>
  )
}

ApplyToMandateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mandateId: PropTypes.number.isRequired,
  mandate: PropTypes.object,
  fundId: PropTypes.number,
  onApply: PropTypes.func,
}

export default ApplyToMandateModal
