import { useCallback, useEffect } from 'react'
import { Empty } from 'antd'
import concat from 'lodash/concat'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { fetchEventsIfNeeded } from '~/actions/events'
import { fetchCancellingReasonsIfNeeded } from '~/actions/meetings'
import Loading from '~/components/Loading'
import { selectEvent } from '~/selectors/events'
import SummitDetails from './SummitDetails'

const SummitDetailsContainer = () => {
  const { push } = useHistory()
  const {
    params: { id, tab, subtab },
  } = useRouteMatch()

  const selectedEventId = parseInt(id, 10)

  const isFetching = useSelector((state) => state.events.isFetching)
  const attendingEvents = useSelector((state) =>
    concat(state.events.attendingEvents, state.events.pastEvents)
  )
  const attendingEvent = useSelector(selectEvent)
  const role = useSelector((state) => state.auth.role)

  const dispatch = useDispatch()

  const fetchEvents = useCallback(
    (selectedEventId) => {
      dispatch(fetchEventsIfNeeded(selectedEventId))
      dispatch(fetchCancellingReasonsIfNeeded())
    },
    [dispatch]
  )

  useEffect(() => {
    fetchEvents(selectedEventId)
  }, [fetchEvents, selectedEventId])

  if (isNil(attendingEvent)) {
    return (
      <Loading spinning={isFetching}>
        <Empty />
      </Loading>
    )
  }
  return (
    <SummitDetails
      events={attendingEvents}
      selectedEvent={attendingEvent}
      role={role}
      push={push}
      tab={tab}
      subtab={subtab}
    />
  )
}

export default SummitDetailsContainer
