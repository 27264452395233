import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { format } from 'date-fns'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import ProfilePictureGroup from '../../ProfilePictureGroup/index'
import { ConversationShape } from '../MessagePropTypes'
import './ConversationItem.less'

const ConversationItem = ({
  conversation,
  isSelected = false,
  onClick = () => {},
}) => {
  const companyID = useSelector((state) => state.auth.company.companyId)

  const contactsToShow = conversation.contacts
    .filter((x) => x.companyID !== companyID)
    .map((c) => ({
      ...c,
      name: c.contactName,
      image: c.imageUrl,
      imageUrl: c.imageUrl,
    }))

  const pictureDiameter = contactsToShow.length > 1 ? 32 : 48

  return (
    <div
      className={cx(
        'flex mb-2 p-2 border-b cursor-pointer',
        isSelected && 'bg-white shadow-0'
      )}
      onClick={onClick}
    >
      <div className="flex-col">
        {/* Picture Group - left side */}
        <div className="inline-block items-center w-16 flex-shrink-0 mr-2">
          {!isNil(conversation.meetingType) && (
            <Badge size="small" className="mb-2">
              Meeting
            </Badge>
          )}
          <ProfilePictureGroup
            diameter={pictureDiameter}
            profiles={contactsToShow}
            maxImages={3}
            type={conversation.type}
            showImageOnPopover
          />
        </div>
      </div>
      <div className="mt-1">
        <div className="type-body-semibold-sm lg:w-60 overflow-ellipsis">
          {conversation.title}
        </div>
        {conversation.lastMessage && (
          <div className="flex type-body-regular-xs">
            {conversation.lastMessage.body}
          </div>
        )}
        {conversation.sortDate && (
          <div className="flex type-body-regular-xs mt-2">
            {`Last activity: ${format(
              new Date(conversation.sortDate),
              'MMM do, y'
            )}`}
          </div>
        )}
      </div>

      {/* Unread messages count icon - right side:
        container rendered, but content shown only if conversation has any unread messages */}
      <div className="w-full text-right mr-2">
        {conversation.unreadMessages > 0 && (
          <Badge status="error">{conversation.unreadMessages}</Badge>
        )}
      </div>
    </div>
  )
}

ConversationItem.propTypes = {
  conversation: ConversationShape.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

export default ConversationItem
