import { useCallback, useState } from 'react'
import { message } from 'antd'
import isNil from 'lodash/isNil'
import { useRouteMatch } from 'react-router-dom'
import { getMeetingConversationID } from '~/actions/messages'
import ConversationsPanel from '~/components/Messages/ConversationsPanel'
import MeetingMessagesPanel from '~/components/Messages/MeetingMessagesPanel'
import MessagesPanel from '~/components/Messages/MessagesPanel'
import './Messages.less'

const Messages = () => {
  const {
    params: { conversationId: urlConversationId, isArchived: urlIsArchived },
  } = useRouteMatch()

  const [conversationID, setConversationID] = useState(
    isNil(urlConversationId) ? null : parseInt(urlConversationId, 10)
  )

  const [meetingID, setMeetingID] = useState(null)
  const [refreshMeetings, setRefreshMeetings] = useState(null)
  const [refreshConversations, setRefreshConversations] = useState(false)
  const [redirectToConversations, setRedirectToConversations] = useState(null)
  const [archived, setArchived] = useState(false)
  const [redirectedAfterAccept, setRedirectedAfterAccept] = useState(false)

  const isConversationArchived = !isNil(urlIsArchived)

  const conversationSelectedHandler = useCallback(
    (conversationIDparam, fromArchived = false) => {
      setConversationID(conversationIDparam)
      setMeetingID(null)
      setArchived(fromArchived)
    },
    []
  )

  const meetingSelectedHandler = useCallback((meetingID) => {
    setMeetingID(meetingID)
    setConversationID(meetingID)
  }, [])

  const meetingRepliedHandler = useCallback(
    (createdConversationID) => {
      setConversationID(createdConversationID)
      setMeetingID(null)
      setRefreshMeetings(isNil(refreshMeetings) ? true : !refreshMeetings)
      setRefreshConversations(true)
    },
    [refreshMeetings]
  )

  const meetingDeclinedHandler = useCallback(() => {
    setConversationID(null)
    setMeetingID(null)
    setRefreshMeetings(isNil(refreshMeetings) ? true : !refreshMeetings)
  }, [refreshMeetings])

  const meetingAcceptedHandler = async () => {
    try {
      const conversationRaw = await getMeetingConversationID(meetingID)
      const conversationID = conversationRaw.data.result
      setConversationID(conversationID)
      setMeetingID(null)
      setRefreshMeetings(isNil(refreshMeetings) ? true : !refreshMeetings)
      setRedirectedAfterAccept(true)
      setRefreshConversations(true)
    } catch (error) {
      message.error(
        'There was an error while attempting to get conversation for the accepted meeting'
      )
    }
  }

  const archivedRepliedHandler = useCallback(() => {
    setConversationID(null)
    setRefreshMeetings(isNil(refreshMeetings) ? true : !refreshMeetings)
    setRedirectToConversations(
      isNil(redirectToConversations) ? true : !redirectToConversations
    )
  }, [refreshMeetings, redirectToConversations])

  return (
    <div className="flex gap-4">
      <div style={{ width: '30rem' }}>
        <ConversationsPanel
          onConversationSelected={conversationSelectedHandler}
          onMeetingSelected={meetingSelectedHandler}
          conversationID={conversationID}
          refreshMeetings={refreshMeetings}
          refreshConversations={refreshConversations}
          redirectToConversations={redirectToConversations}
          setRefreshConversations={setRefreshConversations}
          isRedirectedToArchives={isConversationArchived}
          redirectedAfterAccept={redirectedAfterAccept}
        />
      </div>
      <div className="w-full">
        {!isNil(conversationID) && isNil(meetingID) && (
          <MessagesPanel
            conversationID={conversationID}
            setConversationID={setConversationID}
            archived={archived}
            setRefreshConversations={setRefreshConversations}
            onRepliedToArchive={archivedRepliedHandler}
          />
        )}

        {!isNil(meetingID) && (
          <MeetingMessagesPanel
            meetingID={meetingID}
            onReplied={meetingRepliedHandler}
            onDeclined={meetingDeclinedHandler}
            onAccepted={meetingAcceptedHandler}
          />
        )}
      </div>
    </div>
  )
}

export default Messages
