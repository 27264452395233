import { Button } from '@context365/button'
import { Star } from '@context365/icons'
import PremiumTierModal from '~/components/PremiumTierModal/PremiumTierModal'
import useVisibility from '~/hooks/useVisibility'

export default function LockedMessage() {
  const upgradeModal = useVisibility()

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-10 h-full p-4">
        <div className="p-4 rounded-full shadow-6">
          <img alt="" src="/static/img/winner 1.svg" />
        </div>
        <div className="type-body-regular-lg max-w-md text-center">
          In order to read this conversation you need the <b>Pro Package</b>.
        </div>
        <div>
          <Button
            variant="filled"
            size="big"
            iconLeft={<Star />}
            onClick={upgradeModal.show}
          >
            Request an Upgrade
          </Button>
        </div>
      </div>
      <PremiumTierModal
        visible={upgradeModal.visible}
        bypassContainer
        closable
        onModalClosed={upgradeModal.hide}
      />
    </>
  )
}
