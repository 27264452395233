import { api } from './services'

export const getEventKey = (eventId) => api.get(`booths/${eventId}/key`)

export const bookBooth = (eventId, boothId, eventCompanyId) =>
  api.post('/booths/book', {
    eventId,
    boothId,
    eventCompanyId,
  })

export const updateBooth = (
  eventId,
  currentBoothId,
  newBoothId,
  EventCompanyBoothId
) =>
  api.patch('/booths', {
    eventId,
    currentBoothId,
    newBoothId,
    EventCompanyBoothId,
  })
