import { AutoAwesomeOutlined } from '@context365/icons'
import { Placement, Popover } from '@context365/popovers'
import './InfoPopover.less'

const InfoPopover = ({ title = 'Info', popoverBody, children }) => {
  return (
    <Popover
      className="info-popover bg-brand-100 rounded-big max-w-xs p-4"
      placement={Placement.Bottom}
      content={
        <>
          <div className="flex items-center mb-3">
            <AutoAwesomeOutlined className="text-black mr-3" />
            <span className="type-subtitle-md">{title}</span>
          </div>
          <div className="popover-body type-body-regular-sm">{popoverBody}</div>
        </>
      }
    >
      {children}
    </Popover>
  )
}

export default InfoPopover
