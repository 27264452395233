import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isNil from 'lodash/isNil'
import {
  getMeetingConversation,
  replyToMeetingMessage,
} from '~/actions/messages'
import { boldCompanyNameFormat } from '~/utils/helpers'
import AllocatorCompanyDetailsModal from '../../AllocatorCompanyDetailsModal/AllocatorCompanyDetailsModal'
import DiscoverServiceProviderDetailsModal from '../../DiscoverServiceProviderDetailsModal/DiscoverServiceProviderDetailsModal'
import FundDetailsModalContainer from '../../FundDetailsModalContainer'
import Loading from '../../Loading'
import PremiumTierModal from '../../PremiumTierModal/PremiumTierModal'
import ChatHeader from '../ChatHeader'
import DataTransformations from '../DataTransformations'
import ForbiddenMessagePanel from '../ForbiddenMessagePanel'
import InfoDrawer from '../InfoDrawer'
import MeetingActionsPopup from '../MeetingActionsPopup'
import MessageBoard from '../MessageBoard'
import MessageControls from '../MessageControls'

const MeetingMessagesPanel = ({
  meetingID,
  onReplied,
  onDeclined,
  onAccepted,
}) => {
  const [meeting, setMeeting] = useState(null)
  const [meetingLoading, setMeetingLoading] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [companyDetailsVisible, setCompanyDetailsVisible] = useState(false)
  const [selectedFundID, setSelectedFundID] = useState(null)
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false)
  const [isForbidden, setIsForbidden] = useState(false)

  const [selectedInvitedCompany, setSelectedInvitedCompany] = useState(false)
  const [selectedCompanyType, setSelectedCompanyType] = useState('')

  useEffect(() => {
    const fetchMeeting = async () => {
      setMeetingLoading(true)
      try {
        const rawMeeting = await getMeetingConversation(meetingID, true)

        setUpgradeModalVisible(false)
        setIsForbidden(false)

        const meeting = rawMeeting.data.result
        meeting.messages = DataTransformations.TransformOrderAndIndexMessages(
          meeting.messages,
          1
        )
        setMeeting(meeting)
      } catch (error) {
        if (error?.response?.status === 403) {
          setUpgradeModalVisible(true)
          setIsForbidden(true)
          setMeeting(null)
        } else {
          message.error(
            'Something went wrong while attempting to fetch the conversation data'
          )
        }
      } finally {
        setMeetingLoading(false)
      }
    }
    fetchMeeting()
  }, [meetingID])
  const meetingWithString = boldCompanyNameFormat(meeting?.meeting)
  const infoClickedHandler = useCallback(() => {
    setDrawerVisible(!drawerVisible)
  }, [drawerVisible])

  const titleClickedHandler = () => {}

  const meetingCompletedHandler = () => {}

  const invitedCompanyClickedHandler = () => {
    setSelectedInvitedCompany(true)
    setSelectedCompanyType(meeting.details.invitedCompanyType)
    setCompanyDetailsVisible(true)
  }

  const companyClickedHandler = () => {
    setSelectedInvitedCompany(false)
    setSelectedCompanyType(meeting.details.companyType)
    setCompanyDetailsVisible(true)
  }

  const drawerClosedHandler = () => {
    setDrawerVisible(false)
  }
  const fundClickedHandler = (fundID) => {
    setSelectedFundID(fundID)
    setSelectedCompanyType(meeting.details.companyType)
    setCompanyDetailsVisible(true)
  }

  const companyDetailsCloseHandler = () => {
    setCompanyDetailsVisible(false)
  }

  const sendMessageHandler = useCallback(
    async (message) => {
      setSendingMessage(true)
      try {
        await replyToMeetingMessage(meetingID, message)
        onReplied()
      } catch (error) {
        //message.error(error.response.data.message);
        message.error('An error occurred while attempting to send message')
      } finally {
        setSendingMessage(false)
      }
    },
    [meetingID, onReplied]
  )

  return !isNil(meeting) ? (
    <Loading
      style={{
        width: '100%',
        marginTop: '100px',
      }}
      spinning={meetingLoading}
    >
      <ChatHeader
        conversationType={meeting.conversationType}
        title={meetingWithString}
        contacts={meeting.details.contacts}
        onInfoClicked={infoClickedHandler}
        onTitleClicked={titleClickedHandler}
        areFeaturesDisabled={true}
        meeting={meeting.meeting}
      />

      <div className="message-board-container">
        {/* if there's a meeting, the BE has decided you should take action on it */}
        {!isNil(meeting) && (
          <MeetingActionsPopup
            meeting={meeting.meeting}
            companyName={meetingWithString}
            onMeetingCompleted={meetingCompletedHandler}
            onMeetingAccepted={onAccepted}
            onMeetingDeclined={onDeclined}
            contacts={meeting.details.contacts}
          />
        )}
        <div className="message-board">
          {!meetingLoading && (
            <MessageBoard messages={meeting.messages} consumptionLookup={[]} />
          )}
        </div>
        <MessageControls
          loading={sendingMessage}
          onSendMessage={sendMessageHandler}
        />
      </div>

      <InfoDrawer
        visible={drawerVisible}
        onDrawerClose={drawerClosedHandler}
        onFundClicked={fundClickedHandler}
        onInvitedCompanyClicked={invitedCompanyClickedHandler}
        onCompanyClicked={companyClickedHandler}
        company={DataTransformations.GetCompanyFromConversation(meeting)}
        dealInfo={DataTransformations.GetDealInfoFromConversation(meeting)}
      />
      {selectedCompanyType === 'Manager' ? (
        <FundDetailsModalContainer
          fund={{
            fundId: selectedFundID,
            contacts: meeting.details.contacts,
            company: {
              companyId:
                selectedInvitedCompany === false
                  ? meeting.details.companyID
                  : meeting.details.invitedCompanyID,
            },
          }}
          showFundProfile={companyDetailsVisible}
          arePointsNear={false}
          onClose={companyDetailsCloseHandler}
          showDataroom={false}
          hideActions={true}
        />
      ) : selectedCompanyType === 'Allocator' ? (
        <Modal
          visible={companyDetailsVisible}
          footer={null}
          onCancel={companyDetailsCloseHandler}
          width="900px"
          bodyStyle={{ padding: 0 }}
        >
          <AllocatorCompanyDetailsModal
            companyId={
              selectedInvitedCompany
                ? meeting.details.invitedCompanyID
                : meeting.details.companyID
            }
            visible={companyDetailsVisible}
          />
        </Modal>
      ) : (
        companyDetailsVisible && (
          <Modal
            visible={companyDetailsVisible}
            footer={null}
            onCancel={companyDetailsCloseHandler}
            width="900px"
            bodyStyle={{ padding: 0 }}
          >
            <DiscoverServiceProviderDetailsModal
              serviceProvider={{
                companyName: !selectedInvitedCompany
                  ? meeting.details.companyName
                  : meeting.details.invitedCompanyName,
                address: !selectedInvitedCompany
                  ? meeting.details.address
                  : meeting.details.invitedAddress,
              }}
              companyId={
                !selectedInvitedCompany
                  ? meeting.details.companyID
                  : meeting.details.invitedCompanyID
              }
              visible={companyDetailsVisible}
              onCancel={companyDetailsCloseHandler}
              selectedFundId={0}
              hideActions={true}
            />
          </Modal>
        )
      )}
    </Loading>
  ) : upgradeModalVisible ? (
    <div id="premiumModalDiv">
      <PremiumTierModal
        bypassContainer={true}
        closable={true}
        visible={upgradeModalVisible}
        onModalClosed={() => {
          setUpgradeModalVisible(false)
        }}
      />
    </div>
  ) : (
    <Loading
      spinning={!isForbidden || meetingLoading}
      style={{
        width: '100%',
        marginTop: '100px',
      }}
    >
      {isForbidden && (
        <ForbiddenMessagePanel
          onRequestUpgrade={() => {
            setUpgradeModalVisible(true)
          }}
        />
      )}
    </Loading>
  )
}

MeetingMessagesPanel.propTypes = {
  meetingID: PropTypes.number.isRequired,
  onReplied: PropTypes.func.isRequired,
  onAccepted: PropTypes.func.isRequired,
  onDeclined: PropTypes.func.isRequired,
}

export default MeetingMessagesPanel
