import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { faPaperPlane, faPaperclip } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, Tooltip, Upload, message as prompt } from 'antd'
import isNil from 'lodash/isNil'
import './MessageControls.less'

const MessageControl = ({
  messagingDisabled = false,
  blockReason = '',
  loading = false,
  onSendMessage,
  isSlim = false,
}) => {
  const [message, setMessage] = useState('')

  const sendMessageHandler = useCallback(() => {
    if (isNil(message) || message === '' || /^\s+$/.test(message)) return
    onSendMessage(message.trim())
    setMessage('')
  }, [message, onSendMessage])

  const messageChangedHandler = (e) => {
    setMessage(e.target.value)
  }

  const getBase64 = useCallback((file, callback) => {
    const reader = new FileReader()
    reader.onload = () => callback(reader.result)
    reader.readAsDataURL(file)
  }, [])

  const onFileUploaded = (e) => {
    if (e.file.status !== 'done') return
    getBase64(e.file.originFileObj, (fileUrl) => {
      onSendMessage(fileUrl, e.file.name, true)
    })
  }

  const uploadButton = (
    <div className="messaging-chat-attachment">
      <Upload
        disabled={messagingDisabled}
        accept=".pdf,application/pdf"
        multiple={false}
        className="messaging-chat-attachment-upload"
        customRequest={(e) => {
          e.onSuccess('ok')
        }}
        onChange={onFileUploaded}
        beforeUpload={(e) => {
          if (e.type !== 'application/pdf') {
            prompt.error('Can only send PDF files')
            return false
          }

          if (e.size > 100 * 1024 * 1024) {
            prompt.error('Cannot upload files over 100MB')
            return false
          }

          return true
        }}
      >
        <Tooltip title="Send document (PDF)">
          <Button disabled={messagingDisabled}>
            <FontAwesomeIcon
              className="messaging-chat-control-attachment-icon"
              icon={faPaperclip}
            />
          </Button>
        </Tooltip>
      </Upload>
    </div>
  )

  const textInput = (
    <div className="messaging-chat-message-input">
      <Input.TextArea
        autoSize={{ minRows: 2, maxRows: 3 }}
        style={{ height: '48px' }}
        disabled={messagingDisabled}
        value={message}
        onChange={messageChangedHandler}
        placeholder={messagingDisabled ? blockReason : 'Type a message'}
      />
    </div>
  )
  const sendButton = (
    <Button
      type="primary"
      className="messaging-chat-send-button"
      onClick={sendMessageHandler}
      disabled={messagingDisabled}
      loading={loading}
    >
      <FontAwesomeIcon icon={faPaperPlane} />
      Send
    </Button>
  )

  return (
    <div
      className={`messaging-chat-control${
        isSlim ? ' messaging-chat-control-slim' : ''
      }`}
    >
      {isSlim ? (
        <>
          <div className="messaging-chat-control-slim-text">{textInput}</div>
          <div className="messaging-chat-control-slim-buttons">
            {uploadButton}
            {sendButton}
          </div>
        </>
      ) : (
        <>
          {uploadButton}
          {textInput}
          {sendButton}
        </>
      )}
    </div>
  )
}

MessageControl.propTypes = {
  messagingDisabled: PropTypes.bool,
  blockReason: PropTypes.string,
  loading: PropTypes.bool,
  onSendMessage: PropTypes.func.isRequired,
  isSlim: PropTypes.bool,
}

export default MessageControl
