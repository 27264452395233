import { Icon } from '@context365/icons'
import map from 'lodash/map'
import { ReactComponent as Logo } from '~/assets/logo-icon.svg'
import useNavItems from '~/lib/useNavItems'
import PolicyBarCollapsed from '~/views/PolicyBarCollapsed/PolicyBarCollapsed'
import SidebarLink from '../NavUtils/SidebarLink'

const SideBar = () => {
  const navItems = useNavItems()

  return (
    <aside className="hidden md:block bg-black h-full w-full">
      <div className="flex flex-col w-full items-center">
        <Icon
          as={Logo}
          className="text-brand-100 w-12 h-12 mt-4"
          viewBox="0 0 1224 1227"
          titleAccess="Context365 by Apex"
        />
        <nav
          className="mt-8 flex w-full items-stretch flex-col gap-2"
          aria-label="Main"
        >
          <ul className="list-none p-0 m-0">
            {map(navItems, ({ path, ...navItem }) => (
              <SidebarLink key={path} to={path} {...navItem} />
            ))}
          </ul>
        </nav>
        <div className="cursor-pointer">
          <PolicyBarCollapsed />
        </div>
      </div>
    </aside>
  )
}

export default SideBar
