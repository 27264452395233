import get from 'lodash/get'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { createSelector } from 'reselect'
import Tiers, { TIER_CLAIM } from '~/constants/tiers'

const claims = (state) => state.auth.claims
const contact = (state) => state.auth.contact
const company = (state) => state.auth.company

export const tokenExpiryDate = createSelector(claims, (claims) => {
  if (isNil(claims)) {
    return moment().utc().subtract(1, 'year')
  }
  return moment.utc((claims.exp || 0) * 1000)
})

export const isAuthenticated = createSelector(tokenExpiryDate, (expiryDate) =>
  expiryDate.isAfter(moment.utc())
)

export const getUserId = createSelector(claims, (claims) =>
  parseInt(get(claims, 'nameid', '0'), 10)
)

export const getCompanyId = createSelector(claims, (claims) =>
  parseInt(get(claims, 'company_id', '0'), 10)
)

export const getUserCompanies = createSelector(contact, (contact) =>
  get(contact, 'companies', [])
)

export const getCurrentCompanyName = createSelector(company, (company) =>
  get(company, 'name', '')
)

export const getUserEmail = createSelector(claims, (claims) =>
  get(claims, 'email', '')
)

export const isAdmin = createSelector(
  claims,
  (claims) => get(claims, 'is_admin', '0') === '1'
)

export const getTwilioToken = createSelector(contact, (contact) =>
  get(contact, 'twilioToken', '')
)

export const hasOnboarded = createSelector(contact, (contact) =>
  get(contact, 'hasOnBoarded', '')
)

export const getOnboardStep = createSelector(contact, (contact) =>
  get(contact, 'onboardStep', '')
)

export const getTier = createSelector(claims, (claims) =>
  get(claims, TIER_CLAIM, Tiers.Starter)
)

export const getUserCompanyContactId = createSelector(claims, (claims) =>
  parseInt(get(claims, 'ccid', '0'), 10)
)

export const isImpersonation = createSelector(
  claims,
  (claims) => get(claims, 'impersonation', 'false') === 'true'
)

export const userIsGuest = createSelector(
  (state) => state.auth.role,
  (role) => role.toLowerCase() === 'guest presentation'
)
