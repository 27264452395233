import { Input, TextArea } from '@context365/forms'
import { Divider } from 'antd'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { getAllCountries } from '~/actions/geography'
import ArrayField from '~/components/GenericForm/Fields/ArrayField'
import CheckBoxField from '~/components/GenericForm/Fields/CheckBoxField'
import DateField from '~/components/GenericForm/Fields/DateField'
import DropDownField from '~/components/GenericForm/Fields/DropDownField'
import FileField from '~/components/GenericForm/Fields/FileField'
import InputNumberField from '~/components/GenericForm/Fields/InputNumberField'
import LinkField from '~/components/GenericForm/Fields/LinkField'
import RadioField from '~/components/GenericForm/Fields/RadioField'
import StaticText from '~/components/GenericForm/Fields/StaticText'
import UploadMultipleFileField from '~/components/GenericForm/Fields/UploadMultipleFileField'
import { IlliquidFundTypeExplanation } from '../../FundTypeExplanation'
import GeneralPartnerField from '../Fields/GeneralPartnerField'
import PortfolioField from '../Fields/PortfolioField'
import ReturnsField from '../Fields/ReturnsField'
import ServiceProviderField from '../Fields/ServiceProviderField'

const Header = ({ title, subtitle }) => (
  <>
    <div className="text-primary-100 type-body-semibold-md">{title}</div>
    {subtitle && (
      <div className="text-grey-600 type-body-regular-md">{subtitle}</div>
    )}
  </>
)

export const BasicInfoQuestions = (
  values,
  questionOptions,
  onSwitchFundType
) => [
  {
    hideOnCreate: true,
    questions: [
      {
        component: IlliquidFundTypeExplanation,
        extraProps: {
          onSwitchFundType,
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'fundName',
        title: 'Fund Name',
        required: true,
        component: Input,
      },
    ],
  },
  {
    questions: [
      {
        name: 'strategyDescription',
        title: 'Fund Strategy & Description',
        required: true,
        component: TextArea,
        helpText: `Character Count: ${
          values.strategyDescription?.length ?? 0
        }. Please input at least 280 characters of text. This will help people find your fund by keyword search.`,
        extraProps: { rows: 5 },
      },
    ],
  },
  {
    questions: [
      {
        name: 'broadStrategyId',
        title: 'Broad Strategy',
        required: true,
        component: DropDownField,
        extraProps: {
          options: questionOptions.strategies,
          clearFieldsOnChange: ['subStrategyId'],
        },
      },
      {
        name: 'subStrategyId',
        title: 'Narrow Strategy',
        component: DropDownField,
        extraProps: {
          options: isNil(values.broadStrategyId)
            ? []
            : find(questionOptions.strategies, { id: values.broadStrategyId })
                ?.children,
        },
      },
    ],
  },
  {
    questions: [
      {
        component: Divider,
      },
    ],
  },
  {
    questions: [
      {
        name: 'inceptionDate',
        title: 'Inception Date',
        required: true,
        isFormField: true,
        component: DateField,
        helpText:
          'This date can be a date in the future if this fund has not launched yet.',
      },
      {
        name: 'vintageInceptionDate',
        title: 'Fund Vintage Inception Date',
        isFormField: true,
        component: DateField,
        helpText: 'Date of first investment',
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'targetCloseDate',
        title: 'Fund Target Close Date',
        isFormField: true,
        component: DateField,
        helpText: 'Date the fund will be closed to new investors (target)',
      },
    ],
  },
  {
    questions: [
      {
        component: Divider,
      },
    ],
  },
  {
    questions: [
      {
        name: 'fundAum',
        title: 'Fund AUM',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
      {
        name: 'targetFundSize',
        title: 'Target Fund Size',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  {
    half: false,
    questions: [
      {
        name: 'isOpenEnded',
        title: 'Is the fund structure open-end or closed-end?',
        required: true,
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            { label: 'Open-end/Evergreen', value: true },
            { label: 'Closed-end', value: false },
          ],
          clearFieldsOnChange: ['fundTerm'],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
  {
    half: values.isOpenEnded,
    questions: [
      {
        name: 'investmentPeriod',
        title: 'Investment Period (Years)',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
      {
        name: 'fundTerm',
        title: 'Fund Term (Years)',
        isVisible: (vals) => !vals.isOpenEnded,
        required: !values.isOpenEnded,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },

  {
    questions: [
      {
        name: 'domicileIds',
        title: 'Fund Domicile',
        required: true,
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.domiciles,
        },
      },
      {
        name: 'domicileCountryIds',
        title: 'Fund Domicile Country',
        required: true,
        component: DropDownField,
        helpText: 'Country where the fund is domiciled',
        extraProps: {
          showSearch: true,
          mode: 'multiple',
          getOptionsMethod: getAllCountries,
          mappingMethod: (vals) =>
            map(vals, (x) => ({ label: x.name, value: x.countryId })),
        },
      },
    ],
  },
  {
    questions: [
      {
        component: Divider,
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'attributeIds',
        title: 'Fund Attributes (Please select all that apply)',
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.attributes,
        },
      },
    ],
  },
]

export const FirmInfoQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        name: 'partners',
        title: '',
        component: ArrayField,
        extraProps: {
          subComponent: GeneralPartnerField,
          subProps: {},
          defaultObject: {
            name: '',
            countryId: null,
            stateProvince: '',
            city: '',
            biography: '',
            isGeneralPartner: true,
          },
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        name: 'companyDescription',
        title: 'Firm Description',
        component: TextArea,
        required: true,
        helpText: `Character Count: ${
          values.companyDescription?.length ?? 0
        }. Please input at least 280 characters of text. This will help people find your fund by keyword search.`,
        extraProps: {
          rows: 4,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'firmAUM',
        title: 'Firm AUM',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
      {
        name: 'activeFunds',
        title: 'Active Funds at the Firm',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'activePortfolioCompanies',
        title: 'Active Portfolio Companies at the firm',
        required: true,
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
      {
        name: 'nthFundByFirm',
        title: 'What number fund is this of the firm?',
        required: true,
        helpText: 'e.g. This is the 9th fund of the firm',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    half: true,
    questions: [
      {
        name: 'industryIds',
        title: 'Industries of interest for the firm',
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.industries,
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'geographyIds',
        title: 'Geographies of interest for the firm',
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.geographies,
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        name: 'verticalIds',
        title: 'Verticals of interest for the firm',
        isFormField: true,
        component: CheckBoxField,
        extraProps: {
          options: questionOptions.verticals,
        },
      },
    ],
  },
]

export const ValuationQuestions = () => [
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Target Performance',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'expectedTargetedInternalRateOfReturn',
        title: 'Target IRR',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'netInternalRateOfReturn',
        title: 'IRR (Net)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
      {
        name: 'grossInternalRateOfReturn',
        title: 'IRR (Gross)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'netTotalValuePaidInCapital',
        title: 'TVPI (Net)',
        helpText: 'e.g. 2.3x TVPI',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'multiple',
        },
      },
      {
        name: 'grossTotalValuePaidInCapital',
        title: 'TVPI (Gross)',
        helpText: 'e.g. 2.3x TVPI',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'multiple',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'residualValueToPaidIn',
        title: 'RVPI',
        helpText: 'e.g. 2.3x RVPI',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'multiple',
        },
      },
      {
        name: 'distributionsPaidInCapital',
        title: 'DVPI',
        helpText: 'e.g. 2.3x DVPI',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'multiple',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Historical Performance',
          subtitle:
            'To showcase historical fund performance in TVPI and/or IRR terms, add a new return below. For either display type, you can choose to showcase quarterly or annual returns.',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'returns',
        title: '',
        component: ReturnsField,
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    half: true,
    questions: [
      {
        name: 'currentNetAssetValue',
        title: 'NAV of Current Assets',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
]

export const CommitmentQuestions = () => [
  {
    questions: [
      {
        name: 'totalCommitments',
        title: 'Total Commitments',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
      {
        name: 'remainingCommitments',
        title: 'Remaining Commitments (Dry Powder)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    half: true,
    questions: [
      {
        name: 'totalDrawdownsSinceInception',
        title: 'Total Drawdowns',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Distributions',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'commitedCapitalPercentage',
        title: 'Percent of Commited Capital',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
      {
        name: 'totalDrawdownPercentage',
        title: 'Percentage of Total Drawdowns',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
]

export const TermsQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        name: 'generalPartnerCommitedRatio',
        title: 'GP Commit',
        helpText: 'GP AUM to Fund AUM Ratio',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
      {
        name: 'minimumInvestment',
        title: 'Minimum Investment',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Fees',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'managementFeePercentage',
        title: 'Management Fee',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
      {
        name: 'performanceFeePercentage',
        title: 'Performance Fee/Carried Interest',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'earlyRedemptionFeePercentage',
        title: 'Early Redemption Fee',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Performance',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'preferredReturnPercentage',
        title: 'Preferred Return',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'hurdleRatePercentage',
        title: 'Hurdle Rate',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
      {
        name: 'catchUpPercentage',
        title: 'Catch Up',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'highWaterMark',
        title: 'High-water Mark',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'Currency',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'hasClawbackProvision',
        title: 'Is there a clawback clause?',
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          mappingMethod: (x) => x,
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Liquidity',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'daysOfCapitalLockup',
        title: 'Lockup Period (Days)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
      {
        name: 'postLockupLiquidity',
        title: 'Liquidity after Lockup',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'daysNoticeForRedemption',
        title: 'Redemption Notice (Days)',
        helpText: 'Number of days the fund needs to fulfill redemptions',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
      {
        name: 'annualWithdrawalPercentageWithNoRedemptionFee',
        title: 'Annual Withdrawal Not Subject to Redemption Fee',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Communication',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'limitedPartnerCommunicationFrequencyId',
        title: 'How often will LPs receive updates from the firm?',
        isFormField: true,
        component: RadioField,
        extraProps: {
          options: questionOptions.communicationFrequencies,
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Benchmark',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'benchmarkIndexId',
        title: 'Select a custom benchmark (optional)',
        helpText:
          'By default, we will use the S&P 500 Index. To showcase your fund against another index, please select a different index.',
        component: DropDownField,
        extraProps: {
          options: questionOptions.fundIndices,
          showSearch: true,
          allowClear: true,
        },
      },
    ],
  },
]

export const ServiceProviderQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        component: StaticText,
        extraProps: {
          labelText: `Don't see a service provider?`,
          label: `Send an email to help@context365.com and we'll add it. In the meantime, please continue adding fund details.`,
          alertType: 'info',
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        name: 'primeBrokerIds',
        title: 'Prime Broker',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.primeBrokers,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'fundAdministratorIds',
        title: 'Admin Firm',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.administrators,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'auditorIds',
        title: 'Audit Firm',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.auditors,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'legalCounselIds',
        title: 'Law Firm',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.legalCounsels,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'investmentAdvisorIds',
        title: 'Investment Advisor',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.investmentAdvisors,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'informationTechnologyProviderIds',
        title: 'Information Technology Firm',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.itProviders,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'marketerIds',
        title: 'Marketer',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.marketers,
          },
          defaultObject: null,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'custodianIds',
        title: 'Custodian',
        component: ArrayField,
        extraProps: {
          subComponent: ServiceProviderField,
          subProps: {
            options: questionOptions.custodians,
          },
          defaultObject: null,
        },
      },
    ],
  },
]

export const PositionQuestions = () => [
  {
    questions: [
      {
        name: 'totalInvestmentsSinceInception',
        title: 'Total Number of Investments Since Inception',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
      {
        name: 'targetInvestmentHorizonYears',
        title: 'Target Investment Horizon (Years)',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'totalPublicPositions',
        title: 'Number of Public Positions',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
      {
        name: 'totalPrivatePositions',
        title: 'Number of Private Positions',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'number',
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'averagePositionSize',
        title: 'Core Position Size',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
      {
        name: 'marketCapitalization',
        title: 'Market Capitalization',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'currency',
        },
      },
    ],
  },
  {
    half: true,
    questions: [
      {
        name: 'leverageUtilization',
        title: 'Leverage',
        isFormField: true,
        component: InputNumberField,
        extraProps: {
          type: 'percentage',
        },
      },
    ],
  },
]

export const MediaQuestions = (values) => [
  {
    questions: [
      {
        name: 'media',
        component: UploadMultipleFileField,
        extraProps: {
          hint: [
            'File Types: .JPG, .PNG, .BMP, .AVI, .MOV, .MP4, .MPEG4',
            'Max File Size: 500MB',
          ],
          accept: '.jpg, .png, .bmp, .mov, .avi, .mp4, .mpeg4',
          listType: 'picture-card',
          listLength: 5,
        },
      },
    ],
  },
  {
    questions: [
      {
        name: 'links',
        title: 'External Links',
        component: ArrayField,
        className: isEmpty(values.media) ? null : 'pt-24',
        extraProps: {
          subComponent: LinkField,
          subProps: {},
          defaultObject: {
            title: null,
            url: null,
          },
        },
      },
    ],
  },
]

export const PortfolioQuestions = (values, questionOptions) => [
  {
    questions: [
      {
        name: 'portfolioCompanies',
        title: '',
        component: ArrayField,
        extraProps: {
          subComponent: PortfolioField,
          subProps: {
            securityOptions: map(questionOptions.securityTypes, (x) => ({
              label: x.name,
              value: x.id,
            })),
          },
          defaultObject: {
            companyName: '',
            investmentDate: null,
            securityTypeId: null,
            securityInternalRateOfReturnPercentage: null,
            marketingMaterialUrls: [],
          },
        },
      },
    ],
  },
  { questions: [{ component: Divider }] },
  {
    questions: [
      {
        component: Header,
        extraProps: {
          title: 'Dataroom',
        },
      },
    ],
  },
  {
    questions: [
      {
        isFormField: true,
        name: 'marketingMaterialUrls',
        title:
          'Please upload any materials related to the fund or firm that you’d like LPs and potential LPs to review',
        component: FileField,
        extraProps: {
          hint: ['File Types: .pdf', 'Max File Size: 5MB'],
        },
      },
    ],
  },
]
