import PropTypes from 'prop-types'
import { Pagination } from 'antd'
import classnames from 'classnames'

const Paginator = ({
  className,
  currentPage,
  total,
  loadPage,
  pageSize = 10,
}) => {
  return (
    <Pagination
      className={classnames('mb-4', className)}
      current={currentPage}
      total={total}
      onChange={(page, size) => {
        loadPage(page, size)
      }}
      showSizeChanger={true}
      defaultPageSize={pageSize}
    />
  )
}

Paginator.propTypes = {
  currentPage: PropTypes.number,
  total: PropTypes.number,
  loadPage: PropTypes.func,
  pageSize: PropTypes.number,
}

export default Paginator
