import { Image } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import WinnerBanner from '~/assets/winner_banner.svg'
import Winner from '~/assets/winner.svg'
import CampaignsPremiumTierBody from './CampaignsPremiumTierBody'
import PremiumTierModalPro from './PremiumTierModalPro'
import PremiumTierModalStarterPro from './PremiumTierModalStarterPro'

const PremiumTierBody = ({ onlyPro }) => {
  const { campaignsModule } = useFlags()
  const { role } = useSelector((state) => state.auth)
  const Role = role === 'Manager' ? 'allocator' : 'manager'
  const starterList = [
    `Respond to inbound messages and meeting requests from
  relevant ${Role}s`,
    `Accept meetings, invite colleagues, and share details about
  your strategy prior to the meeting`,
    `Take meetings with ${Role}s who want to meet with you from
  your desktop or phone`,
    'Schedule follow up meetings',
    'Phone, email, and chat support',
  ]
  let proList = [
    `Search for and discover ${Role}s across various investment interest categories`,
    `Access unique data and view ${Role} profiles ${
      role === 'CFN' ? '' : 'that match your strategy'
    }`,
    'Save searches and create customized platform alerts',
    'Message & meet with members',
    'Schedule meetings and invite colleagues',
    'Phone, email, and chat support',
    'Search and apply to exclusive investment mandates',
    'Create and host roundtables and fund presentations',
  ]

  proList =
    role === 'CFN'
      ? proList.filter((x) => x !== 'Create and broadcast roadshows')
      : proList

  if (campaignsModule) {
    return <CampaignsPremiumTierBody />
  }

  return (
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <Image
        src={WinnerBanner}
        className="cc-modal-block-banner-small"
        preview={false}
      />
      <Image src={Winner} className="cc-modal-block-winner" preview={false} />

      <div className="cc-modal-block-body">
        <div className="cc-modal-block-header">
          You’ve discovered a premium feature for Context365 by Apex members.
        </div>
        {onlyPro ? (
          <PremiumTierModalPro proList={proList} />
        ) : (
          <PremiumTierModalStarterPro
            starterList={starterList}
            proList={proList}
          />
        )}
      </div>
    </div>
  )
}

export default PremiumTierBody
