import PropTypes from 'prop-types'
import { Badge } from '@context365/badge'
import { Tooltip } from '@context365/popovers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { DiscoverBadges } from '../Discover'
import MatchmakingScore from '../MatchmakingScore'

const getSelectedLists = (discoverLists, mandateId) =>
  filter(discoverLists, (list) =>
    find(list.discoverListLinks, ['toMandateId', mandateId])
  )

const getSelectedBadges = (discoverLists, mandate, campaignsEnabled) => {
  const pipelineBadge =
    campaignsEnabled && mandate.campaignApplications?.length > 0
      ? [
          {
            name: 'In Pipeline',
            className: 'bg-green-100 text-white uppercase',
          },
        ]
      : []
  return concat(
    pipelineBadge,
    getSelectedLists(discoverLists, mandate.mandateId)
  )
}

const DiscoverMandateResultHeader = ({ mandate, discoverLists }) => {
  const { campaignsModule } = useFlags()
  return (
    <div className="flex justify-between">
      <div>
        <DiscoverBadges
          badges={getSelectedBadges(discoverLists, mandate, campaignsModule)}
        />
        <div className="flex space-x-4">
          <div className="type-body-regular-md">{mandate.title}</div>
          {mandate.isAnonymous && (
            <div>
              <Tooltip
                placement="top"
                text="The mandate provider posted this mandate anonymously. Apply to the mandate if you’re a fit."
              >
                <Badge size="small">Anonymous</Badge>
              </Tooltip>
            </div>
          )}
          {mandate.isExclusive && (
            <div>
              <Tooltip
                placement="top"
                text="This mandate is exclusively posted on Context365."
              >
                <Badge status="warning" size="small">
                  Exclusive
                </Badge>
              </Tooltip>
            </div>
          )}
        </div>
        {!mandate.isAnonymous && (
          <div className="type-body-regular-sm">{mandate.companyName}</div>
        )}
        <div className="type-body-regular-xs">
          {mandate.primaryInvestorCategory}
        </div>
        <div>
          {mandate.isClosed && (
            <Badge status="error" size="small">
              {mandate.status}
            </Badge>
          )}
        </div>
      </div>
      <div className="flex flex-row items-center -mt-4 -mr-3 pt-4">
        {mandate.matchmakingScore !== 0 && !isNil(mandate.matchmakingScore) && (
          <MatchmakingScore score={mandate.matchmakingScore} threshold={0} />
        )}
      </div>
    </div>
  )
}

DiscoverMandateResultHeader.propTypes = {
  mandate: PropTypes.object.isRequired,
  discoverLists: PropTypes.arrayOf(PropTypes.object),
}

export default DiscoverMandateResultHeader
