import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Select, message } from 'antd'
import isNil from 'lodash/isNil'
import throttle from 'lodash/throttle'
import {
  getCompanyContactsForCompose,
  getContactsForCompose,
} from '~/actions/messages'
import ProfilePictureGroup from '../ProfilePictureGroup'

const { Option } = Select

const SelectUsers = ({
  firstContact,
  setSelectedContactIDs,
  setOtherContacts,
  setFirstContact,
}) => {
  const [autofillContacts, setAutofillContacts] = useState([])
  const [autoFillLoading, setAutoFillLoading] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const searchContactsHandler = async (e) => {
    if (e === '' || e.length < 3) return
    setAutoFillLoading(true)
    try {
      const contactsRaw = await getContactsForCompose(e)
      setAutofillContacts(contactsRaw.data.result)
    } catch (error) {
      message.error('There was an error while searching for contacts')
    } finally {
      setAutoFillLoading(false)
    }
  }

  const delayedQuery = throttle((q) => searchContactsHandler(q), 1000)

  const searchHandler = (e) => {
    delayedQuery(e)
  }

  const contactSelectedHandler = useCallback(
    async (e) => {
      if (isNil(e)) {
        setAutofillContacts([])
        setFirstContact(null)
        setSelectedContactIDs([])
        setOtherContacts([])
      } else
        try {
          const contact = autofillContacts.find((x) => x.companyContactID === e)
          setFirstContact(contact)
          setSelectedContactIDs([contact.contactId])
          const colleaguesResultRaw = await getCompanyContactsForCompose(
            contact.companyId
          )
          setOtherContacts(colleaguesResultRaw.data.result)
        } catch (error) {
          message.error('An error occurred while fetching colleagues')
        }
    },
    [autofillContacts, setFirstContact, setOtherContacts, setSelectedContactIDs]
  )

  const renderOption = (contact) => (
    <Option
      name={contact.contactName.toLowerCase()}
      value={contact.companyContactID}
    >
      <div className="messages-compose-option-contact">
        <div className="messages-compose-option-contact-image">
          <ProfilePictureGroup
            diameter={32}
            maxImages={1}
            profiles={[contact]}
          />
        </div>
        <div className="messages-compose-contact-option-text">
          <div className="cc-body-text messages-compose-option-contact-name">
            {contact.contactName}
          </div>
          <div className="cc-xsmall-font messages-compose-option-company-name">
            {contact.companyName}
          </div>
        </div>
        <div className="cc-xsmall-font messages-compose-option-company-category">
          {contact.companyType}
        </div>
      </div>
    </Option>
  )

  const renderValue = (contact) => (
    <div className="messages-compose-option-contact">
      <div className="messages-compose-option-contact-image">
        <ProfilePictureGroup diameter={32} maxImages={1} profiles={[contact]} />
      </div>
      <div className="messages-compose-contact-option-text">
        <div className="cc-body-text messages-compose-option-contact-name">
          {contact.contactName}
        </div>
        <div className="cc-xsmall-font messages-compose-option-company-name">
          {contact.companyName}
        </div>
      </div>
      <div className="cc-xsmall-font messages-compose-option-company-category">
        {contact.companyType}
      </div>
    </div>
  )

  return (
    <Select
      allowClear={true}
      loading={autoFillLoading}
      showSearch
      className="messages-compose-searchselect"
      placeholder="Select a person"
      optionFilterProp="value"
      filterOption={(input, option) =>
        option.props.name.indexOf(input.toLowerCase()) >= 0
      }
      onChange={contactSelectedHandler}
      onSearch={searchHandler}
      value={firstContact && renderValue(firstContact)}
      onKeyDown={(event) => {
        if (
          event.key === 'Enter' &&
          (autofillContacts.length < 1 || !dropdownOpen)
        ) {
          setDropdownOpen(true)
        }
      }}
      open={dropdownOpen}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
    >
      {autofillContacts.map((x) => renderOption(x))}
    </Select>
  )
}

SelectUsers.propTypes = {
  setSelectedContactIDs: PropTypes.func,
  setOtherContacts: PropTypes.func,
  setFirstContact: PropTypes.func,
  firstContact: PropTypes.object,
}

export default SelectUsers
