import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import AcceptMeetingComponent from '../../AcceptMeetingComponent'
import DeclineOnlineMeeting from '../../DeclineOnlineMeeting'
import { AcceptSummitMeeting } from '../../MeetingActions'
import PremiumTierModal from '../../PremiumTierModal/PremiumTierModal'
import { MeetingShape } from '../MessagePropTypes'
import './MeetingActionsPopup.less'

const MeetingActionsPopup = ({
  meeting,
  companyName,
  contacts,
  onMeetingCompleted = () => {},
  onMeetingAccepted = () => {},
  onMeetingDeclined = () => {},
}) => {
  const [declineMeetingVisible, setDeclineMeetingVisible] = useState(false)
  const [acceptMeetingVisible, setAcceptMeetingVisible] = useState(false)
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false)

  const role = useSelector((state) => state.auth.role)
  const companyId = useSelector((state) => state.auth.company.companyId)

  const declineClickedHandler = () => {
    setDeclineMeetingVisible(true)
  }

  const acceptClickedHandler = () => {
    setAcceptMeetingVisible(true)
  }

  const declineClosedHandler = () => {
    setDeclineMeetingVisible(false)
  }

  const acceptClosedHandler = () => {
    setAcceptMeetingVisible(false)
  }

  const meetingCompletedHandler = () => {
    onMeetingAccepted()
    onMeetingCompleted()
  }

  const meetingCancelledHandler = () => {
    onMeetingDeclined()
    onMeetingCompleted()
  }

  const meetingForbiddenHandler = () => {
    setAcceptMeetingVisible(false)
    setDeclineMeetingVisible(false)
    setUpgradeModalVisible(true)
  }

  const RescheduleModal =
    meeting.meetingType === 'Summit' ? (
      <AcceptMeetingComponent
        visible={acceptMeetingVisible}
        closeModal={acceptClosedHandler}
        categoryName={meeting.company.category}
        meetingType={meeting.meetingType}
        company={companyName}
        meetingId={meeting.meetingId}
        meetingDateTime={meeting.meetingDateTime}
        eventId={meeting.eventId}
        onAccept={meetingCompletedHandler}
        participants={contacts
          .filter((x) => x.companyId === companyId)
          .map((x) => x.contactId)}
        toParticipants={contacts
          .filter((x) => x.companyId !== companyId)
          .map((x) => x.contactId)}
        isVirtualSummit={meeting?.virtualSummit}
        isOptimized={meeting?.isOptimized}
        timezone={meeting?.meetingTimeZone}
      />
    ) : (
      <AcceptSummitMeeting
        visible={acceptMeetingVisible}
        closeModal={acceptClosedHandler}
        company={companyName}
        meetingId={meeting.meetingId}
        meetingDateTime={meeting.meetingDateTime}
        role={role}
        onAccept={meetingCompletedHandler}
        haveAnswered={meeting.haveAnswered}
        onForbidden={meetingForbiddenHandler}
        roadshowId={meeting?.roadshowId}
      />
    )

  return (
    <div className="messaging-chat-meeting-actions">
      <div className="cc-body-text messaging-chat-meeting-actions-title">
        You&apos;ve been invited to a meeting with
        <span className="messaging-chat-meeting-actions-company">
          {' '}
          {companyName}
        </span>
      </div>
      <div className="messaging-chat-meeting-actions-buttons">
        <Button
          className="decline"
          type="danger"
          onClick={declineClickedHandler}
        >
          Decline meeting
        </Button>
        {declineMeetingVisible && (
          <DeclineOnlineMeeting
            onCancel={meetingCancelledHandler}
            company={companyName}
            visible={declineMeetingVisible}
            meetingId={meeting.meetingId}
            closeModal={declineClosedHandler}
            meetingType={meeting.meetingType}
            onForbidden={meetingForbiddenHandler}
          />
        )}
        <Button
          className="accept"
          type="primary"
          onClick={acceptClickedHandler}
        >
          Accept meeting
        </Button>
        {acceptMeetingVisible && RescheduleModal}
      </div>
      <PremiumTierModal
        bypassContainer={true}
        closable={true}
        visible={upgradeModalVisible}
        onModalClosed={() => {
          setUpgradeModalVisible(false)
        }}
      />
    </div>
  )
}

MeetingActionsPopup.propTypes = {
  meeting: MeetingShape.isRequired,
  companyName: PropTypes.string.isRequired,
  contacts: PropTypes.object,
  onMeetingCompleted: PropTypes.func,
  onMeetingAccepted: PropTypes.func,
  onMeetingDeclined: PropTypes.func,
}

export default MeetingActionsPopup
