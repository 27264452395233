export const agendas = [
  {
    eventId: 1764, //Miami 2022 - this is the ID in Production
    url: 'https://media.context365.com/public-context/Miami2022/Miami2022_Agenda_Final_Updated.pdf',
  },
  {
    eventId: 1767, //NY 2022 - this is the ID in Production
    url: 'https://media.context365.com/public-context/NewYork2022/Context365_New_York_2022_AGENDA.pdf',
  },
  {
    eventId: 1770, //Miami 2023 - production
    url: 'https://media.context365.com/public-context/Miami2023/Miami_2023_AGENDA.pdf',
  },
  {
    eventId: 3001, //Context Academy event in UAT
    url: 'https://media.context365.com/public-context/Miami2022/Miami2022_Agenda_Final_Updated.pdf',
  },
  {
    eventId: 3040, //Test event in QA
    url: 'https://contextsummits.com/',
  },
]

export const covidPolicies = [
  {
    eventId: 1764, //Miami 22 Prod
    url: 'https://media.context365.com/public-context/Miami2022/CONTEXT_COVID-19_HEALTH_AND_SAFETY_PROTOCOLS_2022.pdf',
  },
]
