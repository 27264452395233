import * as React from 'react'
import PropTypes from 'prop-types'
import { useLiveRef } from '@context365/hooks'
import { Col, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { SP500_FUND_INDEX_ID } from '~/constants/indexFunds'
import { addLineBreaks } from '~/utils/helpers'
import {
  FundPerformanceChart,
  FundReturnAumChart,
  FundReturnDistributionChart,
  FundReturnSpxChart,
  PerformanceTable,
  VamiChart,
  useChartWidth,
  useFundPerformanceData,
} from '../FundPerformanceCharts'
import StatsSideBar from './StatsSideBar'
import './FundTearSheet.less'

const FundTearSheet = ({
  fundId,
  indexFund = SP500_FUND_INDEX_ID,
  details,
  onLoaded,
  visible = false,
}) => {
  const isNotNarrative = details?.narrative === false
  const fundChartsContainer = React.useRef(null)
  const chartWidth = useChartWidth(fundChartsContainer)

  const {
    loading,
    fundMonthlyReturns,
    fundYTDReturns,
    fundReturnAUM,
    fundReturnSPX,
    fundReturnDistribution,
    fundPerformance,
    customBenchmark,
    fundVami,
    fundPeriod,
    fundStats,
    benchmarkStats,
  } = useFundPerformanceData(fundId, indexFund, { includeStats: true })

  const onLoadedRef = useLiveRef(onLoaded)
  React.useEffect(() => {
    if (!loading) {
      onLoadedRef.current()
    }
  }, [loading, onLoadedRef])

  return (
    <div
      className="TearSheetContainer"
      style={{ visibility: visible ? 'visible' : 'hidden' }}
    >
      <div>
        <img src="/static/img/new-logo.svg" alt="Context365 by Apex" />
      </div>
      {!isNil(details?.name) && <h1>{details.name}</h1>}
      {!isNil(details?.investmentVehicle) && (
        <div>
          <strong>Investment Vehicle:</strong> {details.investmentVehicle}
        </div>
      )}
      {!isNil(details?.companyName) && (
        <div>
          <strong>Company Name:</strong> {details.companyName}
        </div>
      )}
      {!isNil(details?.tradingMethod) && (
        <div>
          <h1>Fund Strategy &amp; Description</h1>
          <p>{addLineBreaks(details.tradingMethod)}</p>
        </div>
      )}
      {isNotNarrative && (
        <>
          <Row className="HidePrint">
            <Col span={16}>
              <div ref={fundChartsContainer}>
                <PerformanceTable
                  fundMonthlyReturns={fundMonthlyReturns}
                  fundYtdReturns={fundYTDReturns}
                  scroll={{ x: 800 }}
                />
                <VamiChart
                  fundVami={fundVami}
                  customBenchmark={customBenchmark}
                  fundPeriod={fundPeriod}
                  width={chartWidth}
                />
                <FundReturnAumChart
                  fundReturnAum={fundReturnAUM}
                  width={chartWidth}
                />
                <FundReturnSpxChart
                  fundReturnSpx={fundReturnSPX}
                  width={chartWidth}
                />
                <FundReturnDistributionChart
                  fundReturnDistribution={fundReturnDistribution}
                  width={chartWidth}
                />
                <FundPerformanceChart
                  fundPerformance={fundPerformance}
                  width={chartWidth}
                />
              </div>
            </Col>
            <Col span={8}>
              <StatsSideBar
                details={details}
                stats={fundStats}
                bmStats={benchmarkStats}
              />
            </Col>
          </Row>
          <div className="ShowPrint">
            <PerformanceTable
              fundMonthlyReturns={fundMonthlyReturns}
              fundYtdReturns={fundYTDReturns}
            />
          </div>
        </>
      )}
      <StatsSideBar
        showOnPrint
        details={details}
        stats={fundStats}
        bmStats={benchmarkStats}
      />
      <div className="TextSection">
        <h1>MANAGER BIOS / DESCRIPTION</h1>
        {addLineBreaks(details.managerBio)}
      </div>
      <div className="TextSection NoBreaks">
        <h1>ADMINISTRATION INFORMATION</h1>
        <AdminSection details={details} />
      </div>
      <div>
        <h1>IMPORTANT DISCLAIMER</h1>
        The provision of any information regarding any fund or fund manager (the
        &quot;Fund Information&quot;) to you is for informational purposes only
        and does not constitute any offer to sell or the solicitation of an
        offer to buy any securities or investments. Apex US Distribution LLC
        makes no representation about, and does not independently verify, the
        Fund Information and shall not be responsible for its accuracy,
        completeness or your reliance thereon. The Fund Information is
        self-reported by the associated fund or fund manager to Apex US
        Distribution LLC. Apex US Distribution LLC is not making any
        recommendation, express or implied, as to the suitability or otherwise
        of any investment with, and is not acting as agent, promoter,
        underwriter or placement agent for, any fund or the fund manager, or any
        other client represented by the fund manager. You must assess the
        suitability of any particular fund or strategy and carry out appropriate
        due diligence on your own behalf in relation to the same and Apex US
        Distribution LLC provides no advice in that regard. Apex US Distribution
        LLC makes no representation as to the fund’s or fund manager’s
        compliance with any applicable laws or regulations. Apex US Distribution
        LLC shall not be liable for any losses that may arise, directly or
        indirectly, from your introduction to, or investment with, any fund or
        fund manager.
        {details.disclaimer && (
          <div dangerouslySetInnerHTML={{ __html: details.disclaimer }} />
        )}
      </div>
    </div>
  )
}

FundTearSheet.propTypes = {
  fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  indexFund: PropTypes.number,
  details: PropTypes.object.isRequired,
  onLoaded: PropTypes.func,
  visible: PropTypes.bool.isRequired,
}

export default FundTearSheet

function AdminSection({ details }) {
  const fields = getAdminFields(details)
  const rows = groupFields(fields)

  return rows.map((row, index) => (
    <Row key={index}>
      <Col span={12}>
        <strong>{row[0].name}:</strong>
        {Array.isArray(row[0].value) ? (
          row[0].value.map((val, i) => <div key={i}>{val}</div>)
        ) : (
          <div>{row[0].value}</div>
        )}
      </Col>
      {row.length > 1 ? (
        <Col span={12}>
          <strong>{row[1].name}:</strong>
          {Array.isArray(row[1].value) ? (
            row[1].value.map((val, i) => <div key={i}>{val}</div>)
          ) : (
            <div>{row[1].value}</div>
          )}
        </Col>
      ) : null}
    </Row>
  ))
}

function getAdminFields(details) {
  const fields = []

  if (!isNil(details) && !isNil(details.administratorFirm)) {
    fields.push({
      name: 'Administrator Firm',
      value: details.administratorFirm,
    })
  }

  if (!isNil(details) && !isNil(details.lawFirm)) {
    fields.push({
      name: 'Law Firm',
      value: details.lawFirm,
    })
  }

  if (!isNil(details) && !isNil(details.auditFirm)) {
    fields.push({
      name: 'Auditor Firm',
      value: details.auditFirm,
    })
  }

  if (!isNil(details) && !isNil(details.custodian)) {
    fields.push({
      name: 'Custodian',
      value: details.custodian,
    })
  }

  if (!isNil(details) && !isNil(details.marketer)) {
    fields.push({
      name: 'Marketer',
      value: details.marketer,
    })
  }

  const primes = []

  if (!isNil(details) && !isNil(details.primeBroker1)) {
    primes.push(details.primeBroker1)
  }

  if (!isNil(details) && !isNil(details.primeBroker2)) {
    primes.push(details.primeBroker2)
  }

  if (!isNil(details) && !isNil(details.primeBroker3)) {
    primes.push(details.primeBroker3)
  }

  if (!isEmpty(primes)) {
    fields.push({
      name: 'Prime Broker',
      value: primes,
    })
  }

  return fields
}

function groupFields(fields) {
  const grouped = []
  let row = []

  for (let i = 0; i < fields.length; i++) {
    if (i % 2 === 0) {
      row.push(fields[i])
    } else {
      row.push(fields[i])
      grouped.push(row)
      row = []
    }
  }

  //if there is one extra field
  if (row.length > 0) grouped.push(row)

  return grouped
}
