import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import {
  Exposure,
  FolderOpenOutlined,
  OpenInNew,
  QuestionAnswerOutlined,
} from '@context365/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isNil from 'lodash/isNil'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { ApplyToMandateButton, ViewApplicationButton } from '../Application'

const DiscoverMandateResultFooter = ({
  mandate,
  canAskQuestion,
  canApplyToMandate,
  onShowProfileModal,
  onShowApplyToMandateModal,
  onShowAskAQuestionModal,
  onShowAddToListModal,
  fromPreview = false,
}) => {
  const { trackEvent } = useTracking()
  const { campaignsModule } = useFlags()

  return (
    <div className="flex flex-row justify-between">
      <div className="justify-start">
        {!fromPreview && (
          <Button
            className="color-secondary-90"
            status="secondary"
            iconLeft={<Exposure />}
            onClick={() => onShowAddToListModal()}
          >
            Lists
          </Button>
        )}
      </div>
      <div className="justify-end space-x-2">
        {fromPreview ||
        isNil(mandate.documentAddMean) ? null : campaignsModule ? (
          <Button
            as={Link}
            to={`/mandate/${mandate.mandateId}?tab=documentation`}
            iconLeft={<FolderOpenOutlined />}
            onClick={() => {
              trackEvent({
                eventName: 'click',
                element: 'Mandate Open Documentation',
              })
            }}
          >
            Open Dataroom
          </Button>
        ) : (
          <Button
            iconLeft={<FolderOpenOutlined />}
            onClick={() => {
              trackEvent({
                eventName: 'click',
                element: 'Mandate Open Documentation',
              })
              onShowProfileModal(true, mandate)
            }}
          >
            Open Dataroom
          </Button>
        )}
        {!fromPreview && canAskQuestion && !campaignsModule && (
          <Button
            iconLeft={<QuestionAnswerOutlined />}
            onClick={(e) => {
              trackEvent({
                eventName: 'click',
                element: 'Mandate Open Ask Question Modal',
              })
              e.stopPropagation()
              onShowAskAQuestionModal()
            }}
          >
            Ask a Question
          </Button>
        )}
        {!fromPreview && (
          <ViewApplicationButton
            campaignEntity={{
              ...mandate,
              campaignId: mandate.campaignId,
              campaignName: mandate.title,
            }}
          />
        )}
        {canApplyToMandate && !fromPreview && (
          <ApplyToMandateButton
            mandate={mandate}
            onApply={(fundId) => {
              onShowApplyToMandateModal(fundId)
            }}
          />
        )}
        {fromPreview && (
          <Button
            variant="filled"
            as="a"
            href={`/discover/mandates?search=${mandate.title}&selectedId=${mandate.mandateId}`}
            target="_blank"
            iconRight={<OpenInNew />}
          >
            Open
          </Button>
        )}
      </div>
    </div>
  )
}

DiscoverMandateResultFooter.propTypes = {
  mandate: PropTypes.object.isRequired,
  canAskQuestion: PropTypes.bool.isRequired,
  canApplyToMandate: PropTypes.bool.isRequired,
  onShowProfileModal: PropTypes.func.isRequired,
  onShowApplyToMandateModal: PropTypes.func.isRequired,
  onShowAskAQuestionModal: PropTypes.func.isRequired,
  onShowAddToListModal: PropTypes.func.isRequired,
  fromPreview: PropTypes.bool,
}

export default DiscoverMandateResultFooter
