import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { faBookmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Input, Modal, Tooltip, message } from 'antd'
import isNil from 'lodash/isNil'
import { composeMessage } from '~/actions/messages'
import ProfilePictureGroup from '../../ProfilePictureGroup'
import SelectUsers from '../../SelectUsers'
import './ComposeMessage.less'

const { TextArea } = Input

const ComposeMessage = ({ visible, onClose, refreshList }) => {
  const [firstContact, setFirstContact] = useState(null)
  const [otherContacts, setOtherContacts] = useState([])
  const [selectedContactIDs, setSelectedContactIDs] = useState([])
  const [messageBody, setMessageBody] = useState('')
  const [sendingMessageLoading, setSendingMessageLoading] = useState(false)

  // delaying for 1s before performing search to
  //accept additional input by the user

  // one of the collagues has been selected / deselected
  const participantChangedHandler = useCallback(
    (e, contact) => {
      const { checked } = e.target
      if (checked === true) {
        setSelectedContactIDs([...selectedContactIDs, contact.contactId])
      } else {
        setSelectedContactIDs(
          selectedContactIDs.filter((x) => x !== contact.contactId)
        )
      }
    },
    [selectedContactIDs]
  )

  // user decides to send message
  const sendMessageHandler = useCallback(async () => {
    setSendingMessageLoading(true)
    try {
      await composeMessage(
        selectedContactIDs,
        firstContact.companyId,
        messageBody
      )
      refreshList?.()
      recycleUI()
      onClose()
    } catch (error) {
      message.error('There was an error while attempting to send the message')
    } finally {
      setSendingMessageLoading(false)
    }
  }, [firstContact, selectedContactIDs, messageBody, onClose, refreshList])

  // renders one checkbox for each contact in the colleagues list
  const renderCheckbox = (contact) => {
    const isChecked = selectedContactIDs.includes(contact.contactId)
    return (
      <div className="messages-compose-contact-checkbox-card">
        <div className="messages-compose-contact-checkbox">
          <Checkbox
            style={{
              width: '20px',
            }}
            checked={isChecked}
            disabled={contact.contactId === firstContact.contactId}
            onChange={(e) => participantChangedHandler(e, contact)}
          />
        </div>
        <div className="messages-compose-contact-checkbox-image">
          <ProfilePictureGroup
            diameter={24}
            maxImages={1}
            profiles={[
              {
                image: contact.imageUrl,
                ...contact,
              },
            ]}
          />
        </div>
        <div className="messages-compose-contact-checkbox-text">
          <div className="cc-small-font messages-compose-contact-checkbox-name">
            {contact.contactName}
          </div>
          <div className="cc-xsmall-font messages-compose-contact-checkbox-title">
            {contact.jobTitle}
          </div>
        </div>
        <div className="messages-compose-contact-checkbox-summit-presence">
          {contact.eventID > 0 ? (
            <Tooltip title={`Attending the ${contact.eventName} summit`}>
              <FontAwesomeIcon icon={faBookmark} />
            </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }

  // method that resets data for next compose
  const recycleUI = () => {
    setFirstContact(null)
    setOtherContacts([])
    setSelectedContactIDs([])
    setMessageBody('')
  }

  return (
    <Modal
      style={{
        minWidth: '646px',
      }}
      visible={visible}
      onOk={sendMessageHandler}
      onCancel={() => {
        recycleUI()
        onClose()
      }}
      okButtonProps={{
        disabled: !(
          !isNil(firstContact) &&
          !isNil(messageBody) &&
          messageBody !== ''
        ),
        loading: sendingMessageLoading,
      }}
      okText="Send Message"
      title="Compose Message"
    >
      <div className="messages-compose-container">
        <div className="cc-heading5 messages-compose-title">
          Search for the person or people you want to message
        </div>

        {/* dropdown with the list of 365 contacts found for the current search text */}
        <div className="messages-compose-contacts">
          {visible && (
            <SelectUsers
              firstContact={firstContact}
              setFirstContact={setFirstContact}
              setOtherContacts={setOtherContacts}
              setSelectedContactIDs={setSelectedContactIDs}
            />
          )}
        </div>

        {/* if we have a contact to start from i.e. the user has selected one contact
          a list of other contacts are selected, returned as colleagues of the
          initial contact; each colleague is displayed as a checkbox */}
        {firstContact && otherContacts.length > 0 && (
          <div className="messages-compose-more-contacts">
            <div className="cc-heading5 messages-compose-title">
              {`You can also add ${firstContact.contactName}'s colleagues`}
            </div>
            <div className="messages-compose-more-contacts-checks">
              {otherContacts.map((x) => renderCheckbox(x))}
            </div>

            {/* textarea for message is also shown only if an initial contact is selected */}
            <div className="cc-heading5 messages-compose-title">
              Enter the message you want to send:
            </div>
            <div className="messages-compose-textbox">
              <TextArea
                value={messageBody}
                onChange={(e) => {
                  setMessageBody(e.target.value)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

ComposeMessage.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  refreshList: PropTypes.func,
}

export default ComposeMessage
