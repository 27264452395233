import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ContentPasteOutlined,
  EventNoteOutlined,
  GroupsOutlined,
  PeopleOutlined,
} from '@context365/icons'
import { Tabs } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { getEventFunds } from '~/actions/fundList'
import Agenda from '~/components/Agenda'
import AttendeeList from './attendees/AttendeeList'
import EventMeetingsTab from './meetings/EventMeetingsTab'
import Schedule from './Schedule'
import './SummitDetails.less'

const { TabPane } = Tabs

const SummitDetails = ({
  events,
  selectedEvent,
  role,
  tab = 'attendees',
  subtab = '',
}) => {
  const { push } = useHistory()
  const {
    params: { id },
  } = useRouteMatch()
  const selectedEventId = parseInt(id, 10)
  const isFundManager = role.toLowerCase() === 'manager'
  const filteredEvents = events.filter(
    (item) => item.eventId === selectedEventId
  )
  const { schedulerAccessDate } = isEmpty(filteredEvents)
    ? { schedulerAccessDate: '' }
    : filteredEvents[0]

  // ;[selectedEvent] = events.filter((item) => item.eventId == selectedEventId)

  const [attendeeAccess, setAttendeeAccess] = useState(true)
  const [defaultTab] = useState(tab || 'attendees')
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (isFundManager) {
      getEventFunds(selectedEvent.eventId).then((response) => {
        setAttendeeAccess(response.data.result.length > 0) //cannot access the list if you dont have funds tied to the event
      })
    } else setAttendeeAccess(true)
  }, [selectedEvent, isFundManager, refresh])

  const handleTabChange = (e) => {
    push(`/summits/${selectedEvent.eventId}/${e}`)
  }

  return (
    <div className="SummitDetails">
      <div className="type-subtitle-md mb-2">{selectedEvent.eventName}</div>
      <Tabs
        defaultActiveKey={defaultTab}
        size="large"
        className="SummitDetails-tabs"
        onChange={handleTabChange}
      >
        <TabPane
          className="SummitDetails-tabs-tab"
          tab={
            <span className="flex align-middle space-x-2">
              <GroupsOutlined />
              <span>Attendees</span>
            </span>
          }
          key="attendees"
        >
          <AttendeeList
            attendeeAccess={attendeeAccess}
            schedulerAccessDate={schedulerAccessDate}
            eventId={selectedEvent.eventId}
            companyEventId={selectedEvent.companyEventId}
            isOptimized={selectedEvent.isOptimized}
            role={role}
            creditsAvailable={selectedEvent.creditsAvailable}
            contextPoints={selectedEvent.contextPoints}
            subtab={subtab}
            onRefresh={() => setRefresh(!refresh)}
            confirmedMeetingLimit={selectedEvent.confirmedMeetingsLimit}
            confirmedMeetings={selectedEvent.confirmedMeetings}
            disabledMeetingRequest={selectedEvent.disabledMeetingRequest}
            boothSP={selectedEvent.booths}
          />
        </TabPane>
        <TabPane
          className="SummitDetails-tabs-tab"
          tab={
            <span className="flex align-middle space-x-2">
              <PeopleOutlined />
              <span>Meetings</span>
            </span>
          }
          key="meetings"
        >
          <EventMeetingsTab
            confirmedMeetingLimit={selectedEvent.confirmedMeetingsLimit}
            confirmedMeetings={selectedEvent.confirmedMeetings}
            disabledMeetingRequest={selectedEvent.disabledMeetingRequest}
            eventId={selectedEvent.eventId}
            tab={tab}
            subtab={subtab}
            path={`/summits/${selectedEvent.eventId}/`}
          />
        </TabPane>
        {selectedEvent.amRegistered && (
          <TabPane
            className="SummitDetails-tabs-tab"
            tab={
              <span className="flex align-middle space-x-2">
                <EventNoteOutlined />
                <span>Schedule</span>
              </span>
            }
            key="schedule"
          >
            <Schedule
              eventId={selectedEvent.eventId}
              companyEventId={selectedEvent.companyEventId}
              defaultTimezone={selectedEvent.timeZone}
              virtualSummit={selectedEvent.virtualSummit}
              optimizationDate={selectedEvent.optimizationDate}
              agendaUrl={selectedEvent?.agendaUrl}
            />
          </TabPane>
        )}
        <TabPane
          className="SummitDetails-tabs-tab"
          tab={
            <span className="flex align-middle space-x-2">
              <ContentPasteOutlined />
              <span>Agenda</span>
            </span>
          }
          key="agenda"
        >
          <Agenda eventId={selectedEvent.eventId} />
        </TabPane>
      </Tabs>
    </div>
  )
}

SummitDetails.propTypes = {
  events: PropTypes.arrayOf(PropTypes.any),
  selectedEvent: PropTypes.shape({
    eventId: PropTypes.number.isRequired,
    companyEventId: PropTypes.number.isRequired,
    eventName: PropTypes.string.isRequired,
    isOptimized: PropTypes.bool.isRequired,
    schedulerAccessDate: PropTypes.instanceOf(Date),
    optimizationDate: PropTypes.instanceOf(Date),
    creditsAvailable: PropTypes.number,
    contextPoints: PropTypes.number.isRequired,
    timeZone: PropTypes.string.isRequired,
    confirmedMeetingsLimit: PropTypes.number.isRequired,
    confirmedMeetings: PropTypes.number.isRequired,
    disabledMeetingRequest: PropTypes.bool.isRequired,
    virtualSummit: PropTypes.bool.isRequired,
    amRegistered: PropTypes.bool.isRequired,
    booths: PropTypes.array,
    agendaUrl: PropTypes.string,
  }).isRequired,
  role: PropTypes.string.isRequired,
  tab: PropTypes.string,
  subtab: PropTypes.string,
}

export default SummitDetails
