import map from 'lodash/map'
import {
  formatCurrency,
  formatDate,
  formatDecimal,
  formatPercent,
} from '~/utils/helpers'
import AvatarGroup from '../AvatarGroup'
import ContextSignalsContainer from '../ContextSignalsContainer'
import MatchmakingScore from '../MatchmakingScore'
import ShortenedText from '../ShortenedText'

export const ContextSignals = {
  Header: '',
  id: 'Context Signals',
  title: 'CONTEXT SIGNALS',
  accessor: (fund) => {
    return (
      <div className="pt-3 whitespace-nowrap">
        <ContextSignalsContainer className="mt-3" company={fund} max={2} />
      </div>
    )
  },
}
export const FundName = {
  Header: 'FUND',
  title: 'FUND',
  id: 'Fund Name',
  accessor: (fund) => {
    return <ShortenedText text={fund.name} maxLength={35} />
  },
}
export const Matchmaking = {
  id: 'Matchmaking Score',
  Header: '',
  title: 'MATCHMAKING SCORE',
  accessor: (fund) => {
    return (
      <MatchmakingScore
        score={fund.matchmakingScore}
        threshold={fund.threshold}
        size={30}
      />
    )
  },
}
export const Contacts = {
  id: 'Contacts',
  Header: 'CONTACTS',
  title: 'CONTACTS',
  accessor: (fund) => {
    return (
      <div className="whitespace-nowrap">
        <AvatarGroup
          avatars={map(fund.contacts, (contact) => {
            return {
              id: contact.contactId,
              firstName: contact.firstName,
              lastName: contact.lastName,
              initials: `${contact.firstName[0]}${contact.lastName[0]}`,
              description: `${contact.contactName} (${contact.jobTitle})`,
              img: contact.imageUrl,
            }
          })}
        />
      </div>
    )
  },
}
export const BroadStrategy = {
  id: 'Broad Strategy',
  Header: 'BROAD STRATEGY',
  title: 'BROAD STRATEGY',
  accessor: (fund) => {
    return (
      fund.broadStrategy && (
        <ShortenedText text={fund.broadStrategy} maxLength={35} />
      )
    )
  },
}
export const Substrategy = {
  id: 'Sub Strategy',
  Header: 'SUB STRATEGY',
  title: 'SUB STRATEGY',
  accessor: (fund) => {
    return (
      fund.subStrategy && (
        <ShortenedText text={fund.subStrategy} maxLength={35} />
      )
    )
  },
}
export const Aum = {
  id: 'AUM',
  Header: 'AUM',
  title: 'AUM',
  accessor: (fund) => formatCurrency(fund.fundAum),
}
export const InceptionDate = {
  id: 'Inception Date',
  Header: 'INCEPTION DATE',
  title: 'INCEPTION DATE',
  accessor: (fund) => formatDate(fund.fundInceptionDate),
}
export const Minimum = {
  id: 'Minimum',
  Header: 'MINIMUM',
  title: 'MINIMUM',
  accessor: (fund) => formatCurrency(fund.minimumInvestment),
}
export const AnnualReturns = {
  id: 'Annual Return',
  Header: 'ANN. RETURN',
  title: 'ANN. RETURN',
  accessor: (fund) =>
    fund.annualizedReturn && formatPercent(fund.annualizedReturn),
}
export const YearToDateReturn = {
  id: 'YTD Return',
  Header: 'YTD RETURN',
  title: 'YTD RETURN',
  accessor: (fund) => fund.ytdReturn && formatPercent(fund.ytdReturn / 100),
}
export const Sharpe = {
  id: 'Sharpe',
  Header: 'SHARPE',
  title: 'SHARPE',
  accessor: (fund) => fund.sharpe && formatDecimal(fund.sharpe),
}
