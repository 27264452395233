import { Button } from '@context365/button'
import { isIOS, isMobileOnly } from 'react-device-detect'
import MobileImage from '~/assets/image_for_mobile.png'
import MobileLogo from '~/assets/logo_for_mobile.png'

const MobileGate = ({ children, mobileFlag = false }) => {
  return !isMobileOnly || mobileFlag ? (
    children
  ) : (
    <div className="text-center m-8">
      <img src={MobileLogo} className="w-4/5" alt="Context365 by Apex" />
      <img src={MobileImage} className="w-4/6 my-8" alt="" />
      <div>
        <Button
          as="a"
          href={
            isIOS
              ? 'https://itunes.apple.com/app/context-summits/id1061631621'
              : 'https://play.google.com/store/apps/details?id=com.contextsummits.app'
          }
          target="_blank"
          variant="filled"
          className="w-full h-12"
        >
          Install App
        </Button>
      </div>
      <p className="my-8">
        You need to install the app to be able to use Context365 by Apex on a
        mobile device.
      </p>
    </div>
  )
}

export default MobileGate
