import * as React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@context365/alert'
import { Avatar, AvatarGroup } from '@context365/avatar'
import { Button, IconButton } from '@context365/button'
import { Search } from '@context365/forms'
import {
  Add,
  GroupsOutlined,
  Logout,
  MoreVert,
  NavigateBefore,
  NavigateNext,
  Search as SearchIcon,
} from '@context365/icons'
import { Menu, MenuButton } from '@context365/menu'
import { Tooltip } from '@context365/popovers'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom-v5-compat'
import Collapse from '~/components/Collapse'
import useMinScreen from '~/hooks/useMinScreen'
import useTracking from '~/hooks/useTracking'
import useVisibility from '~/hooks/useVisibility'
import {
  showCompanyProfileModal,
  showContactProfileModal,
} from '~/store/modals'
import { getInitials } from '~/utils'
import * as dateFormat from '../../utils/formatters/dates'
import ConversationParticipantsModal from './ConversationParticipantsModal'
import CreateMeetingModal from './CreateMeetingModal'
import InviteColleaguesModal from './InviteColleaguesModal'
import LeaveConversationConfirmation from './LeaveConversationConfirmation'
import { useMessageSearch } from './useMessageSearch'
import './Messages.css'

export default function ConversationHeader({
  conversationId,
  companies,
  contacts,
  meetingDateTime,
  refreshConversation,
}) {
  const { trackEvent } = useTracking({
    component: 'ConversationHeader',
    conversationId,
  })

  const isDesktop = useMinScreen('md')

  const dispatch = useDispatch()
  const addParticipantsModal = useVisibility()
  const leaveConversationModal = useVisibility()
  const chatParticipantsModal = useVisibility()
  const mobileSearch = useVisibility()
  const createMeeting = useVisibility()

  const {
    searchText,
    goToNextResult,
    goToPreviousResult,
    currentIndex,
    results,
  } = useMessageSearch()

  const uniqueContacts = uniqBy(contacts, 'contactId')

  const title = join(companies, ' & ', (company) => (
    <Button
      as="span"
      variant="none"
      className="text-primary-100 cursor-pointer"
      onClick={() => {
        trackEvent.click({
          element: 'company name',
          companyId: company.companyId,
        })
        dispatch(showCompanyProfileModal({ companyId: company.companyId }))
      }}
    >
      {company.companyName}
    </Button>
  ))

  return (
    <>
      <Layout
        title={title}
        avatars={
          <AvatarGroup size="big" isLayered>
            {uniqueContacts.map((contact) => (
              <Button
                key={contact.contactId}
                as={Avatar}
                className="cursor-pointer"
                variant="none"
                initials={getInitials(contact.contactName)}
                profileImageUrl={contact.imageUrl}
                onClick={() => {
                  trackEvent.click({
                    element: 'contact avatar',
                    contactId: contact.contactId,
                  })
                  dispatch(
                    showContactProfileModal({
                      contactId: contact.contactId,
                    })
                  )
                }}
              />
            ))}
          </AvatarGroup>
        }
        meeting={
          meetingDateTime ? (
            <Link to="/meetings">
              <Alert size="small">{`Meeting scheduled for ${dateFormat.utcToLocalDateAtTime(
                meetingDateTime
              )}`}</Alert>
            </Link>
          ) : (
            <Tooltip
              placement="bottom"
              text="If you and the other party have decided to meet, schedule a meeting to put a placeholder on both of your calendars."
            >
              <Button
                variant="outlined"
                status="secondary"
                onClick={() => {
                  createMeeting.show()
                }}
              >
                Schedule Meeting
              </Button>
            </Tooltip>
          )
        }
        menu={
          <Menu className="w-56" trigger={<MoreMenuButton />}>
            {!isDesktop && (
              <>
                <Menu.Item
                  onClick={() => {
                    trackEvent.click({ element: 'search chat' })
                    mobileSearch.show()
                  }}
                >
                  <SearchIcon className="mr-3" />
                  Search chat
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    trackEvent.click({ element: 'show participants' })
                    chatParticipantsModal.show()
                  }}
                >
                  <GroupsOutlined className="mr-3" />
                  View chat members
                </Menu.Item>
              </>
            )}
            <Menu.Item
              onClick={() => {
                trackEvent.click({ element: 'Add Participants menu item' })
                addParticipantsModal.show()
              }}
            >
              <Add className="mr-3" />
              Add more people
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                trackEvent.click({ element: 'Leave Conversation menu item' })
                leaveConversationModal.show()
              }}
            >
              <Logout className="mr-3" />
              Leave conversation
            </Menu.Item>
          </Menu>
        }
        mobileSearchVisible={mobileSearch.visible}
        mobileSearch={
          <div className="flex gap-1 p-1">
            <MessageSearchResults
              disabled={isEmpty(results)}
              currentIndex={currentIndex}
              total={results.length}
              onNext={goToNextResult}
              onPrevious={goToPreviousResult}
            />
            <div className="flex-1 overflow-hidden">
              <MessageSearch onClose={mobileSearch.hide} />
            </div>
          </div>
        }
        search={
          <div className="flex gap-2">
            <Collapse open={searchText != null} orientation="horizontal">
              <MessageSearchResults
                className="gap-2"
                disabled={isEmpty(results)}
                currentIndex={currentIndex}
                total={results.length}
                onNext={goToNextResult}
                onPrevious={goToPreviousResult}
              />
            </Collapse>
            <MessageSearch
              className="w-72"
              placeholder="Search this conversation"
            />
          </div>
        }
      />
      {conversationId != null && (
        <>
          <InviteColleaguesModal
            conversationId={conversationId}
            visible={addParticipantsModal.visible}
            onClose={addParticipantsModal.hide}
          />
          <LeaveConversationConfirmation
            conversationId={conversationId}
            visible={leaveConversationModal.visible}
            onClose={leaveConversationModal.hide}
          />
          <ConversationParticipantsModal
            contacts={uniqueContacts}
            visible={!isDesktop && chatParticipantsModal.visible}
            onClose={chatParticipantsModal.hide}
          />
          <CreateMeetingModal
            visible={createMeeting.visible}
            onSubmit={() => {
              trackEvent.click({ element: 'CreateMeeting' })
              refreshConversation()
              createMeeting.hide()
            }}
            onClose={() => createMeeting.hide()}
            conversationId={conversationId}
          />
        </>
      )}
    </>
  )
}

ConversationHeader.propTypes = {
  conversationId: PropTypes.number.isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      companyId: PropTypes.number.isRequired,
      companyName: PropTypes.string.isRequired,
    })
  ).isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.number.isRequired,
      contactName: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  meetingDateTime: PropTypes.string,
  refreshConversation: PropTypes.func.isRequired,
}

function Layout({
  title,
  avatars,
  meeting,
  menu,
  search,
  mobileSearchVisible = false,
  mobileSearch,
}) {
  const isDesktop = useMinScreen('md')
  const { campaignsModule } = useFlags()

  return (
    <div className="conversation-header bg-secondary-2 border border-secondary-5 flex flex-col">
      <div className="flex justify-between items-center px-3 py-4 md:px-6 md:pt-5 md:pb-4 md:items-start">
        <div className="flex flex-col gap-4 items-start">
          <div className="type-header-sm md:type-header-md">{title}</div>
          {isDesktop && <div>{avatars}</div>}
        </div>
        <div className="flex flex-col gap-5 items-end">
          <div className="flex flex-row gap-4">
            {campaignsModule && <div>{meeting}</div>}
            <div>{menu}</div>
          </div>
          {isDesktop && <div>{search}</div>}
        </div>
      </div>
      <div className="md:hidden">
        <Collapse open={mobileSearchVisible}>{mobileSearch}</Collapse>
      </div>
    </div>
  )
}

const MoreMenuButton = React.forwardRef((props, ref) => {
  const isDesktop = useMinScreen('md')

  return isDesktop ? (
    <MenuButton variant="filled" status="secondary" {...props} ref={ref}>
      More
    </MenuButton>
  ) : (
    <IconButton
      variant="link"
      status="secondary"
      icon={<MoreVert />}
      label="More Actions"
      {...props}
      ref={ref}
    />
  )
})

function MessageSearch({ className, placeholder, onClose }) {
  const { trackEvent } = useTracking({ component: 'ConversationHeaderSearch' })
  const [searchInput, setSearchInput] = React.useState('')

  const { searchText, search, goToNextResult, goToPreviousResult } =
    useMessageSearch()

  return (
    <Search
      className={className}
      clearable
      placeholder={placeholder}
      value={searchInput}
      onChange={setSearchInput}
      onSearch={(text) => {
        if (text && text === searchText) {
          goToNextResult()
        } else {
          trackEvent({
            eventName: 'search',
            searchText: text,
          })
          search(text)
        }

        if (!text) {
          onClose?.()
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && e.target.value === searchText) {
          if (e.shiftKey) {
            goToPreviousResult()
          } else {
            goToNextResult()
          }

          e.preventDefault()
        } else if (e.key === 'Escape') {
          search(null)
          setSearchInput('')
          onClose?.()
          e.target.blur()
        }
      }}
    />
  )
}

function MessageSearchResults({
  className,
  disabled,
  currentIndex,
  total,
  onNext,
  onPrevious,
}) {
  return (
    <div className={cx(className, 'flex items-center')}>
      <IconButton
        variant="link"
        icon={<NavigateBefore />}
        label="Previous Result"
        onClick={onPrevious}
        disabled={disabled}
      />
      {disabled ? (
        <span className="text-grey-400 whitespace-nowrap">No Results</span>
      ) : (
        <span className="whitespace-nowrap">
          {currentIndex + 1} of {total}
        </span>
      )}
      <IconButton
        variant="link"
        icon={<NavigateNext />}
        label="Next Result"
        onClick={onNext}
        disabled={disabled}
      />
    </div>
  )
}

function Loading() {
  return (
    <Layout
      title={
        <div className="h-5 w-56 md:h-7 md:w-96 bg-grey-300 rounded-full animate-pulse" />
      }
      avatars={
        <div className="animate-pulse">
          <AvatarGroup size="big" isLayered>
            {Array.from({ length: 4 }).map((_, i) => (
              <div key={i} className="bg-grey-300 avatar avatar--big" />
            ))}
          </AvatarGroup>
        </div>
      }
      menu={
        <Menu trigger={<MoreMenuButton disabled>More</MoreMenuButton>}>
          <Menu.Item />
        </Menu>
      }
      search={
        <Search
          className="w-72"
          disabled
          placeholder="Search this conversation"
        />
      }
      mobileSearch={<div />}
    />
  )
}

ConversationHeader.Loading = Loading

function join(elements, separator, makeElement) {
  return elements.reduce((els, el, i) => {
    els.push(
      <React.Fragment key={i}>
        {els.length > 0 && separator}
        {makeElement(el)}
      </React.Fragment>
    )
    return els
  }, [])
}
