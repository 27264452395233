import { forwardRef, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TabButton, TabList, TabPanel, Tabs } from '@context365/tabs'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, message } from 'antd'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import {
  getArchivedMessages,
  getConversations,
  getMeetingRequests,
} from '~/actions/messages'
import useAuth from '../../../hooks/useAuth'
import ComposeMessage from '../ComposeMessage'
import ConversationList from '../ConversationList'
import DataTransformations from '../DataTransformations'
import './ConversationsPanel.less'

const asyncForEach = async (array, callback) => Promise.all(array.map(callback))

const ConversationsPanel = ({
  onConversationSelected,
  onMeetingSelected,
  conversationID,
  refreshMeetings,
  redirectToConversations,
  refreshConversations,
  setRefreshConversations,
  isRedirectedToArchives,
  redirectedAfterAccept,
}) => {
  const [currentTabValue, setCurrentTabValue] = useState(
    isRedirectedToArchives === true ? '2' : '1'
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [conversations, setConversations] = useState(null)
  const [conversationsLoading, setConversationsLoading] = useState(false)
  const [conversationsPage, setConversationsPage] = useState(1)
  const [archives, setArchives] = useState([])
  const [archivesLoading, setArchivesLoading] = useState(false)
  const [archivesPage, setArchivesPage] = useState(1)
  const [anymoreArchives, setAnymoreArchives] = useState(false)
  const [shouldLoadArchives, setShouldLoadArchives] = useState(true)
  const [meetings, setMeetings] = useState([])
  const [meetingsLoading, setMeetingsLoading] = useState(false)
  const [meetingsPage, setMeetingsPage] = useState(1)
  const [anymoreMeetings, setAnymoreMeetings] = useState(false)
  const [shouldLoadMeetings, setShouldLoadMeetings] = useState(true)
  const [selectedConversationID, setSelectedConversationID] =
    useState(conversationID)
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const [consumptionLookup, setConsumptionLookup] = useState([])
  const [initialLookupInProgress, setInitialLookupInProgress] = useState(false)
  const [shouldLoadFirstItem, setShouldLoadFirstItem] = useState(false)
  const [scrolledToSelectedElement, setScrolledToSelectedElement] =
    useState(false)

  const { Search } = Input

  const { role, contact } = useAuth()
  const contactID = contact.contactId
  const twilioClient = useSelector((state) => state.messaging.twilioClient)
  const selectedItemRef = forwardRef()

  const { trackEvent } = useTracking({ component: 'ConversationsPanel' })

  //callback after twilio client receives another message
  //note: we subscribe to the client, not the channel,
  //therefore the channel has to be detected first
  const twilioMessageAdded = useCallback(
    (twilioMessage) => {
      if (
        moment()
          .subtract(60, 'second')
          .isAfter(moment.utc(twilioMessage.timestamp))
      )
        return

      if (isNil(conversations)) return
      const conversation = conversations.find(
        (x) => x.channelSID === twilioMessage.conversation.sid
      )

      if (isNil(conversation)) {
        if (
          !moment()
            .subtract(30, 'second')
            .isAfter(moment.utc(twilioMessage.timestamp))
        ) {
          setConversations(null)
          setMeetings([])
        }
        return
      }

      if (twilioMessage.attributes.MessageTypeID > 2) return

      const transformedMessage = DataTransformations.TransformTwilioMessage(
        twilioMessage,
        conversation.contacts
      )
      const newConversations = [...conversations]
      const updatedConversation = newConversations.find(
        (x) => x.conversationID === conversation.conversationID
      )
      updatedConversation.updatedMemberStatus = false
      updatedConversation.lastMessage = transformedMessage
      updatedConversation.totalMessages++
      if (updatedConversation.conversationID !== selectedConversationID)
        updatedConversation.unreadMessages++

      setConversations(newConversations)
    },
    [conversations, selectedConversationID]
  )

  const twilioMemberUpdated = useCallback(
    (updatedEvent) => {
      if (isNil(conversations)) return
      if (updatedEvent.updateReasons.includes('lastConsumedMessageIndex')) {
        const updatedContactId = parseInt(updatedEvent.member.identity, 10)

        const updatedConversation = conversations.find(
          (x) => x.channelSID === updatedEvent.member.channel.sid
        )
        if (isNil(updatedConversation)) return
        const contact = updatedConversation.contacts.find(
          (x) => x.contactId === updatedContactId
        )

        const lookupItem = consumptionLookup.find(
          (x) =>
            x.conversationID === updatedConversation.conversationID &&
            x.contactID === updatedContactId
        )

        if (isNil(lookupItem)) {
          setConsumptionLookup([
            ...consumptionLookup,
            {
              contactID: updatedContactId,
              conversationID: updatedConversation.conversationID,
              companyID: contact.companyId,
              lastConsumedIndex: updatedEvent.member.lastConsumedMessageIndex,
            },
          ])
        } else {
          lookupItem.lastConsumedIndex =
            updatedEvent.member.lastConsumedMessageIndex
          setConsumptionLookup([
            ...consumptionLookup.filter(
              (x) =>
                x.contactID === updatedContactId &&
                x.companyID === contact.companyId &&
                x.conversationID === updatedConversation.conversationID
            ),
            [{ ...lookupItem }],
          ])
        }
      }
    },
    [conversations, consumptionLookup]
  )

  const twilioMemberAdded = useCallback(() => {
    setConversations(null)
  }, [])

  // subscribe / unsubscribe from Twilio client on mount / unmount
  useEffect(() => {
    if (!isNil(twilioClient)) {
      twilioClient.on('messageAdded', twilioMessageAdded)
      twilioClient.on('memberUpdated', twilioMemberUpdated)
      twilioClient.on('memberJoined', twilioMemberAdded)
    }
    return () => {
      if (!isNil(twilioClient)) {
        twilioClient.off('messageAdded', twilioMessageAdded)
        twilioClient.off('memberUpdated', twilioMemberUpdated)
        twilioClient.off('memberJoined', twilioMemberAdded)
      }
    }
  }, [twilioMessageAdded, twilioMemberUpdated, twilioClient, twilioMemberAdded])

  // conversation has changed:
  // reset the number of unread messages to 0;
  // call onConversationSelected for parent component
  //to handle change
  const conversationSelectedHandler = useCallback(
    (conversationID) => {
      setSelectedConversationID(conversationID)
      if (currentTabValue === '1' && !isNil(conversations)) {
        const conversationsCopy = [...conversations]
        const conversationToRead = conversationsCopy.find(
          (x) => x.conversationID === conversationID
        )
        if (!isNil(conversationToRead)) {
          conversationToRead.unreadMessages = 0
          setConversations(conversationsCopy)
        }
      }

      onConversationSelected(conversationID, currentTabValue === '2')
    },
    [currentTabValue, conversations, onConversationSelected]
  )

  const meetingSelectedHandler = useCallback(
    (meetingID) => {
      setSelectedConversationID(meetingID)
      onMeetingSelected(meetingID)
    },
    [onMeetingSelected]
  )

  useEffect(() => {
    if (
      currentTabValue === '3' &&
      conversationID !== selectedConversationID &&
      conversationID !== null
    )
      tabSwitchHandler('1')
  }, [conversationID, currentTabValue, selectedConversationID])

  // tab has changed:
  // if the first tab is selected and there's no conversations, fetch them
  // this is the initial conversations load from API;
  // select first conversation after list loads
  useEffect(() => {
    if (currentTabValue === '1' && isNil(conversations)) {
      const fetchConversations = async () => {
        setConversationsLoading(true)
        try {
          const conversationsRaw = await getConversations()
          const conversationsProccessed =
            DataTransformations.ConvertConversationsForList(
              conversationsRaw.data.result.items.result
            )
          setConversations(conversationsProccessed)

          if (isRedirectedToArchives) {
            setShouldLoadFirstItem(true)
          } else {
            setShouldLoadFirstItem(isNil(conversationID))
          }

          let filteredConversations = conversationsProccessed.filter(
            (x) =>
              (!isNil(x.title) && x.title.match(new RegExp(searchTerm, 'i'))) ||
              x.contacts.some(
                (contact) =>
                  !isNil(contact.contactName) &&
                  contact.contactName.match(new RegExp(searchTerm, 'i'))
              )
          )

          filteredConversations = orderBy(
            filteredConversations,
            (conversation) => {
              const sortDate =
                conversation.sortDate ||
                conversation.lastMessage?.timeSent ||
                -1
              return sortDate === -1 ? sortDate : new Date(sortDate)
            },
            'desc'
          )

          if (isRedirectedToArchives) {
            setConversationsPage(1)
          } else {
            const index = isNil(conversationID)
              ? 0
              : filteredConversations.findIndex(
                  (x) => x.conversationID === conversationID
                )
            const page = Math.floor(index / 7) + 1
            setConversationsPage(page)
          }

          refreshConversations &&
            filteredConversations.length > 0 &&
            conversationSelectedHandler(
              filteredConversations[0].conversationID,
              false
            )

          if (conversationID) {
            if (
              !filteredConversations.some(
                (el) => el.conversationID == conversationID
              )
            ) {
              setConversationsPage(conversationsPage + 1)
            }
          }

          if (redirectedAfterAccept) {
            setScrolledToSelectedElement(false)
            setSelectedConversationID(conversationID)
          }

          //Now that processing is done, set the state
          setConversations(filteredConversations)
        } catch (error) {
          message.error(
            'There was an error while attempting to get conversation list'
          )
        } finally {
          setConversationsLoading(false)

          setRefreshConversations(false)
        }
      }
      fetchConversations()
    }
  }, [
    currentTabValue,
    conversations,
    conversationSelectedHandler,
    conversationID,
    setRefreshConversations,
    refreshConversations,
    searchTerm,
    isRedirectedToArchives,
    conversationsPage,
    redirectedAfterAccept,
  ])

  // if something has changed in the page or conversation list, update the members with their
  // last consumed index
  useEffect(() => {
    if (isNil(twilioClient)) return
    if (isNil(conversations)) return
    if (initialLookupInProgress) return
    setInitialLookupInProgress(true)

    const firstConversationIndex = 7 * (conversationsPage - 1)
    const lastConverastionIndex = 7 * conversationsPage
    const conversationsToSearch = conversations
      .slice(firstConversationIndex, lastConverastionIndex)
      .filter(
        (x) =>
          !isNil(x.lastMessage) &&
          x.lastMessage.authorContact.contactId === contactID
      )
      .filter(
        (x) =>
          !consumptionLookup.some((c) => c.conversationID === x.conversationID)
      )

    if (conversationsToSearch.length === 0) return
    let consumptionLookupCopy = [...consumptionLookup]

    const getIndexesForChannelsInView = async () => {
      await asyncForEach(conversationsToSearch, async (currConversation) => {
        const channel = await twilioClient.getConversationBySid(
          currConversation.channelSID
        )

        const members = await channel.getParticipants()

        members.forEach((member) => {
          const currentContactId = parseInt(member.identity, 10)
          const currentContact = currConversation.contacts.find(
            (x) => x.contactId === currentContactId
          )
          if (isNil(currentContact)) return
          const lookupObject = consumptionLookup.find(
            (x) =>
              x.converastionID === currConversation.conversationID &&
              x.contactID === currentContactId
          )
          if (isNil(lookupObject)) {
            consumptionLookupCopy = [
              ...consumptionLookupCopy,
              {
                contactID: currentContactId,
                companyID: currentContact.companyId,
                conversationID: currConversation.conversationID,
                lastConsumedIndex: member.lastConsumedMessageIndex,
              },
            ]
          } else {
            lookupObject.lastConsumedIndex = member.lastConsumedMessageIndex
          }
        })
      })
    }

    getIndexesForChannelsInView().then(() => {
      setConsumptionLookup(consumptionLookupCopy)
      //setInitialLookupInProgress(false);
    })
  }, [
    conversations,
    conversationsPage,
    twilioClient,
    contactID,
    consumptionLookup,
    initialLookupInProgress,
  ])

  // if the tab has changed to archives and there's a need to load archives
  // eg. page page changed, or search changed, or no load has been done before
  useEffect(() => {
    if (currentTabValue === '2' && shouldLoadArchives) {
      const fetchArchives = async () => {
        setArchivesLoading(true)
        try {
          const archivesRaw = await getArchivedMessages(
            searchTerm,
            archivesPage
          )
          const archivesProcessed =
            DataTransformations.ConvertConversationsForList(
              archivesRaw.data.result.items.result
            )

          const responseArray = [...archives, ...archivesProcessed]

          // Filtered in case conversations would duplicate
          const newArchivesArray = Array.from(
            new Set(responseArray.map((a) => a.conversationID))
          ).map((id) => responseArray.find((a) => a.conversationID === id))

          if (isRedirectedToArchives) {
            if (
              newArchivesArray.some((el) => el.conversationID == conversationID)
            ) {
              setShouldLoadArchives(false)
            } else {
              setArchivesPage(archivesPage + 1)
              setShouldLoadArchives(true)
            }
          } else {
            setShouldLoadArchives(false)
          }
          setShouldLoadArchives(false)

          const totalItems = archivesRaw.data.result.items.total
          setAnymoreArchives(totalItems > newArchivesArray.length)
          setArchives(newArchivesArray)
        } catch (error) {
          message.error(
            'There was a problem while attempting to get archived conversations'
          )
        } finally {
          setArchivesLoading(false)
        }
      }
      fetchArchives()
    }
  }, [
    currentTabValue,
    archivesPage,
    searchTerm,
    shouldLoadArchives,
    archives,
    conversationID,
    isRedirectedToArchives,
  ])

  useEffect(() => {
    if (currentTabValue === '3' && shouldLoadMeetings) {
      const fetchMeetings = async () => {
        setMeetingsLoading(true)
        try {
          const meetingsRaw = await getMeetingRequests(searchTerm, meetingsPage)
          const processedMeetings = DataTransformations.ConvertMeetingsForList(
            meetingsRaw.data.result.result
          )
          setShouldLoadMeetings(false)
          const newMeetingsArray = [...meetings, ...processedMeetings]
          const totalItems = meetingsRaw.data.result.total
          setAnymoreMeetings(totalItems > newMeetingsArray.length)
          setMeetings(newMeetingsArray)
        } catch (error) {
          message.error(
            'There was a prolem while attempting to get meeting requests'
          )
        } finally {
          setMeetingsLoading(false)
        }
      }
      fetchMeetings()
    }
  }, [
    currentTabValue,
    meetingsPage,
    searchTerm,
    shouldLoadMeetings,
    meetings,
    onMeetingSelected,
    conversationID,
  ])

  useEffect(() => {
    if (!isNil(refreshMeetings)) {
      setMeetings([])
      setShouldLoadMeetings(true)
    }
  }, [refreshMeetings])

  useEffect(() => {
    refreshConversations && setConversations(null)
  }, [refreshConversations])

  useEffect(() => {
    if (!isNil(redirectToConversations)) {
      setCurrentTabValue('1')
      setArchives([])
      setShouldLoadArchives(true)
      setMeetings([])
      setShouldLoadMeetings(true)
    }
  }, [redirectToConversations, onConversationSelected])

  useEffect(() => {
    if (selectedItemRef.current && !scrolledToSelectedElement) {
      selectedItemRef.current.scrollIntoView()
      setScrolledToSelectedElement(true)
    }
  }, [selectedItemRef, scrolledToSelectedElement])

  useEffect(() => {
    if (shouldLoadFirstItem === false) {
      return
    }
    if (
      currentTabValue === '1' &&
      !isNil(conversations) &&
      conversations.length > 0
    ) {
      //setSelectedConversationID(conversations[0].conversationID);
      if (conversations.length > 0) {
        const freshestConversation = orderBy(
          conversations,
          (conversation) => {
            if (isNil(conversation.lastMessage)) return -1
            else return conversation.lastMessage.timeSent
          },
          'desc'
        )[0]
        freshestConversation.unreadMessages = 0

        setSelectedConversationID(freshestConversation.conversationID)
        onConversationSelected(freshestConversation.conversationID)
        setShouldLoadFirstItem(false)
      }
    } else if (
      currentTabValue === '2' &&
      !isNil(archives) &&
      archives.length > 0
    ) {
      setSelectedConversationID(archives[0].conversationID)
      onConversationSelected(archives[0].conversationID, true)
      setShouldLoadFirstItem(false)
    } else if (
      currentTabValue === '3' &&
      !isNil(meetings) &&
      meetings.length > 0
    ) {
      meetingSelectedHandler(meetings[0].conversationID)
      onMeetingSelected(meetings[0].conversationID)
      setSelectedConversationID(meetings[0].conversationID)
    }
  }, [
    currentTabValue,
    conversations,
    archives,
    meetings,
    onConversationSelected,
    onMeetingSelected,
    shouldLoadFirstItem,
    meetingSelectedHandler,
    refreshMeetings,
  ])

  // tab has been changed
  const tabSwitchHandler = (tabValue) => {
    setCurrentTabValue(tabValue)
    setShouldLoadFirstItem(true)
  }

  const channelListSearchHandler = useCallback(
    (searchTerm) => {
      setSearchTerm(searchTerm)
      if (currentTabValue === '2') {
        setShouldLoadArchives(true)
        setArchives([])
      } else if (currentTabValue === '3') {
        setShouldLoadMeetings(true)
        setMeetings([])
      }
    },
    [currentTabValue]
  )

  const composeModalClosedHandler = () => {
    setComposeModalVisible(false)
  }

  // refresh has been requested, reset conversations
  // this is only for conversations, not archives or meetings
  const refreshConversationsListHandler = () => {
    setConversations(null)
  }

  const conversationLoadMoreHandler = useCallback(() => {
    setConversationsPage(conversationsPage + 1)
  }, [conversationsPage])

  const archivesLoadMoreHandler = useCallback(() => {
    setArchivesPage(archivesPage + 1)
    setShouldLoadArchives(true)
  }, [archivesPage])

  const meetingsLoadMoreHandler = useCallback(() => {
    setMeetingsPage(meetingsPage + 1)
    setShouldLoadMeetings(true)
  }, [meetingsPage])

  const changeTabs = (value) => {
    setCurrentTabValue(value)
    setShouldLoadFirstItem(true)
  }

  // conversations are always filtered by search term
  let filteredConversations =
    searchTerm === '' || isNil(conversations)
      ? conversations
      : conversations.filter(
          (x) =>
            (!isNil(x.title) && x.title.match(new RegExp(searchTerm, 'i'))) ||
            x.contacts.some(
              (contact) =>
                !isNil(contact.contactName) &&
                contact.contactName.match(new RegExp(searchTerm, 'i'))
            )
        )

  // and ordered by lastMessage UTC time
  filteredConversations = orderBy(
    filteredConversations,
    (conversation) => {
      if (isNil(conversation.lastMessage)) return -1
      else return conversation.lastMessage.timeSent
    },
    'desc'
  )

  const itemsPerPage = 7

  return (
    <div className="flex-1 overflow-y-auto">
      <Tabs selectedTab={currentTabValue} onSelect={changeTabs}>
        <TabList className="flex-shrink-0" variant="underlined">
          <TabButton
            id="1"
            label="Conversations"
            onClick={() => {
              trackEvent({
                eventName: 'click',
                element: 'ConversationTabs',
                label: 'Conversations',
              })
            }}
          />
          <TabButton
            id="3"
            label="Meeting Requests"
            onClick={() => {
              trackEvent({
                eventName: 'click',
                element: 'ConversationTabs',
                label: 'Meeting Requests',
              })
            }}
          />
          <TabButton
            id="2"
            label="Archive"
            onClick={() => {
              trackEvent({
                eventName: 'click',
                element: 'ConversationTabs',
                label: 'Archive',
              })
            }}
          />
        </TabList>
        <div className="m-4">
          <Search
            placeholder="Search"
            allowClear={true}
            size="default"
            onSearch={channelListSearchHandler}
          />
        </div>
        <div>
          <TabPanel tabId="1">
            <ConversationList
              areArchives={false}
              conversations={filteredConversations.slice(
                0,
                conversationsPage * itemsPerPage
              )}
              onConversationSelected={conversationSelectedHandler}
              selectedConversationID={selectedConversationID}
              loading={conversationsLoading}
              showLoadMore={
                filteredConversations.length > conversationsPage * itemsPerPage
              }
              onLoadMore={conversationLoadMoreHandler}
              consumptionLookup={consumptionLookup}
              selectedItemRef={selectedItemRef}
            />
          </TabPanel>
          <TabPanel tabId="3">
            <ConversationList
              areArchives={false}
              conversations={meetings}
              onConversationSelected={meetingSelectedHandler}
              selectedConversationID={selectedConversationID}
              loading={meetingsLoading}
              showLoadMore={anymoreMeetings}
              onLoadMore={meetingsLoadMoreHandler}
            />
          </TabPanel>
          <TabPanel tabId="2">
            <ConversationList
              areArchives={true}
              conversations={archives}
              onConversationSelected={conversationSelectedHandler}
              selectedConversationID={selectedConversationID}
              loading={archivesLoading}
              showLoadMore={anymoreArchives}
              onLoadMore={archivesLoadMoreHandler}
              selectedItemRef={selectedItemRef}
            />
          </TabPanel>
        </div>
      </Tabs>

      {!isNil(role) &&
        role.toLowerCase() !== 'manager' &&
        role.toLowerCase() !== 'service provider' && (
          <>
            <div className="conversations-panel-compose mt-2">
              <Button
                className="conversations-panel-compose-button"
                type="primary"
                onClick={() => {
                  trackEvent({ eventName: 'click', element: 'ComposeMessage' })
                  setComposeModalVisible(true)
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="conversations-panel-compose-icon"
                />
                Compose Message
              </Button>
            </div>

            <ComposeMessage
              visible={composeModalVisible}
              onClose={composeModalClosedHandler}
              refreshList={refreshConversationsListHandler}
            />
          </>
        )}
    </div>
  )
}

ConversationsPanel.propTypes = {
  onConversationSelected: PropTypes.func.isRequired,
  onMeetingSelected: PropTypes.func.isRequired,
  conversationID: PropTypes.number,
  refreshMeetings: PropTypes.bool,
  redirectToConversations: PropTypes.bool,
  refreshConversations: PropTypes.bool,
  setRefreshConversations: PropTypes.func,
  isRedirectedToArchives: PropTypes.bool,
  redirectedAfterAccept: PropTypes.bool,
}

export default ConversationsPanel
