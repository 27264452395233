export const TIER_CLAIM = 'tier'

export const Free = 'Free'
export const Starter = 'Starter'
export const Pro = 'Pro'

const Tiers = {
  Free,
  Starter,
  Pro,
}

export default Tiers
