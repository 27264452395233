import * as React from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import take from 'lodash/take'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { fetchCompanyIfNeeded, logProfileView } from '~/actions/company'
import * as api from '~/api'
import { ILLIQUID_FUND_TYPE, LIQUID_FUND_TYPE } from '~/constants/funds'
import { SP500_FUND_INDEX_ID } from '~/constants/indexFunds'
import { useDiscoverLists } from '~/hooks/discoverLists'
import useVisibility from '~/hooks/useVisibility'
import { getCompanyId } from '~/selectors/auth'
import ComposeMessageModal from '../ComposeMessageModal'
import ContactList from '../ContactList'
import { DiscoverResultCard } from '../Discover'
import { DiscoverCardStats, statFormat } from '../DiscoverCardStats'
import DiscoverFundMeetingRequest from '../DiscoverFundMeetingRequest'
import DiscoverFundChart from './DiscoverFundChart'
import DiscoverFundResultFooter from './DiscoverFundResultFooter'
import DiscoverFundResultHeader from './DiscoverFundResultHeader'
import DiscoverIlliquidCharts from './DiscoverIlliquidCharts'
import './DiscoverFundResult.less'

const arePointsNear = () => false

const getDetailItems = (fund) => {
  const allowMonthlyMetrics =
    !fund.utilizesNewIlliquidProfile || fund.fundTypeId === LIQUID_FUND_TYPE

  const items = [
    {
      title: 'Fund AUM',
      data: fund.fundAum,
      format: statFormat.currency,
      isVisible: !isNil(fund.fundAum),
    },
    {
      title: 'Inception Date',
      data: fund.fundInceptionDate,
      format: statFormat.monthYear,
      isVisible: !isNil(fund.fundInceptionDate),
    },
    {
      title: 'Broad Strategy',
      data: fund.broadStrategy,
      isVisible: !isNil(fund.broadStrategy),
    },
    {
      title: 'Sub Strategy',
      data: fund.subStrategy,
      isVisible: !isNil(fund.subStrategy),
    },
    {
      title: 'Net Dollar Exposure',
      data: fund.netDollarExposure,
      isVisible: allowMonthlyMetrics && !isNil(fund.netDollarExposure),
    },
    {
      title: 'Ann. Return',
      data: fund.annualizedReturn,
      format: statFormat.percent,
      isVisible:
        allowMonthlyMetrics && !fund.narrative && !isNil(fund.annualizedReturn),
    },
    {
      title: 'YTD Return',
      data: fund.ytdReturn / 100,
      format: statFormat.percent,
      isVisible:
        allowMonthlyMetrics && !fund.narrative && !isNil(fund.ytdReturn),
    },
    {
      title: 'Sharpe',
      data: fund.sharpe,
      format: statFormat.decimal,
      isVisible: allowMonthlyMetrics && !fund.narrative && !isNil(fund.sharpe),
    },

    {
      title: 'IRR Net',
      data: fund.expectedTargetedInternalRateOfReturn / 100.0,
      format: statFormat.percent,
      isVisible:
        fund.utilizesNewIlliquidProfile &&
        !isNil(fund.expectedTargetedInternalRateOfReturn),
    },
    {
      title: 'TVPI Net',
      data: fund.netTotalValuePaidInCapital,
      format: statFormat.multiple,
      isVisible:
        fund.utilizesNewIlliquidProfile &&
        !isNil(fund.netTotalValuePaidInCapital),
    },
    {
      title: 'Leverage',
      data: fund.leverageUtilization / 100.0,
      format: statFormat.percent,
      isVisible:
        fund.utilizesNewIlliquidProfile && !isNil(fund.leverageUtilization),
    },
    {
      title: 'GP Commit',
      data: fund.generalPartnerCommitedRatio / 100.0,
      format: statFormat.percent,
      isVisible:
        fund.utilizesNewIlliquidProfile &&
        !isNil(fund.generalPartnerCommitedRatio),
    },
    {
      title: 'IRR Gross',
      data: fund.grossInternalRateOfReturn / 100.0,
      format: statFormat.percent,
      isVisible:
        fund.utilizesNewIlliquidProfile &&
        !isNil(fund.grossInternalRateOfReturn),
    },
    {
      title: 'TVPI Gross',
      data: fund.grossTotalValuePaidInCapital,
      format: statFormat.multiple,
      isVisible:
        fund.utilizesNewIlliquidProfile &&
        !isNil(fund.grossTotalValuePaidInCapital),
    },
    {
      title: 'Minimum',
      data: fund.minimumInvestment,
      format: statFormat.currency,
      isVisible: !isNil(fund.minimumInvestment),
    },
  ]

  return take(filter(items, 'isVisible'), 8)
}

const DiscoverFundResult = ({
  fund,
  fromMandate = false,
  fromPreview = false,
  onToggleHidden,
  isSelectedForCompare,
  onChangeSelectedFundId,
  onAddFundToList,
  onToggleCompare,
  onChangeSelectedFund,
}) => {
  const { Track } = useTracking({ component: 'DiscoverFundResult' })
  const requestMeetingModal = useVisibility()
  const composeModal = useVisibility()
  const { role } = useSelector((state) => state.auth)
  const companyId = useSelector(getCompanyId)
  const isCFNAllocator = useSelector(
    (state) => state.auth.contact.isCFNAllocator
  )
  const { discoverLists } = useDiscoverLists()
  const dispatch = useDispatch()
  const { campaignsModule } = useFlags()

  const sendMeetingRequest = React.useCallback(() => {
    if (role === 'Service Provider') {
      api.meeting
        .checkCanSendMeetingRequest({
          toCompanyId: fund.company.companyId,
          fundId: fund.fundId,
        })
        .then((validationResponse) => {
          if (validationResponse.isValid) {
            requestMeetingModal.show()
          } else {
            message.error(validationResponse.reason)
          }
        })
    } else {
      requestMeetingModal.show()
    }
  }, [role, requestMeetingModal, fund])

  const showDetailsModal = React.useCallback(
    (showDataroom) => {
      if (fromMandate) {
        onChangeSelectedFund(fund, showDataroom ? 'attachments' : null)
      } else if (fromPreview) {
        window.open(
          `/discover/funds?search=${fund.name}&selectedId=${fund.fundId}`
        )
      } else {
        onChangeSelectedFundId(fund.fundId, showDataroom ? 'attachments' : null)
        const profile = {
          companyId: fund.companyId,
          fundId: fund.fundId,
        }
        logProfileView(profile)
      }
    },
    [
      fund,
      onChangeSelectedFundId,
      fromMandate,
      fromPreview,
      onChangeSelectedFund,
    ]
  )

  const closeDetailsModal = React.useCallback(() => {
    dispatch(fetchCompanyIfNeeded(companyId))
    fromMandate ? onChangeSelectedFund(null) : onChangeSelectedFundId(null)
  }, [
    companyId,
    dispatch,
    onChangeSelectedFundId,
    fromMandate,
    onChangeSelectedFund,
  ])

  if (!fund) {
    return null
  }

  return (
    <div id="fixed" className="discover-fund">
      <Track>
        <DiscoverResultCard
          className={cx(
            'discover-fund-result',
            fromMandate &&
              fund.isHiddenForMandate &&
              'discover-fund-result--hidden'
          )}
          href={campaignsModule ? `/fund/${fund.fundId}` : undefined}
          onClick={() => {
            !campaignsModule && showDetailsModal()
          }}
          header={
            <DiscoverFundResultHeader
              discoverLists={discoverLists}
              fund={fund}
              isCFNAllocator={isCFNAllocator}
            />
          }
          body={
            <div className="discover-fund-result-info">
              <div>
                <DiscoverCardStats items={getDetailItems(fund)} />
                <div className="pt-5 text-xs">
                  <ContactList contacts={fund.contacts} />
                </div>
              </div>
              {fund.utilizesNewIlliquidProfile &&
              fund.fundTypeId === ILLIQUID_FUND_TYPE ? (
                <DiscoverIlliquidCharts
                  fundId={fund.fundId}
                  indexFund={SP500_FUND_INDEX_ID}
                />
              ) : (
                !fund.narrative && (
                  <DiscoverFundChart
                    fundId={
                      process.env.REACT_APP_CHARTS_FUND_ID
                        ? parseInt(process.env.REACT_APP_CHARTS_FUND_ID, 10)
                        : fund.fundId
                    }
                    indexFund={SP500_FUND_INDEX_ID}
                  />
                )
              )}
            </div>
          }
          footer={
            <DiscoverFundResultFooter
              fromMandate={fromMandate}
              fromPreview={fromPreview}
              fund={fund}
              role={role}
              isSelectedForCompare={isSelectedForCompare}
              onSendMeetingRequest={sendMeetingRequest}
              onSendMessage={composeModal.show}
              onShowAddToListModal={() => onAddFundToList([fund])}
              onShowDetailsModal={() =>
                !campaignsModule && showDetailsModal(true)
              }
              onToggleHideFund={onToggleHidden}
              onToggleCompare={() => onToggleCompare(fund)}
            />
          }
        />
      </Track>

      <ComposeMessageModal
        visible={composeModal.visible}
        onClose={composeModal.hide}
        companyID={fund.company.companyId}
        fromFundId={fund.fundId}
      />
      {requestMeetingModal.visible && (
        <DiscoverFundMeetingRequest
          visible={requestMeetingModal.visible}
          onClose={requestMeetingModal.hide}
          fund={fund}
          arePointsNear={arePointsNear}
          onRefresh={() => {
            closeDetailsModal()
            dispatch(fetchCompanyIfNeeded(companyId))
          }}
        />
      )}
    </div>
  )
}

DiscoverFundResult.propTypes = {
  fund: PropTypes.object.isRequired,
  fromMandate: PropTypes.bool,
  fromPreview: PropTypes.bool,
  onToggleHidden: PropTypes.func,
  isSelectedForCompare: PropTypes.bool,
  onChangeSelectedFundId: PropTypes.func,
  onAddFundToList: PropTypes.func.isRequired,
  onToggleCompare: PropTypes.func.isRequired,
  onChangeSelectedFund: PropTypes.func,
}

export default DiscoverFundResult
