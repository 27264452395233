import * as React from 'react'
import PropTypes from 'prop-types'
import useAuth from '~/hooks/useAuth'
import useFrameState from '~/hooks/useFrameState'
import CfnNoOnboardingModal from '../CFNNoOnboardingModal/CfnNoOnboardingModal'
import PremiumTierModal from '../PremiumTierModal/PremiumTierModal'

export default function BlockView({ image, onlyPro = true }) {
  const ref = React.useRef()
  const parentPadding = useParentPadding(ref)

  const { role, contact } = useAuth()
  const isCFNAllocator = contact?.isCFNAllocator

  return (
    <div
      ref={ref}
      id="cc-block-view"
      className="flex-1 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${image})`,
        ...getInverseMargins(parentPadding),
      }}
    >
      {role === 'CFN' && isCFNAllocator === false ? (
        <CfnNoOnboardingModal onlyPro={onlyPro} visible />
      ) : (
        <PremiumTierModal onlyPro={onlyPro} visible />
      )}
    </div>
  )
}

BlockView.propTypes = {
  image: PropTypes.string,
  onlyPro: PropTypes.bool,
}

function getInverseMargins(parentPadding) {
  return Object.entries(parentPadding).reduce((margins, [side, padding]) => {
    if (!padding) {
      return margins
    }

    const marginKey = `margin${side.charAt(0).toUpperCase()}${side.slice(1)}`
    margins[marginKey] = `-${padding}`
    return margins
  }, {})
}

function useParentPadding(ref) {
  const [parentPadding, setParentPadding] = useFrameState({})

  React.useLayoutEffect(() => {
    const parent = ref.current?.parentNode
    if (!parent || typeof window.ResizeObserver === 'undefined') {
      return undefined
    }

    function updateParentPadding() {
      const parentStyle = window.getComputedStyle(parent)
      setParentPadding({
        top: parentStyle.getPropertyValue('padding-top'),
        right: parentStyle.getPropertyValue('padding-right'),
        bottom: parentStyle.getPropertyValue('padding-bottom'),
        left: parentStyle.getPropertyValue('padding-left'),
      })
    }

    const observer = new ResizeObserver(updateParentPadding)
    observer.observe(parent)
    updateParentPadding()

    return () => {
      observer.disconnect()
    }
  }, [ref, setParentPadding])

  return parentPadding
}
