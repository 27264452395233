import PropTypes from 'prop-types'
import { Button } from 'antd'
import SimpleBar from 'simplebar-react'
import Loading from '../../Loading'
import ConversationItem from '../ConversationItem'
import EmptyChannelList from '../EmptyChannelList'
import { ConversationShape } from '../MessagePropTypes'
import './ConversationList.less'

const ConversationList = ({
  conversations,
  selectedConversationID,
  onConversationSelected = () => {},
  areArchives = false,
  loading,
  showLoadMore = false,
  onLoadMore,
  selectedItemRef,
}) => (
  <>
    {loading && conversations.length === 0 && <Loading />}

    {conversations.length > 0 && (
      <SimpleBar
        className="overflow-y-auto"
        style={{
          maxHeight: `calc(100vh - ${showLoadMore ? '320px' : '280px '})`,
        }}
      >
        {conversations.map((x) => (
          <div
            key={x.conversationID}
            ref={
              x.conversationID === selectedConversationID
                ? selectedItemRef
                : null
            }
          >
            <ConversationItem
              conversation={x}
              isSelected={x.conversationID === selectedConversationID}
              key={x.conversationID}
              onClick={() =>
                onConversationSelected(x.conversationID, areArchives)
              }
            />
          </div>
        ))}
      </SimpleBar>
    )}

    {/* there are more conversations to be taken,
      'Load More' button is visible */}

    {conversations.length > 0 && showLoadMore && (
      <div className="messaging-contacts-load-more">
        <Button onClick={onLoadMore} loading={loading} type="link">
          Load more...
        </Button>
      </div>
    )}

    {/* nothing is loading and there are no conversations,
      display the empty channel view */}
    {!loading && conversations.length === 0 && (
      <EmptyChannelList areArchives={areArchives} />
    )}
  </>
)

ConversationList.propTypes = {
  conversations: PropTypes.arrayOf(ConversationShape).isRequired,
  selectedConversationID: PropTypes.number,
  onConversationSelected: PropTypes.func,
  areArchives: PropTypes.bool,
  loading: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  selectedItemRef: PropTypes.object,
}

export default ConversationList
