import * as React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import * as api from '~/api'
import logo from '~/assets/new-logo-white.png'
import FundMiniProfile from '~/components/FundMiniProfile'
import Loading from '~/components/Loading'
import { PagedPdfViewer } from '~/components/PDFViewer'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import useSearchParams from '~/hooks/useSearchParams'

export default function Attachment() {
  const { name, entityId, type } = useParams()
  const [{ file: fileUrl }] = useSearchParams()
  const { role } = useAuth()

  const { data: signedUrl, status } = useQuery(
    ['attachment', fileUrl],
    () => api.attachments.downloadDocument(fileUrl),
    { refetchOnWindowFocus: false }
  )

  const { data: fundProfile } = useQuery(
    ['fundProfile', { role, type, entityId }],
    () => {
      if (role !== ROLE.ALLOCATOR || type === 'mandate') {
        return undefined
      }

      return api.funds.getFundProfile(entityId).then((fund) => fund)
    }
  )

  React.useEffect(() => {
    if (role !== ROLE.CFN && type !== 'mandate') {
      api.attachments.logAttachmentView.fund(entityId)
    } else if (type === 'deal') {
      api.attachments.logAttachmentView.deal(entityId)
    } else if (type === 'mandate') {
      api.attachments.logAttachmentView.mandate(entityId)
    }
  }, [entityId, role, type])

  const logPageView = React.useCallback(
    (page, pageCount) => {
      if (role === ROLE.CFN) {
        return
      }

      const logProps = { fileUrl, page, pageCount }
      if (type === 'mandate') {
        api.attachments.logMandatePageView(entityId, logProps)
      } else {
        api.attachments.logFundPageView(entityId, logProps)
      }
    },
    [role, entityId, fileUrl, type]
  )

  const downloadDocument = React.useCallback(() => {
    api.attachments.downloadDocument(fileUrl).then((url) => {
      window.open(url, '_blank')
    })
  }, [fileUrl])

  return (
    <div className="bg-grey-800 w-full h-screen flex flex-col overflow-hidden">
      <header className="h-12 bg-grey-900 flex justify-center items-center flex-shrink-0">
        <img src={logo} alt="Context365 by Apex" />
      </header>
      <div className="flex-1 overflow-hidden">
        {choose(status, {
          loading: (
            <div className="w-full h-full flex items-center justify-center">
              <Loading />
            </div>
          ),
          error: (
            <div className="w-full h-full flex flex-col items-center justify-center text-white">
              <span className="type-body-semibold-lg">
                An error occurred while downloading the attachment.
              </span>
              <span className="type-body-regular-md">
                Please refresh the page or try again later.
              </span>
            </div>
          ),
          success: (
            <PagedPdfViewer
              name={name}
              fileUrl={signedUrl}
              onPageChange={logPageView}
              onDownload={downloadDocument}
            />
          ),
        })}
      </div>
      {fundProfile && (
        <div className="fixed text-center" style={{ left: '2%', bottom: '5%' }}>
          <FundMiniProfile
            fund={fundProfile}
            hideActions={type?.toLowerCase() === 'event'}
          />
        </div>
      )}
    </div>
  )
}

function choose(status, states) {
  return states[status] ?? null
}
