import * as React from 'react'
import PropTypes from 'prop-types'
import { Typography, message as response } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import join from 'lodash/join'
import take from 'lodash/take'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import MandateTypes from '~/constants/mandateTypes'
import { useDiscoverLists } from '~/hooks/discoverLists'
import { ApplyToMandateModal, SuccessfulApplicationModal } from '../Application'
import ComposeMessageModal from '../ComposeMessageModal'
import ContactList from '../ContactList'
import { DiscoverResultCard } from '../Discover'
import { DiscoverCardStats, statFormat } from '../DiscoverCardStats'
import MandateProfileModal from '../MandateProfile/MandateProfileModal'
import DiscoverMandateResultFooter from './DiscoverMandateResultFooter'
import DiscoverMandateResultHeader from './DiscoverMandateResultHeader'

const { Text } = Typography

const getDetailItems = (mandate) => {
  const items = [
    {
      title: 'Mandate Type',
      data: mandate.mandateType,
      isVisible: !isNil(mandate.mandateType),
    },
    {
      title: 'Target Completion Date',
      data: mandate.targetCompletionDate,
      format: statFormat.date,
      isVisible: !isNil(mandate.targetCompletionDate),
    },
    {
      title: 'Mandate Size',
      data: isNil(mandate.maxSize)
        ? `${statFormat.currency(mandate.minSize)} (min)`
        : `${statFormat.currency(mandate.minSize ?? 0)} - ${statFormat.currency(
            mandate.maxSize
          )}`,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        (mandate.minSize > 0 || !isNil(mandate.maxSize)),
    },
    {
      title: 'Broad Strategy',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(mandate.strategyInterest, ', ')}
        </Text>
      ),
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        mandate.strategyInterest?.length > 0,
    },
    {
      title: 'Fund AUM Range',
      data: isNil(mandate.maxFundAum)
        ? `${statFormat.currency(mandate.minFundAum)} (min)`
        : `${statFormat.currency(
            mandate.minFundAum ?? 0
          )} - ${statFormat.currency(mandate.maxFundAum)}`,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        (mandate.minFundAum > 0 || !isNil(mandate.maxFundAum)),
    },
    {
      title: 'Manager Track Record Range',
      data: isNil(mandate.managerMaxTrackRecord)
        ? `${mandate.managerMinTrackRecord} years (min)`
        : `${mandate.managerMinTrackRecord ?? 0} - ${
            mandate.managerMaxTrackRecord
          } years`,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        (mandate.managerMinTrackRecord > 0 ||
          !isNil(mandate.managerMaxTrackRecord)),
    },
    {
      title: 'Target Return',
      data: mandate.targetReturn / 100.0,
      format: statFormat.percent,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        !isNil(mandate.targetReturn),
    },
    {
      title: 'Benchmark',
      data: mandate.benchmark,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        !isNil(mandate.benchmark),
    },
    {
      title: 'Attributes',
      data: (
        <Text ellipsis={{ tooltip: true }}>
          {join(mandate.attributes, ', ')}
        </Text>
      ),
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        mandate.attributes?.length > 0,
    },
    {
      title: 'Current Assets',
      data: mandate.currentAssets,
      format: statFormat.currency,
      isVisible: !isNil(mandate.currentAssets),
    },
    {
      title: 'Current Assets',
      data: mandate.currentAssetsRange,
      isVisible:
        mandate.mandateTypeId === MandateTypes.AssetManagement &&
        !isNil(mandate.currentAssetsRange),
    },
  ]

  return take(filter(items, 'isVisible'), 8)
}

const DiscoverMandatesResult = ({
  mandate,
  selectedFund,
  selectedMandateId,
  onChangeSelectedMandateId,
  onAddMandateToList,
  fromPreview = false,
}) => {
  const { Track, trackEvent } = useTracking({ page: 'MandateCard' })

  const [isApplyToMandateModalVisible, setIsApplyToMandateModalVisible] =
    React.useState(false)
  const [isAskAQuestionModalvisible, setIsAskAQuestionModalvisible] =
    React.useState(false)
  const [showDataroom, setShowDataroom] = React.useState(false)
  const [appliedWithCampaign, setAppliedWithCampaign] = React.useState(null)
  const [applyingFundId, setApplyingFundId] = React.useState(selectedFund)

  const { discoverLists } = useDiscoverLists()
  const { campaignsModule } = useFlags()

  const isProfileModalVisible = isNil(mandate)
    ? false
    : mandate.mandateId === selectedMandateId

  const canAskQuestion =
    !mandate.isAnonymous && !mandate.myMandate && !isEmpty(mandate.contacts)

  const showProfileModal = React.useCallback(
    (jumpToDataroom = false, mandate) => {
      if (fromPreview) {
        window.open(
          `/discover/mandates?search=${mandate.title}&selectedId=${mandate.mandateId}`
        )
      } else {
        setShowDataroom(jumpToDataroom)
        onChangeSelectedMandateId(mandate.mandateId)
        const profile = {
          companyId: mandate.companyId,
          mandateId: mandate.mandateId,
        }
        api.mandate.logProfileView(profile)
      }
    },
    [onChangeSelectedMandateId, fromPreview]
  )

  const hideProfileModal = React.useCallback(() => {
    setShowDataroom(false)
    onChangeSelectedMandateId(null)
  }, [onChangeSelectedMandateId])

  const showSentApplicationModal = React.useCallback(
    (applyingCampaignId, applyingCampaignName) => {
      hideProfileModal()
      if (campaignsModule) {
        const campaign = applyingCampaignId
          ? {
              id: applyingCampaignId,
              name: applyingCampaignName,
            }
          : null
        setAppliedWithCampaign(campaign)
      } else {
        response.success(
          "Success! Your interest in the mandate has been submitted. If the mandate poster believes you're a fit, they'll reach out to you directly with next steps.",
          5 //seconds
        )
      }
    },
    [setAppliedWithCampaign, hideProfileModal, campaignsModule]
  )

  const showApplyToMandateModal = React.useCallback(
    (fundId) => {
      trackEvent({
        eventName: 'click',
        element: 'Mandate open apply modal from profile',
      })
      setIsApplyToMandateModalVisible(true)
      if (fundId) {
        setApplyingFundId(fundId)
      }
    },
    [trackEvent]
  )

  const hideApplyToMandateModal = React.useCallback(() => {
    setIsApplyToMandateModalVisible(false)
    setApplyingFundId(selectedFund)
  }, [selectedFund])

  const showAskAQuestionModal = React.useCallback(() => {
    setIsAskAQuestionModalvisible(true)
  }, [])

  const hideAskAQuestionModal = React.useCallback(() => {
    setIsAskAQuestionModalvisible(false)
  }, [])

  if (!mandate) {
    return null
  }

  return (
    <div id="fixed" className="discover-fund">
      <Track>
        <DiscoverResultCard
          className="discover-fund-result"
          href={campaignsModule ? `/mandate/${mandate.mandateId}` : undefined}
          onClick={() => {
            trackEvent({
              eventName: 'click',
              element: 'Mandate Open Profile',
            })
            !campaignsModule && showProfileModal(false, mandate)
          }}
          header={
            <DiscoverMandateResultHeader
              mandate={mandate}
              discoverLists={discoverLists}
            />
          }
          body={
            <>
              <DiscoverCardStats items={getDetailItems(mandate)} />
              {!mandate.isAnonymous && (
                <div className="pt-5 text-xs">
                  <ContactList contacts={mandate.contacts} />
                </div>
              )}
            </>
          }
          footer={
            <DiscoverMandateResultFooter
              mandate={mandate}
              canAskQuestion={canAskQuestion}
              canApplyToMandate={mandate.canApply}
              onShowProfileModal={() => {
                !campaignsModule && showProfileModal(true, mandate)
              }}
              onShowAskAQuestionModal={showAskAQuestionModal}
              onShowApplyToMandateModal={showApplyToMandateModal}
              onShowAddToListModal={() => onAddMandateToList([mandate])}
              fromPreview={fromPreview}
            />
          }
        />
      </Track>
      <MandateProfileModal
        mandateId={mandate.mandateId}
        visible={isProfileModalVisible}
        openDataroom={showDataroom}
        onCancel={hideProfileModal}
        hideActions={mandate.myMandate}
        canAskQuestion={canAskQuestion}
        canApplyToMandate={mandate.canApply}
        onApplyToMandate={showApplyToMandateModal}
        onAskQuestion={showAskAQuestionModal}
        fundId={selectedFund}
      />
      <ApplyToMandateModal
        visible={isApplyToMandateModalVisible}
        onClose={hideApplyToMandateModal}
        mandateId={mandate.mandateId}
        fundId={applyingFundId}
        mandate={mandate}
        onApply={showSentApplicationModal}
      />
      <ComposeMessageModal
        visible={isAskAQuestionModalvisible}
        onClose={hideAskAQuestionModal}
        companyID={mandate.companyId}
        selectAll
        showContactsAttendingEvent={false}
        fromMandateId={mandate.mandateId}
      />
      <SuccessfulApplicationModal
        visible={!!appliedWithCampaign}
        applyingCampaignId={appliedWithCampaign?.id}
        applyingCampaignName={appliedWithCampaign?.name}
        onClose={() => setAppliedWithCampaign(null)}
      />
    </div>
  )
}

DiscoverMandatesResult.propTypes = {
  mandate: PropTypes.object.isRequired,
  selectedFund: PropTypes.number,
  selectedMandateId: PropTypes.number,
  onChangeSelectedMandateId: PropTypes.func,
  onAddMandateToList: PropTypes.func.isRequired,
  fromPreview: PropTypes.bool,
}

export default DiscoverMandatesResult
