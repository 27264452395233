import PropTypes from 'prop-types'
import { Wait } from '@context365/loaders'
import { Spin } from 'antd'

const Loading = ({
  className,
  spinning = true,
  children,
  style,
  spinnerProps,
}) => (
  <Spin
    className={className}
    style={style}
    spinning={spinning}
    indicator={
      <Wait
        {...spinnerProps}
        style={{
          height: 64,
          width: 64,
          ...spinnerProps?.style,
        }}
      />
    }
  >
    {children}
  </Spin>
)

Loading.propTypes = {
  style: PropTypes.object,
  spinning: PropTypes.bool,
  children: PropTypes.node,
  spinnerProps: PropTypes.object,
}

export default Loading
