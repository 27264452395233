import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@context365/alert'
import { Modal, message } from 'antd'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConversation,
  getMessages,
  leaveConversation,
  searchConversation,
  sendAttachmentToConversation,
  sendMessageToConversation,
} from '~/actions/messages'
import { MEETING_STATUS } from '~/constants/meetingStatusIds'
import { SWITCH_TWILIO_CHANNEL } from '~/constants/types/messages'
import AllocatorCompanyDetailsModal from '../../AllocatorCompanyDetailsModal/AllocatorCompanyDetailsModal'
import DiscoverServiceProviderDetailsModal from '../../DiscoverServiceProviderDetailsModal/DiscoverServiceProviderDetailsModal'
import FundDetailsModalContainer from '../../FundDetailsModalContainer'
import Loading from '../../Loading'
import { ScheduleMeeting } from '../../MeetingActions'
import PremiumTierModal from '../../PremiumTierModal/PremiumTierModal'
import AddPeopleToConversation from '../AddPeopleToConversation'
import ChatHeader from '../ChatHeader'
import DataTransformations from '../DataTransformations'
import ForbiddenMessagePanel from '../ForbiddenMessagePanel'
import InfoDrawer from '../InfoDrawer'
import MessageBoard from '../MessageBoard'
import MessageSearchBar from '../MessageSearchBar'
import MessageActions from './MessageActions'

const MessagesPanel = ({
  conversationID,
  simpleBoard = false,
  searchVisible = false,
  archived = false,
  onRepliedToArchive = () => {},
  setRefreshConversations,
  setConversationID,
}) => {
  const [conversation, setConversation] = useState(null)
  const [searching, setSearching] = useState(false)
  const [currentSearchIndex, setCurrentSearchIndex] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [conversationLoading, setConversationLoading] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)

  const [addColleaguesVisible, setAddColleaguesVisible] = useState(false)
  const [leaveConversationVisible, setLeaveConversationVisible] =
    useState(false)
  const [leaveConversationLoading, setLeaveConversationLoading] =
    useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [companyDetailsVisible, setCompanyDetailsVisible] = useState(false)
  const [selectedFundID, setSelectedFundID] = useState(null)
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false)
  const [consumptionLookup, setConsumptionLookup] = useState([])
  const [validationResponse, setValidationResponse] = useState({})
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false)
  const [isForbidden, setIsForbidden] = useState(false)

  const [selectedInvitedCompany, setSelectedInvitedCompany] = useState(false)
  const [selectedCompanyType, setSelectedCompanyType] = useState('')
  const [hasSetMeetingTime, setHasSetMeetingTime] = useState(false)

  const dispatch = useDispatch()

  // method that takes the last 25 messages of the conversation
  // and appends to the bottom of the messages array (top of the view)
  const get25OlderMessages = async () => {
    if (conversation.messages.length === 0) return
    if (conversation.totalMessages <= conversation.messages.length) return
    if (loadingOlderMessages) return

    setLoadingOlderMessages(true)
    const firstIDInConversation = orderBy(
      conversation.messages,
      ['timeSent'],
      'asc'
    )[0].messageID
    try {
      const messagesRaw = await getMessages(
        conversationID,
        firstIDInConversation,
        25
      )
      const newMessages = [...messagesRaw.data.result, ...conversation.messages]
      const transformedNewMessages =
        DataTransformations.transformOrderAndIndexMessages(
          newMessages,
          conversation.totalMessages,
          conversationID
        )
      conversation.messages = transformedNewMessages
      setConversation(conversation)
    } catch (error) {
      message.error(
        'There was an error while attempting to fetch older messages'
      )
    } finally {
      setLoadingOlderMessages(false)
    }
  }

  // same idea as above, but this takes a variable number of messages depending
  // on the index of the message that needs to be highlighted as current found message
  // should only be called if the message is not loaded for this conversation yet
  const getOlderMessagesForCurrentSearchResult = useCallback(async () => {
    if (isNil(searchResults) || searchResults.length === 0) return

    const indexToSearch = searchResults[currentSearchIndex].messageIndex
    const { messageID: firstMessageID, index: firstIndex } = orderBy(
      conversation.messages,
      ['timeSent'],
      'asc'
    )[0]
    const numberToTake = firstIndex - indexToSearch + 10
    try {
      const messagesRaw = await getMessages(
        conversationID,
        firstMessageID,
        numberToTake < 0 ? 0 : numberToTake
      )
      const newMessages = [...messagesRaw.data.result, ...conversation.messages]
      const transformedNewMessages =
        DataTransformations.transformOrderAndIndexMessages(
          newMessages,
          conversation.totalMessages,
          conversationID
        )
      conversation.messages = transformedNewMessages
      const newConversation = { ...conversation }
      setConversation(newConversation)
    } catch (error) {
      message.error(
        'There was an error while attempting to fetch older messages'
      )
    } finally {
      setLoadingOlderMessages(false)
    }
  }, [searchResults, conversation, currentSearchIndex, conversationID])

  const twilioClient = useSelector((state) => state.messaging.twilioClient)

  // method to consume channel once the conversation has been opened
  const consumeChannel = useCallback(() => {
    twilioClient
      .getConversationBySid(conversation.channelSID)
      .then((conversation) => {
        conversation.setAllMessagesRead()
      })
  }, [conversation, twilioClient])

  // callback for when a new message arrives
  // another callback exists in the ConversationsPanel that updates that list
  // so this component is not depending on that one to include new messages into
  // the conversation and is isolated and concerned only for this scope
  const onTwilioMessageAdded = useCallback(
    (twilioMessage) => {
      if (
        moment()
          .subtract(30, 'second')
          .isAfter(moment.utc(twilioMessage.timestamp))
      )
        return

      if (isNil(conversation)) return

      if (twilioMessage.conversation.sid !== conversation.channelSID) return
      const transformedMessage = DataTransformations.TransformTwilioMessage(
        twilioMessage,
        conversation.participants,
        conversationID
      )
      const conversationToMod = { ...conversation }
      conversationToMod.messages = [
        ...conversationToMod.messages,
        transformedMessage,
      ]
      conversationToMod.totalMessages += 1
      setConversation(conversationToMod)
      consumeChannel()
    },
    [conversation, consumeChannel, conversationID]
  )

  const onMemberUpdated = useCallback(
    (updatedEvent) => {
      if (updatedEvent.updateReasons.includes('lastConsumedMessageIndex')) {
        if (isNil(conversation)) return
        if (updatedEvent.member.channel.sid !== conversation.channelSID) return

        const updatedContactId = parseInt(updatedEvent.member.identity, 10)
        const contact = conversation.details.contacts.find(
          (x) => x.contactId === updatedContactId
        )
        const updatedMessageIndex = updatedEvent.member.lastConsumedMessageIndex
        const lookupItemToUpdate = consumptionLookup.find(
          (x) => x.contactID === updatedContactId
        )
        if (isNil(lookupItemToUpdate)) {
          setConsumptionLookup([
            ...consumptionLookup,
            {
              contactID: updatedContactId,
              companyID: contact.companyId,
              lastConsumedMessageIndex: updatedMessageIndex,
            },
          ])
        } else {
          lookupItemToUpdate.lastConsumedMessageIndex = updatedMessageIndex
          setConsumptionLookup([...consumptionLookup])
        }
      }
    },
    [conversation, consumptionLookup]
  )

  // subscribe and unsubscribe to twilio when component mounts / unmounts
  useEffect(() => {
    if (!isNil(twilioClient)) {
      twilioClient.on('messageAdded', onTwilioMessageAdded)
      twilioClient.on('memberUpdated', onMemberUpdated)
      if (!isNil(conversation)) {
        consumeChannel()
      }
    }
    return () => {
      if (!isNil(twilioClient)) {
        twilioClient.off('messageAdded', onTwilioMessageAdded)
        twilioClient.off('memberUpdated', onMemberUpdated)
      }
    }
  }, [
    twilioClient,
    conversation,
    onTwilioMessageAdded,
    onMemberUpdated,
    consumeChannel,
  ])

  useEffect(() => {
    if (isNil(twilioClient)) return
    if (isNil(conversation)) return
    const updateMembersForCurrentConversation = async () => {
      const channel = await twilioClient.getConversationBySid(
        conversation.channelSID
      )

      const lookups = []

      const members = await channel.getParticipants()

      members.forEach((member) => {
        const contactIdToUpdate = parseInt(member.identity, 10)
        const contact = conversation.details.contacts.find(
          (x) => x.contactId === contactIdToUpdate
        )
        if (isNil(contact)) {
          return
        }
        lookups.push({
          contactID: parseInt(member.identity, 10),
          companyID: contact.companyId,
          lastConsumedMessageIndex: member.lastConsumedMessageIndex,
        })
      })
      setConsumptionLookup(lookups)
    }

    updateMembersForCurrentConversation()
  }, [conversation, twilioClient])

  // first time the component is rendered with a conversationID - get that conversation's data
  useEffect(() => {
    const fetchConversation = async () => {
      setConversationLoading(true)
      try {
        const rawConversation = await getConversation(
          conversationID,
          true,
          true
        )
        setUpgradeModalVisible(false)
        setIsForbidden(false)

        const conversation = rawConversation.data.result
        conversation.messages =
          DataTransformations.transformOrderAndIndexMessages(
            conversation.messages,
            conversation.totalMessages,
            conversationID
          )
        setConversation(conversation)
        setValidationResponse(conversation.validationResponse)
      } catch (error) {
        if (error?.response?.status === 403) {
          setUpgradeModalVisible(true)
          setIsForbidden(true)
          setConversation(null)
        } else
          message.error(
            'Something went wrong while attempting to fetch the conversation data'
          )
      } finally {
        setConversationLoading(false)
      }
    }
    conversationID > 0 && fetchConversation()
    setSearching(false)
    setCurrentSearchIndex(null)
    setSearchResults(null)

    dispatch({
      type: SWITCH_TWILIO_CHANNEL,
      payload: conversationID,
    })

    return () => {
      dispatch({
        type: SWITCH_TWILIO_CHANNEL,
        payload: null,
      })
    }
  }, [conversationID, dispatch])

  // when the current user result changes and the message is not yet loaded,
  // fetch older messages up to that message + 10 more older messages for better UX
  useEffect(() => {
    if (
      !isNil(conversation) &&
      !isNil(searchResults) &&
      searchResults.length > 0 &&
      !conversation.messages.some(
        (x) => x.index === searchResults[currentSearchIndex].messageIndex
      )
    )
      getOlderMessagesForCurrentSearchResult()
  }, [
    currentSearchIndex,
    getOlderMessagesForCurrentSearchResult,
    conversation,
    searchResults,
  ])

  // the user has left the conversation (not just opened the modal)
  const leaveConversationHandler = useCallback(async () => {
    setLeaveConversationLoading(true)

    try {
      const leaveConversationResponseRaw = await leaveConversation(
        conversationID
      )
      setRefreshConversations(true)
      message.success(leaveConversationResponseRaw.data.message)
      setConversationID(null)
    } catch (error) {
      message.error(error.response.data.message)
    } finally {
      setLeaveConversationLoading(false)
      setLeaveConversationVisible(false)
    }
  }, [conversationID, setRefreshConversations, setConversationID])

  // the user has sent a message - send to BE and consume the channel
  // after this one is sent since Twilio does not consume it automatically
  const sendMessageHandler = useCallback(
    async (messagePar, fileName, isAttachment) => {
      setSendingMessage(true)
      try {
        let sendResponseRaw
        if (isAttachment === true) {
          sendResponseRaw = await sendAttachmentToConversation(
            conversationID,
            fileName,
            messagePar
          )
        } else {
          sendResponseRaw = await sendMessageToConversation(
            conversationID,
            messagePar
          )
        }
        consumeChannel()
        setValidationResponse(
          sendResponseRaw.data.result.furtherValidationResponse
        )
        if (archived) {
          onRepliedToArchive()
        }
      } catch (error) {
        //message.error(error.response.data.message);
        message.error('An error occurred while attempting to send message')
      } finally {
        setSendingMessage(false)
      }
    },
    [conversationID, consumeChannel, archived, onRepliedToArchive]
  )

  // on searching the conversation, take what results the BE returned
  // for this search term and reset the current index to 0
  const searchHandler = useCallback(
    async (searchTerm) => {
      const searchResultsRaw = await searchConversation(
        conversationID,
        searchTerm
      )
      setSearchTerm(searchTerm)
      setCurrentSearchIndex(0)
      const searchResultsOrdered = orderBy(
        searchResultsRaw.data.result.items,
        ['messageIndex'],
        'desc'
      )
      setSearchResults(searchResultsOrdered)
    },
    [conversationID]
  )

  // on next clicked while a search is active
  const nextSearchHandler = useCallback(() => {
    if (isNil(searchResults)) return
    let nextIndex = 0
    if (currentSearchIndex !== searchResults.length - 1)
      nextIndex = currentSearchIndex + 1

    setCurrentSearchIndex(nextIndex)
  }, [currentSearchIndex, searchResults])

  // on previous clicked while a search is active
  const previousSearchHandler = useCallback(() => {
    if (isNil(searchResults)) return
    let previousIndex = searchResults.length - 1
    if (currentSearchIndex !== 0) previousIndex = currentSearchIndex - 1

    setCurrentSearchIndex(previousIndex)
  }, [currentSearchIndex, searchResults])

  const closeSearchHandler = () => {
    setSearching(false)
    setCurrentSearchIndex(null)
    setSearchResults(null)
  }

  const fundClickedHandler = (fundID) => {
    setSelectedCompanyType(conversation.details.companyType)
    setSelectedFundID(fundID)
    setCompanyDetailsVisible(true)
  }
  const companyClickedHandler = () => {
    setSelectedInvitedCompany(false)
    setSelectedCompanyType(conversation.details.companyType)
    setCompanyDetailsVisible(true)
  }
  const invitedCompanyClickedHandler = () => {
    setSelectedInvitedCompany(true)
    setSelectedCompanyType(conversation.details.invitedCompanyType)
    setCompanyDetailsVisible(true)
  }

  const meetingPendingScheduling =
    conversation?.meeting?.meetingStatusId === MEETING_STATUS.CONFIRMED &&
    !conversation?.meeting?.meetingDateTime &&
    !hasSetMeetingTime

  if (conversationLoading || isNil(conversation)) {
    return (
      <Loading
        style={{
          width: '100%',
          marginTop: '100px',
        }}
        spinning={conversationLoading}
      />
    )
  }
  if (upgradeModalVisible) {
    return (
      <div id="premiumModalDiv">
        <PremiumTierModal
          bypassContainer={true}
          closable={true}
          visible={upgradeModalVisible}
          onModalClosed={() => {
            setUpgradeModalVisible(false)
          }}
        />
      </div>
    )
  }
  if (isForbidden) {
    return (
      <ForbiddenMessagePanel
        onRequestUpgrade={() => {
          setUpgradeModalVisible(true)
        }}
      />
    )
  }

  return (
    <>
      {!simpleBoard && (
        <ChatHeader
          conversationType={
            isNil(conversation.conversationType)
              ? 'directmessage'
              : conversation.conversationType
          }
          title={
            isNil(conversation.details.invitedCompanyName)
              ? conversation.details.companyName
              : `${conversation.details.companyName} & ${conversation.details.invitedCompanyName}`
          }
          contacts={conversation.details.contacts}
          onAddMorePeopleClicked={() => setAddColleaguesVisible(true)}
          onTitleClicked={
            conversation.details.companyType === 'Manager' //don't make title clickable for manager companies - need to click the Info icon and then select a fund profile to view instead
              ? null
              : companyClickedHandler
          }
          onInfoClicked={() => setDrawerVisible(!drawerVisible)}
          onLeaveConversationClicked={() => setLeaveConversationVisible(true)}
          onSearchClicked={() => setSearching(true)}
          areFeaturesDisabled={!validationResponse.canSendMessage}
          meeting={conversation.meeting}
        />
      )}

      {conversation.meeting &&
        conversation.meeting.meetingStatusId === MEETING_STATUS.CONFIRMED &&
        meetingPendingScheduling && (
          <Alert status="info">
            <div className="w-full">
              Discuss times that work for all parties, then schedule the meeting
              here.
            </div>
            <ScheduleMeeting
              meetingId={conversation.meeting.meetingId}
              onSubmit={() => setHasSetMeetingTime(true)}
            />
          </Alert>
        )}

      <div>
        {(searching || searchVisible) && (
          <MessageSearchBar
            currentSearchIndex={currentSearchIndex}
            onClose={closeSearchHandler}
            onNext={nextSearchHandler}
            onPrevious={previousSearchHandler}
            onSearch={searchHandler}
            hasSearchOccured={!isNil(searchResults)}
            totalResults={isNil(searchResults) ? 0 : searchResults.length}
          />
        )}
        <div className="message-board">
          <MessageBoard
            messages={conversation.messages}
            currentSearchIndex={currentSearchIndex}
            searchResults={searchResults}
            searchTerm={searchTerm}
            onScrolledToTop={() => get25OlderMessages()}
            loadingOlderMessages={loadingOlderMessages}
            consumptionLookup={consumptionLookup}
            needsHeadsUp={validationResponse && validationResponse.needsHeadsUp}
            daySeparatorStyle={simpleBoard ? { width: '35%' } : {}}
          />
        </div>
        <MessageActions
          loading={sendingMessage}
          onSendMessage={sendMessageHandler}
          blockReason={validationResponse.reason}
          messagingDisabled={!validationResponse.canSendMessage}
        />
      </div>

      {!simpleBoard && (
        <InfoDrawer
          visible={drawerVisible}
          onDrawerClose={() => setDrawerVisible(false)}
          onFundClicked={fundClickedHandler}
          onCompanyClicked={companyClickedHandler}
          onInvitedCompanyClicked={invitedCompanyClickedHandler}
          company={DataTransformations.GetCompanyFromConversation(conversation)}
          dealInfo={DataTransformations.GetDealInfoFromConversation(
            conversation
          )}
        />
      )}

      {!simpleBoard && (
        <AddPeopleToConversation
          conversationID={conversationID}
          onClose={() => setAddColleaguesVisible(false)}
          visible={addColleaguesVisible}
        />
      )}

      <Modal
        title="Leave conversation"
        visible={leaveConversationVisible}
        onOk={leaveConversationHandler}
        okText="Leave conversation"
        cancelText="Close"
        okButtonProps={{
          type: 'danger',
          loading: leaveConversationLoading,
        }}
        onCancel={() => setLeaveConversationVisible(false)}
      >
        <span className="messages-chat-modal-title">
          Are you sure you want to leave the conversation?
        </span>
      </Modal>
      {selectedCompanyType === 'Manager' && (
        <FundDetailsModalContainer
          fund={{
            fundId: selectedFundID,
            contacts: conversation.details.contacts,
            company: {
              companyId:
                selectedInvitedCompany === false
                  ? conversation.details.companyID
                  : conversation.details.invitedCompanyID,
            },
          }}
          showFundProfile={companyDetailsVisible}
          arePointsNear={false}
          onClose={() => setCompanyDetailsVisible(false)}
          showDataroom={false}
          hideActions={true}
        />
      )}
      {selectedCompanyType === 'Allocator' && (
        <Modal
          visible={companyDetailsVisible}
          footer={null}
          onCancel={() => setCompanyDetailsVisible(false)}
          width="900px"
          bodyStyle={{ padding: 0 }}
        >
          <AllocatorCompanyDetailsModal
            companyId={
              selectedInvitedCompany
                ? conversation.details.invitedCompanyID
                : conversation.details.companyID
            }
            visible={companyDetailsVisible}
          />
        </Modal>
      )}
      {selectedCompanyType !== 'Manager' &&
        selectedCompanyType !== 'Allocator' &&
        companyDetailsVisible && (
          <Modal
            visible={companyDetailsVisible}
            footer={null}
            onCancel={() => setCompanyDetailsVisible(false)}
            width="900px"
            bodyStyle={{ padding: 0 }}
          >
            <DiscoverServiceProviderDetailsModal
              serviceProvider={{
                companyName: !selectedInvitedCompany
                  ? conversation.details.companyName
                  : conversation.details.invitedCompanyName,
                address: !selectedInvitedCompany
                  ? conversation.details.address
                  : conversation.details.invitedAddress,
              }}
              companyId={
                !selectedInvitedCompany
                  ? conversation.details.companyID
                  : conversation.details.invitedCompanyID
              }
              visible={companyDetailsVisible}
              onCancel={() => setCompanyDetailsVisible(false)}
              selectedFundId={0}
              hideActions={true}
            />
          </Modal>
        )}
    </>
  )
}

MessagesPanel.propTypes = {
  conversationID: PropTypes.number.isRequired,
  simpleBoard: PropTypes.bool,
  searchVisible: PropTypes.bool,
  archived: PropTypes.bool,
  onRepliedToArchive: PropTypes.func,
  setRefreshConversations: PropTypes.func,
  setConversationID: PropTypes.func,
}

export default MessagesPanel
