import {
  FETCH_EVENTS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
  UPDATE_ATTENDING_EVENT,
} from '~/constants/types/events'

const initialState = {
  isFetching: false,
  selectedEventId: 0,
  attendingEvents: [],
  availableEvents: [],
  pastEvents: [],
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return { ...state, isFetching: true }
    case FETCH_EVENTS_SUCCESS:
      return { ...state, isFetching: false, ...action.payload }
    case FETCH_EVENTS_FAILURE:
      return { ...state, isFetching: false, error: action.payload }
    case UPDATE_ATTENDING_EVENT:
      return { ...state, selectedEventId: action.payload }
    default:
      return state
  }
}
