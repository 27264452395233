import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import { api as http } from '~/api/services'
import CampaignStatusModal from '~/components/CampaignStatusModal'
import Loading from '~/components/Loading'
import useSearchParams from '~/hooks/useSearchParams'
import MandateWizard from './MandateWizard'
import { MandateWizardContextProvider } from './MandateWizardContext'
import { useCurrentStepIndex, useStepNavigation, useWizardSteps } from './steps'

export default function MandateWizardController() {
  const { Track, trackEvent } = useTracking({ page: 'MandateWizardForm' })
  const { campaignsModule } = useFlags()

  const [initialStepQuestions, setInitialStepQuestions] = useState([])
  const [initialStepAnswers, setInitialStepAnswers] = useState([])
  const [otherStepQuestions, setOtherStepQuestions] = useState([])
  const [otherStepAnswers, setOtherStepAnswers] = useState([])
  const [invalidAnswers, setInvalidAnswers] = useState([])
  const [requiredQuestionsForApplication, setRequiredQuestionsForApplication] =
    useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCommonQuestions, setSelectedCommonQuestions] = useState([])
  const [customQuestions, setCustomQuestions] = useState([])
  const [showCampaignStatusModal, setShowCampaignStatusModal] = useState(false)

  const navigate = useNavigate()
  const { mandateId } = useParams()
  const [{ campaignId }] = useSearchParams()

  const isEdit = !isNil(mandateId)
  const stepNumbers = [
    1,
    ...new Set(otherStepQuestions.map((step) => step.stepNumber)),
  ]

  const { steps, getSteps } = useWizardSteps(stepNumbers)
  const currentStep = useCurrentStepIndex(steps)
  const stepNavigation = useStepNavigation(steps)

  const generateEmptyAnswers = (answers) => {
    const questions = [...initialStepQuestions, ...otherStepQuestions]
    const answersIds = answers.map((a) => a.questionId)
    const allAnswers = questions.map((q) => ({
      questionId: q.questionId,
      shortName: q.shortName,
      responseText: null,
      questionAnswerIds: [],
    }))
    return allAnswers.filter((a) => !answersIds.includes(a.questionId))
  }

  // The callback returned by `useNavigate` isn't stable, but changes with the
  // current location. Since we only want `fetchInitialStepQuestions` to be
  // called once, we'll create a memoized version of `navigate`. But by doing
  // so, we can only use absolute paths instead of relative paths.
  // https://github.com/remix-run/react-router/issues/7634
  const [stableNavigate] = useState(() => navigate)
  const fetchInitialStepQuestions = useCallback(() => {
    setLoading(true)
    http
      .get('/mandates/questions/overview')
      .then((response) => setInitialStepQuestions(response.data.result))
      .catch(() =>
        message.error('Could not fetch mandate questions for the first step')
      )
      .finally(() => {
        setLoading(false)
        isEdit &&
          http
            .get(`/mandates/answers/${mandateId}/overview`)
            .then((response) =>
              setInitialStepAnswers(response.data.result.answers)
            )
            .catch((error) => {
              if (error.response?.status === 403) {
                stableNavigate('/', { replace: true })
                return message.error(
                  'You do not have permission to edit that mandate.'
                )
              }
              return message.error(
                'Could not fetch mandate answers for the first step'
              )
            })
      })
  }, [isEdit, mandateId, stableNavigate])

  const { data: mandateWizard = {} } = useQuery(
    ['mandateWizard', mandateId],
    () => api.mandate.getMandateWizard(mandateId)
  )

  useEffect(() => {
    const savedCommonQuestions = mandateWizard
      ? map(
          filter(
            mandateWizard.commonQuestions,
            (question) => question.isSelected
          ),
          (question) => question.mandateApplicationQuestionId
        )
      : []
    setSelectedCommonQuestions(savedCommonQuestions)
    const customQuestions = map(
      filter(mandateWizard.customQuestions, (q) => q.isSelected),
      (q, sequence) => ({ ...q, sequence })
    )
    setCustomQuestions(customQuestions)
  }, [mandateWizard.commonQuestions, mandateWizard.customQuestions])

  const handleNextInitialStep = () => {
    setLoading(true)
    http
      .post('/mandates/questions', initialStepAnswers)
      .then((response) => {
        const questions = response.data.result
        setOtherStepQuestions(questions)

        const questionsShortNames = questions.map((r) => r.shortName)
        setOtherStepAnswers((prevAnswers) =>
          prevAnswers.filter((a) => questionsShortNames.includes(a.shortName))
        )

        const stepNumbers = [
          1,
          ...new Set(questions.map((step) => step.stepNumber)),
        ]
        stepNavigation.goToStep(1, getSteps(stepNumbers))
      })
      .catch(() =>
        message.error('Could not fetch mandate questions for the other steps')
      )
      .finally(() => {
        setLoading(false)
        if (isEdit) {
          setLoading(true)
          http
            .post(`/mandates/answers/${mandateId}`, initialStepAnswers)
            .then((response) => {
              setOtherStepAnswers(response.data.result.answers)
              setInvalidAnswers((prev) =>
                prev.filter((p) => p.stepNumber === currentStep + 1)
              )
              setRequiredQuestionsForApplication(
                map(
                  filter(
                    response.data.result.answers,
                    'isRequiredForApplication'
                  ),
                  (a) => a.questionId
                )
              )
            })
            .catch(() =>
              message.error(
                'Could not fetch mandate answers for the other steps'
              )
            )
            .finally(() => setLoading(false))
        }
      })
  }

  const onStatusCompletion = () => {
    navigate(`/workspace/${campaignId}`)
  }

  const handleSubmit = () => {
    trackEvent({
      eventName: 'click',
      element: 'Mandate Submit',
    })
    const type = isEdit ? 'patch' : 'post'
    const url = isEdit ? `/mandates/${mandateId}` : '/mandates'
    const action = isEdit ? 'edited' : 'created'
    setLoading(true)
    const answers = [...initialStepAnswers, ...otherStepAnswers]
    const answersWithNoResponses = [
      ...answers,
      ...generateEmptyAnswers(answers),
    ]
    const allAnswers = map(answersWithNoResponses, (a) => ({
      ...a,
      isRequiredForApplication: includes(
        requiredQuestionsForApplication,
        a.questionId
      ),
    }))

    http[type](url, {
      answers: allAnswers,
      campaignId,
    })
      .then((res) => {
        const savedMandateId = isEdit ? mandateId : res.data.result
        api.mandate.saveMandateWizard(savedMandateId, {
          selectedCommonQuestionIds: selectedCommonQuestions,
          selectedCustomQuestions: customQuestions,
        })
      })
      .then(() => {
        message.success(`Mandate ${action} successfully`)
        campaignsModule ? setShowCampaignStatusModal(true) : navigate('/')
      })
      .catch(() => message.error('Could not create mandate'))
      .finally(() => setLoading(false))
  }

  const goToNextStep = () => {
    trackEvent({
      eventName: 'click',
      element: 'Mandate next step',
      currentStep,
    })
    stepNavigation.goToNextStep()
  }

  const goToPreviousStep = () => {
    trackEvent({
      eventName: 'click',
      element: 'Mandate previous step',
      currentStep,
    })
    stepNavigation.goToPreviousStep()
  }

  const mandateWizardCtxValue = {
    steps,
    initialStepQuestions,
    initialStepAnswers,
    setInitialStepAnswers,
    handleNextInitialStep,
    invalidAnswers,
    setInvalidAnswers,
    currentStep,
    stepNumbers,
    goToNextStep,
    goToPreviousStep,
    handleSubmit,
    isEdit,
    requiredQuestionsForApplication,
    setRequiredQuestionsForApplication,
    commonQuestions: mandateWizard.commonQuestions,
    selectedCommonQuestions,
    setSelectedCommonQuestions,
    customQuestions,
    setCustomQuestions,
  }

  useEffect(() => {
    fetchInitialStepQuestions()
  }, [fetchInitialStepQuestions])

  return (
    <>
      <Track>
        <MandateWizardContextProvider value={mandateWizardCtxValue}>
          <Loading spinning={loading}>
            <MandateWizard
              questions={otherStepQuestions}
              answers={otherStepAnswers}
              setAnswers={setOtherStepAnswers}
            />
          </Loading>
        </MandateWizardContextProvider>
      </Track>
      <CampaignStatusModal
        campaignId={campaignId}
        visible={showCampaignStatusModal}
        onSave={onStatusCompletion}
      />
    </>
  )
}
