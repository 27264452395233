import PropTypes from 'prop-types'
import { faCommentSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './EmptyChannelList.less'

const EmptyChannelList = ({ areArchives = false }) => (
  <div className="empty-messages-main-container">
    <div className="empty-messages-icon">
      <FontAwesomeIcon icon={faCommentSlash} />
    </div>
    <div className="empty-messages-text">
      {areArchives
        ? 'No conversations have been archived'
        : 'You currently have no conversations'}
    </div>
  </div>
)

EmptyChannelList.propTypes = {
  areArchives: PropTypes.bool,
}

export default EmptyChannelList
