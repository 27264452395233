import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { api as http } from '~/api/services'
import Loading from '../Loading'
import MandateProfileContainer from './MandateProfileContainer'

const MandateProfileModal = ({
  mandateId,
  visible,
  onCancel,
  hideActions = false,
  openDataroom = false,
  onApplyToMandate,
  onAskQuestion,
  canAskQuestion,
  canApplyToMandate,
  fundId,
}) => {
  const [mandate, setMandate] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchMandate = useCallback(() => {
    if (!mandateId) {
      return
    }

    setLoading(true)
    http
      .get(`/mandates/${mandateId}`)
      .then((response) => setMandate(response.data.result))
      .catch(() => message.error('Could not load mandate profile'))
      .finally(() => setLoading(false))
  }, [mandateId])

  useEffect(() => {
    if (visible) {
      fetchMandate()
    }
  }, [fetchMandate, visible])

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width="900px"
      bodyStyle={{ padding: 0, minHeight: '400px' }}
    >
      <Loading spinning={loading} style={{ marginTop: '200px' }}>
        {visible && !isEmpty(mandate) && (
          <MandateProfileContainer
            openDataroom={openDataroom}
            mandate={mandate.mandateItem}
            hideActions={hideActions}
            onApplyToMandate={onApplyToMandate}
            onAskQuestion={onAskQuestion}
            canAskQuestion={canAskQuestion}
            canApplyToMandate={canApplyToMandate}
            fundId={fundId}
          />
        )}
      </Loading>
    </Modal>
  )
}

MandateProfileModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  mandateId: PropTypes.number,
  hideActions: PropTypes.bool,
  openDataroom: PropTypes.bool,
  onApplyToMandate: PropTypes.func,
  onAskQuestion: PropTypes.func,
  canAskQuestion: PropTypes.bool,
  canApplyToMandate: PropTypes.bool,
  fundId: PropTypes.number,
}

export default MandateProfileModal
