import concat from 'lodash/concat'
import filter from 'lodash/filter'
import { createSelector } from 'reselect'

export const selectAttendingEvent = createSelector(
  (state) => state.events.attendingEvents,
  (state) => state.events.selectedEventId,
  (attendingEvents, eventId) =>
    filter(attendingEvents, (e) => e.eventId === eventId)[0]
)

export const selectPastEvent = createSelector(
  (state) => state.events.pastEvents,
  (state) => state.events.selectedEventId,
  (pastEvents, eventId) => filter(pastEvents, (e) => e.eventId === eventId)[0]
)

export const selectEvent = createSelector(
  (state) => state.events.attendingEvents,
  (state) => state.events.pastEvents,
  (state) => state.events.selectedEventId,
  (next, past, eventId) =>
    filter(concat(next, past), (e) => e.eventId === eventId)[0]
)

export const existsActiveSummit = createSelector(
  (state) => state.events.attendingEvents,
  (attendingEvents) => attendingEvents.length > 0
)

export const existsActiveVirtualSummit = createSelector(
  (state) => state.events.attendingEvents,
  (attendingEvents) =>
    attendingEvents.length > 0 &&
    filter(attendingEvents, (e) => e.virtualSummit).length > 0
)

export const selectedEventHasEnded = createSelector(
  (state) => state.events.pastEvents,
  (state) => state.events.selectedEventId,
  (past, eventId) => filter(past, (e) => e.eventId === eventId).length > 0
)
