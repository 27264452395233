import { getMandateQuestionnaireResponses } from './mandate'
import { api } from './services'

export const getFundsThatMatchWithAllocator = (companyContactId) =>
  api.get(`/funds/match/${companyContactId}`)

export const getFundOptions = () => api.get(`/funds/options`)

export const getProfileWizardAnswers = (fundId) =>
  api.get(`funds/${fundId}/ProfileWizard`)

export const createIlliquidFund = (profile) =>
  api.post('funds/Illiquid', profile)

export const saveIlliquidFundProfile = (fundId, profile) =>
  api.patch(`funds/${fundId}/Illiquid`, profile)

export const createLiquidFund = (profile) => api.post('funds/Liquid', profile)

export const saveLiquidFundProfile = (fundId, profile) =>
  api.patch(`funds/${fundId}/Liquid`, profile)

export const getFundType = (fundId) => api.get(`funds/${fundId}/type`)

export const getCampaign = (fundId) => api.get(`funds/${fundId}/campaign`)

export const trackFundStep = (fundId, step) =>
  api.post(`funds/${fundId}/step/${step}/track`)

export const toggleFundVisibility = (fundId, isInvisible) =>
  api.patch(`/funds/invisible/${fundId}`, {
    isInvisible,
  })

export const subscribeToFund = (fundId) => api.post(`funds/${fundId}/subscribe`)

export const trackCompareFunds = (
  fundIds,
  chartType,
  primaryBenchmark,
  secondaryBenchmark = null
) =>
  api.post(`funds/compare`, {
    fundIds,
    chartType,
    primaryBenchmarkId: primaryBenchmark,
    secondaryBenchmarkId: secondaryBenchmark,
  })

export const getFundProfile = (fundId) =>
  api.get(`funds/${fundId}/profile`).then((res) => res.data.result)

export const getFundProfileForApplication = async (
  fundId,
  campaignApplicationId
) => {
  const [fundProfile, applicationFeedback, applicationQuestionnaireResponses] =
    await Promise.all([
      getFundProfile(fundId),
      api
        .get(`campaigns/applications/${campaignApplicationId}/feedback`)
        .then((res) => res.data.result),
      api
        .get(`campaigns/applications/${campaignApplicationId}/responses`)
        .then((res) => res.data.result),
    ])
  return {
    ...fundProfile,
    ...applicationFeedback,
    applicationQuestionnaireResponses,
  }
}

export const getFundProfileForMandateApplication = async (
  fundId,
  mandateApplicationId
) => {
  const [fundProfile, applicationQuestionnaireResponses] = await Promise.all([
    getFundProfile(fundId),
    getMandateQuestionnaireResponses(mandateApplicationId),
  ])
  return { ...fundProfile, applicationQuestionnaireResponses }
}

export const checkFundFreshness = (fundId) =>
  api.get(`funds/${fundId}/freshness`).then((res) => res.data.result)

export const getAppliableFundsForMandate = (mandateId) =>
  api.get(`funds/appliableTo/${mandateId}`).then((res) => res.data.result)

export const saveFundAum = (fundId, fundAum) =>
  api.patch(`funds/${fundId}/aum`, { fundAum })

export const saveFundMarketingMaterials = (fundId, marketingMaterials) =>
  api.patch(`funds/${fundId}/marketingmaterials`, { urls: marketingMaterials })

export const addMissingIlliquidReturns = (fundId, returns) =>
  api.post(`funds/${fundId}/returns/illiquid/missing`, { returns })

export const getFundHealth = (fundId) =>
  api.get(`funds/${fundId}/health`).then((res) => res.data.result)

export const getFundAnalytics = (fundId, timeSpan) =>
  api
    .get(`funds/${fundId}/analytics`, { params: { timeSpan } })
    .then((res) => res.data.result)
