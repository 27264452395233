import * as React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { CompatRoute, Navigate } from 'react-router-dom-v5-compat'
import DiscoverBlur from '~/assets/BlockedPages/Discover.PNG'
import MeetingLobbyBlur from '~/assets/BlockedPages/MeetingLobbyBlur.PNG'
import MeetingsBlur from '~/assets/BlockedPages/MeetingsBlur.PNG'
import MessagesBlur from '~/assets/BlockedPages/MessagesBlur.PNG'
import RoadshowBlur from '~/assets/BlockedPages/RoadshowBlur.PNG'
import VideoConferenceBlur from '~/assets/BlockedPages/VideoConferenceBlur.PNG'
import AuthorizationGate from '~/components/AuthorizationGate'
import Loading from '~/components/Loading'
import MobileGate from '~/components/MobileGate'
import { BlockView, RequireAuth } from '~/components/RequireAuth'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import { getTier } from '~/selectors/auth'
import { Free, Starter } from '../constants/tiers'
import AllocatorRoute from './allocator'
import AppChrome from './AppChrome'
import Attachment from './attachment'
import CFNAllocatorOnboarding from './CFNAllocatorOnboarding/CFNAllocatorOnboarding'
import PresentationAttendees from './community/PresentationAttendees'
import PresentationRoom from './community/PresentationRoom'
import PresentationWizardForm from './community/PresentationWizardForm'
import ContactContainer from './ContactContainer'
import Content from './content'
import FundWizard from './createFund/FundWizard'
import CampaignsDashboard from './dashboard/Dashboard'
import { Discover, discoverRedirects } from './discover'
import DiscoverMapView from './DiscoverMapView/DiscoverMapView'
import DiscoverServiceProviders from './discoverServiceProviders/discoverServiceProviders'
import EditContactContainer from './EditContactContainer'
import FundRoute from './fund'
import FundTearSheetContainer from './FundTearSheetContainer'
import Login from './login/Login'
import MandateRoute, { MandateRedirect } from './mandate'
import MeetingLobby from './meetingLobby/meetingLobby'
import MeetingDetails from './Meetings/MeetingDetails'
import Meetings from './Meetings/Meetings'
import Messages, { deprecated__Messages as OldMessages } from './messages'
import NewCompanyOnboardWizard from './newCompanyOnboardWizard/NewCompanyOnboard'
import Settings from './newSettings/Settings'
import NotFound from './NotFound'
import NotificationEntries from './notificationsEntries/NotificationEntries'
import { OldOnboardingWizard, Onboarding } from './Onboarding'
import Portfolio from './portfolio/portfolio'
import PortfolioFundDetails from './portfolioFundDetails/PortfolioFundDetails'
import Roadshows from './roadshows/Roadshows'
import ServiceProviderWizardContainer from './ServiceProviderWizardContainer'
import SSOContainer from './sso'
import SubscriptionAgreement from './SubscriptionAgreement'
import SummitDetailsContainer from './summits/SummitDetailsContainer'
import Summits from './summits/Summits'
import VideoConference from './VideoConference/VideoConference'

const Community = React.lazy(() => import('./community'))
const Profile = React.lazy(() => import('./profile'))
const Campaigns = React.lazy(() => import('./campaigns'))
const Workspace = React.lazy(() => import('./workspace'))
const Marketplace = React.lazy(() => import('./marketplace'))
const Research = React.lazy(() => import('./research'))
const ResearchCampaignWizard = React.lazy(() =>
  import('./ResearchCampaignWizard')
)

export default function AppRoutes() {
  const { pathname } = useLocation()
  const { role } = useAuth()
  const tier = useSelector(getTier)
  const {
    showCreateMandate = true,
    batteaModule = true,
    freeStarterProTiers = true,
    mobileDiscover,
    mobileMessages,
    mobileSummits,
    mobilePresentations,
    mobileSettingsPages,
    newOnboarding,
  } = useFlags()

  const shouldBlockPage = (page) =>
    !freeStarterProTiers &&
    [Free, Starter].includes(tier) &&
    ['DiscoverFunds', 'Discover', 'Roadshows'].includes(page)

  return (
    <Switch>
      <Route exact path="/login/:redirect?">
        <Login />
      </Route>
      <Route path="/tearsheet/:fundId?" exact>
        <RequireAuth>
          <AppChrome hideSidebar hideTopBar initializeMessages={false}>
            <FundTearSheetContainer />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/" exact>
        <RequireAuth>
          <AppChrome>
            <CampaignsDashboard />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/notifications" exact>
        <RequireAuth>
          <AppChrome>
            <NotificationEntries />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/meeting/:meetingType(summit|online)/:meetingId">
        <RequireAuth>
          <AppChrome>
            <MeetingDetails />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/meetings/:tabId?">
        <RequireAuth previewImage={MeetingsBlur}>
          <AppChrome>
            <Meetings />
          </AppChrome>
        </RequireAuth>
      </Route>
      {mobileMessages ? (
        <CompatRoute path="/messages">
          <RequireAuth>
            <AppChrome>
              <Messages />
            </AppChrome>
          </RequireAuth>
        </CompatRoute>
      ) : (
        <Route path="/messages/:conversationId?/:isArchived?">
          <RequireAuth previewImage={MessagesBlur}>
            <AppChrome>
              <MobileGate>
                <OldMessages />
              </MobileGate>
            </AppChrome>
          </RequireAuth>
        </Route>
      )}
      <Route path="/edit-fund/:fundId/:step?">
        <RequireAuth>
          <AppChrome>
            <AuthorizationGate
              blockWhen={() => role !== ROLE.MANAGER}
              fallback={<Navigate replace to="/" />}
            >
              <FundWizard />
            </AuthorizationGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/new-presentation">
        <RequireAuth>
          <AppChrome>
            <MobileGate mobileFlag={mobilePresentations}>
              <PresentationWizardForm />
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/edit-presentation/:id/:step?">
        <RequireAuth>
          <AppChrome>
            <MobileGate mobileFlag={mobilePresentations}>
              <PresentationWizardForm />
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <CompatRoute
        path="/mandate"
        children={
          <RequireAuth>
            <AppChrome>
              <MandateRoute />
            </AppChrome>
          </RequireAuth>
        }
      />
      <CompatRoute
        path="/fund"
        children={
          <RequireAuth>
            <AppChrome>
              <FundRoute />
            </AppChrome>
          </RequireAuth>
        }
      />
      <CompatRoute
        path="/allocator"
        children={
          <RequireAuth>
            <AppChrome>
              <AllocatorRoute />
            </AppChrome>
          </RequireAuth>
        }
      />
      {showCreateMandate && (
        <MandateRedirect
          from="/edit-mandate/:mandateId/:campaignId?"
          to={({ mandateId, campaignId }) => ({
            pathname: `/mandate/${mandateId}/edit`,
            search: campaignId ? `?campaignId=${campaignId}` : undefined,
          })}
        />
      )}
      {showCreateMandate && (
        <MandateRedirect
          from="/create-mandate/:campaignId?"
          to={({ campaignId }) => ({
            pathname: '/mandate/new',
            search: campaignId ? `?campaignId=${campaignId}` : undefined,
          })}
        />
      )}
      <Route path="/summits/:id/:tab?/:subtab?">
        <RequireAuth>
          <AppChrome>
            <MobileGate mobileFlag={mobileSummits}>
              <SummitDetailsContainer />
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/summits">
        <RequireAuth>
          <AppChrome>
            <MobileGate mobileFlag={mobileSummits}>
              <Summits />
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/content">
        <RequireAuth>
          <AppChrome>
            <Content />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/roadshows">
        <RequireAuth>
          <AppChrome
            blockAccess={shouldBlockPage('Roadshows')}
            preview={<BlockView image={RoadshowBlur} onlyPro={true} />}
          >
            <Roadshows />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/company/:companyId/contacts/:contactId/edit">
        <RequireAuth>
          <AppChrome>
            <EditContactContainer />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/company/:companyId/contacts/:contactId">
        <RequireAuth>
          <AppChrome>
            <ContactContainer />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Redirect from="/company/colleagues" to="/settings/company/colleagues" />
      <Redirect from="/company/funds" to="/settings/funds" />
      <Redirect from="/company/:activeTab?" to="/settings" />
      <Route path="/service-providers/new-service-provider">
        <RequireAuth>
          <AppChrome>
            <ServiceProviderWizardContainer />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/company/onboard">
        <NewCompanyOnboardWizard />
      </Route>
      <Route path="/discover">
        <RequireAuth>
          <AppChrome
            blockAccess={shouldBlockPage('Discover')}
            preview={<BlockView image={DiscoverBlur} />}
          >
            <MobileGate mobileFlag={mobileDiscover}>
              <AuthorizationGate
                blockWhen={() =>
                  pathname.includes('discover/funds') && role === ROLE.MANAGER
                }
                fallback={<Navigate replace to="/" />}
              >
                <Discover />
              </AuthorizationGate>
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      {discoverRedirects}
      <Route path="/settings/:mainTab?/:tab?/:subTab?">
        <RequireAuth>
          <AppChrome>
            <MobileGate mobileFlag={mobileSettingsPages}>
              <AuthorizationGate
                blockWhen={(params) =>
                  (params.mainTab === 'funds' && role !== ROLE.MANAGER) ||
                  (params.mainTab === 'matchmaking' && role !== ROLE.ALLOCATOR)
                }
                fallback={<Navigate replace to="/" />}
              >
                <Settings />
              </AuthorizationGate>
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/portfolio" exact>
        <RequireAuth>
          <AppChrome>
            <Portfolio />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/portfolio/:id">
        <RequireAuth>
          <AppChrome>
            <PortfolioFundDetails />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/serviceproviders">
        <RequireAuth>
          <AppChrome>
            <DiscoverServiceProviders />
          </AppChrome>
        </RequireAuth>
      </Route>
      {getOnboardingRoute(newOnboarding, role)}
      <Route path="/agreement">
        <RequireAuth>
          <AppChrome hideSidebar hideTopBar initializeMessages={false}>
            <SubscriptionAgreement />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/meeting-lobby">
        <RequireAuth previewImage={MeetingLobbyBlur}>
          <AppChrome hideSidebar hideTopBar initializeMessages={false}>
            <MeetingLobby />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/video-conference/:meetingType/:meetingId">
        <RequireAuth previewImage={VideoConferenceBlur}>
          <AppChrome hideSidebar hideTopBar>
            <VideoConference />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/discovermap">
        <RequireAuth>
          <AppChrome>
            <DiscoverMapView />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/attachment/:name/:entityId/:type?">
        <RequireAuth>
          <AppChrome hideSidebar hideTopBar initializeMessages={false}>
            <Attachment />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/investor/:tab">
        <RequireAuth>
          <AppChrome>
            <CFNAllocatorOnboarding />
          </AppChrome>
        </RequireAuth>
      </Route>
      <Redirect
        from="/presentations/:id/room"
        to="/community/presentations/:id/room"
      />
      <Route path="/community/presentations/:id/room">
        <RequireAuth>
          <AppChrome hideSidebar hideTopBar initializeMessages={false}>
            <MobileGate mobileFlag={mobilePresentations}>
              <PresentationRoom />
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Redirect
        from="/presentations/:id/attendees"
        to="/community/presentations/:id/attendees"
      />
      <Route path="/community/presentations/:id/attendees">
        <RequireAuth>
          <AppChrome>
            <MobileGate mobileFlag={mobilePresentations}>
              <PresentationAttendees />
            </MobileGate>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Redirect
        from="/presentations/upcoming/:presentationId?"
        to="/community/presentations/:presentationId?"
      />
      <Redirect from="/presentations/past" to="/community/presentations" />
      <Redirect
        from="/presentations/my-presentations"
        to="/community/presentations"
      />
      <Redirect from="/presentations" to="/community/presentations" />
      <Route path="/community">
        <RequireAuth>
          <AppChrome>
            <React.Suspense fallback={<LoadingPage />}>
              <Community />
            </React.Suspense>
          </AppChrome>
        </RequireAuth>
      </Route>

      <CompatRoute
        path="/profile"
        children={
          <RequireAuth>
            <AppChrome>
              <React.Suspense fallback={<LoadingPage />}>
                <Profile />
              </React.Suspense>
            </AppChrome>
          </RequireAuth>
        }
      />
      <CompatRoute
        path="/campaigns"
        children={
          <RequireAuth>
            <AppChrome>
              <React.Suspense fallback={<LoadingPage />}>
                <Campaigns />
              </React.Suspense>
            </AppChrome>
          </RequireAuth>
        }
      />
      <CompatRoute
        path="/workspace/:campaignId"
        children={
          <RequireAuth>
            <AppChrome>
              <React.Suspense fallback={<LoadingPage />}>
                <Workspace />
              </React.Suspense>
            </AppChrome>
          </RequireAuth>
        }
      />
      {batteaModule && (
        <CompatRoute
          path="/marketplace"
          children={
            <RequireAuth>
              <AppChrome>
                <React.Suspense fallback={<LoadingPage />}>
                  <Marketplace />
                </React.Suspense>
              </AppChrome>
            </RequireAuth>
          }
        />
      )}
      <Route path="/create-research/:campaignId/:stepId?">
        <RequireAuth>
          <AppChrome>
            <React.Suspense fallback={<LoadingPage />}>
              <AuthorizationGate
                blockWhen={() => role !== ROLE.ALLOCATOR}
                fallback={<Navigate replace to="/" />}
              >
                <ResearchCampaignWizard />
              </AuthorizationGate>
            </React.Suspense>
          </AppChrome>
        </RequireAuth>
      </Route>
      <Route path="/edit-research/:researchCampaignId/:stepId?">
        <RequireAuth>
          <AppChrome>
            <React.Suspense fallback={<LoadingPage />}>
              <AuthorizationGate
                blockWhen={() => role !== ROLE.ALLOCATOR}
                fallback={<Navigate replace to="/" />}
              >
                <ResearchCampaignWizard />
              </AuthorizationGate>
            </React.Suspense>
          </AppChrome>
        </RequireAuth>
      </Route>
      <CompatRoute path="/research">
        <RequireAuth>
          <AppChrome>
            <React.Suspense fallback={<LoadingPage />}>
              <AuthorizationGate
                blockWhen={() => role !== ROLE.MANAGER}
                fallback={<Navigate replace to="/" />}
              >
                <Research />
              </AuthorizationGate>
            </React.Suspense>
          </AppChrome>
        </RequireAuth>
      </CompatRoute>
      <Route path="/sso">
        <SSOContainer />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

function getOnboardingRoute(showNewOnboarding) {
  if (showNewOnboarding === undefined) {
    // Show the loading spinner while we fetch the feature flags
    return (
      <Route path="/onboard">
        <RequireAuth>
          <LoadingPage />
        </RequireAuth>
      </Route>
    )
  }

  const showOldOnboarding = !showNewOnboarding

  return showOldOnboarding ? (
    <Route path="/onboard">
      <RequireAuth>
        <AppChrome hideSidebar hideTopBar initializeMessages={false}>
          <OldOnboardingWizard />
        </AppChrome>
      </RequireAuth>
    </Route>
  ) : (
    <CompatRoute
      path="/onboard"
      children={
        <RequireAuth>
          <Onboarding />
        </RequireAuth>
      }
    />
  )
}

function LoadingPage() {
  return (
    <div className="flex-1 flex items-center justify-center">
      <Loading />
    </div>
  )
}
