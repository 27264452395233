import * as React from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom-v5-compat'
import { useTracking } from 'react-tracking'
import StepList from '~/components/StepList'
import { useMandateWizardContext } from './MandateWizardContext'
import {
  CONDITIONAL_STEPS,
  QUESTIONNAIRE_STEPS,
  useBasePath,
  useStepNavigation,
} from './steps'
import CommonQuestions from './Steps/CommonQuestions'
import CustomQuestions from './Steps/CustomQuestions'
import InitialStep from './Steps/InitialStep'
import Step from './Steps/Step'
import './MandateWizard.css'

export default function MandateWizard({ questions, answers, setAnswers }) {
  useTracking({ component: 'MandateWizard' })

  const { steps, goToNextStep, goToPreviousStep } = useMandateWizardContext()
  const lastStepNumber = Math.max(...steps.map((step) => step.stepNumber))

  const location = useLocation()
  const basePath = useBasePath()

  if (isEmpty(questions) && location.pathname !== basePath) {
    // If we haven't loaded any questions yet, it's probably because of a
    // bookmark or a refresh. For now, we need to start from the beginning.
    return <Navigate replace to={basePath} />
  }

  return (
    <div className="MandateWizard">
      <Routes>
        <Route index element={<InitialStep title="Mandate Overview" />} />
        <Route element={<MandateWizardLayout />}>
          {CONDITIONAL_STEPS.filter(
            (step) =>
              Boolean(step.path) &&
              steps.some((s) => s.stepNumber === step.stepNumber)
          ).map((step) => (
            <Route
              key={step.path}
              path={step.path}
              element={
                <Step
                  title={step.title}
                  alertMessage={step.alertMessage}
                  questions={questions.filter(
                    (question) => question.stepNumber === step.stepNumber
                  )}
                  answers={answers}
                  onSetAnswers={setAnswers}
                  onNextStep={goToNextStep}
                  onPrevStep={goToPreviousStep}
                  isLast={step.stepNumber === lastStepNumber}
                />
              }
            />
          ))}
          <Route
            path={QUESTIONNAIRE_STEPS.Common.path}
            element={
              <CommonQuestions
                isLastStep={
                  QUESTIONNAIRE_STEPS.Common.stepNumber === lastStepNumber
                }
              />
            }
          />
          <Route
            path={QUESTIONNAIRE_STEPS.Custom.path}
            element={
              <CustomQuestions
                isLastStep={
                  QUESTIONNAIRE_STEPS.Custom.stepNumber === lastStepNumber
                }
              />
            }
          />
        </Route>
        <Route path="*" element={<Navigate replace to={basePath} />} />
      </Routes>
    </div>
  )
}

function MandateWizardLayout() {
  const { trackEvent } = useTracking()
  const { steps, currentStep, invalidAnswers, stepNumbers } =
    useMandateWizardContext()
  const { goToStep } = useStepNavigation(steps)

  const invalidSteps = React.useMemo(() => {
    const invalidAnswerSteps = invalidAnswers.map((answer) =>
      stepNumbers.indexOf(answer.stepNumber)
    )
    return [...new Set(invalidAnswerSteps)]
  }, [invalidAnswers, stepNumbers])

  return (
    <>
      <StepList
        className="MandateWizard-steps"
        steps={steps.map((step) => step.title)}
        current={currentStep}
        onStepChange={(step) => {
          trackEvent({
            eventName: 'click',
            element: 'Mandate step change',
          })
          goToStep(step)
        }}
        stepsInError={invalidSteps}
      />

      <Outlet />
    </>
  )
}
