import * as React from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import {
  DiscoverController,
  DiscoverFilters,
  DiscoverTabs,
  extractQuestionGroups,
} from '~/components/Discover'
import { getDiscoverMandatesFilterComponent } from '~/components/DiscoverFilters'
import SelectFundFilter from '~/components/DiscoverFilters/SelectFundFilter'
import {
  AddMandateToListModal,
  useAddToListModal,
} from '~/components/DiscoverListModals'
import DiscoverMandatesResult from '~/components/DiscoverMandatesResult'
import DiscoverMandatesTable from '~/components/DiscoverMandatesTable/DiscoverMandatesTable'
import { ManageFunds } from '~/constants/userAccess'
import CompanyFundsContext from './companyFundsContext'
import './DiscoverContainer.less'

const sortFields = [
  { name: 'minSize', label: 'Mandate Min Size' },
  { name: 'maxSize', label: 'Mandate Max Size' },
  { name: 'managerMaxTrackRecord', label: 'Manager Maximum Track Record' },
  { name: 'managerMinTrackRecord', label: 'Manager Minimum Track Record' },
  { name: 'maxFirmAum', label: 'Maximum Firm Aum' },
  { name: 'maxFundAum', label: 'Maximum Fund Aum' },
  { name: 'minFirmAum', label: 'Minimum Firm Aum' },
  { name: 'minFundAum', label: 'Minimum Fund Aum' },
  { name: 'targetReturn', label: 'Target Return' },
]

const DiscoverMandatesContainer = ({ tab, resultId, fundId }) => {
  const { mandateAlerts, mandatesTabularView } = useFlags()

  const addMandateToListModal = useAddToListModal()

  const { Track } = useTracking({ page: 'DiscoverAllocators' })
  const { activities } = useSelector((state) => state.userAccess)
  const hasManageFundsAccess = activities.includes(ManageFunds)

  const funds = React.useContext(CompanyFundsContext)
  const [selectedFundId, setSelectedFundId] = React.useState(
    !isNil(fundId) && funds.find((x) => x.fundId === fundId)
      ? fundId
      : isEmpty(funds)
      ? null
      : funds[0].fundId
  )

  const modifyFilterQuery = (filters) => {
    if (hasManageFundsAccess && !isNil(selectedFundId)) {
      filters.push(`fundId eq ${selectedFundId}`)
    }
    if (!isNil(resultId)) {
      filters.push(`mandateId eq ${[resultId]}`)
    }
  }

  const defaultSort = selectedFundId ? 'Matchmaking Score' : 'Created Date'

  return (
    <Track>
      <AddMandateToListModal
        mandates={addMandateToListModal.items}
        visible={addMandateToListModal.visible}
        onClose={addMandateToListModal.hide}
      />
      <DiscoverController
        getResults={(params) => api.discovery.mandates(params)}
        modifyFilterQuery={modifyFilterQuery}
        refetchKeys={{ resultId, selectedFundId }}
        category="mandates"
        tab={tab}
        sortFields={sortFields}
        showTableView={mandatesTabularView}
        showMapView={false}
        showDiscoverAlerts={mandateAlerts === true}
        fundSelect={
          hasManageFundsAccess && (
            <SelectFundFilter
              selectedFund={selectedFundId}
              funds={funds}
              onChangeFund={(fundId) => {
                setSelectedFundId(fundId)
              }}
            />
          )
        }
        getFilters={() => api.filters.getDiscoverFiltersData('mandates')}
        renderFilters={({ filters }) => (
          <DiscoverFilters
            filters={filters}
            hideToggleMatches
            getFilterGroups={extractQuestionGroups()}
            renderFilterControl={getDiscoverMandatesFilterComponent}
          />
        )}
        results={
          <DiscoverTabs
            hideEventAlert={true}
            renderResultCard={({ item, selectedId, onChangeSelectedId }) => (
              <DiscoverMandatesResult
                mandate={item}
                selectedFund={selectedFundId}
                selectedMandateId={selectedId}
                onChangeSelectedMandateId={onChangeSelectedId}
                onAddMandateToList={addMandateToListModal.show}
              />
            )}
            renderTableView={({
              data,
              loadPage,
              currentPage,
              total,
              pageSize,
            }) => (
              <DiscoverMandatesTable
                mandates={data}
                loadPage={loadPage}
                currentPage={currentPage}
                total={total}
                selectedFund={selectedFundId}
                pageSize={pageSize}
              />
            )}
          />
        }
        defaultSortLabel={defaultSort}
      />
    </Track>
  )
}

DiscoverMandatesContainer.propTypes = {
  tab: PropTypes.string,
  resultId: PropTypes.number,
  fundId: PropTypes.number,
}

export default DiscoverMandatesContainer
