import find from 'lodash/find'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const pathnameTitles = {
  '^/$': 'Dashboard',
  '^/mandates': 'Mandates',
  '^/mandate/': 'Mandates',
  '^/discover/mandates': 'Mandates',
  '^/presentations/': 'Presentations',
  '^/new-presentation': 'Presentations',
  '^/edit-presentation': 'Presentations',
  '^/meetings': 'Meetings',
  '^/calendar$': 'Calendar',
  '^/meeting/(summit|online|roadshow)/\\d+': 'Meeting Details',
  '^/messages': 'Messages',
  '^/summits/\\d+': 'Summit Details',
  '^/summits$': 'Summits',
  '^/company': 'Company',
  '^/company/\\d+/contacts/\\d+$': 'Company • Colleague',
  '^/roadshows$': 'Roadshows',
  '^/discover/': 'Discover',
  '^/settings': 'Settings',
  '^/serviceproviders': 'Service Providers',
  '^/contextcapital': 'Context Capital Employees',
  '^/media': 'Media',
  '^/portfolio$': 'Portfolio',
  '^/content': 'Content',
  '^/community': 'Community',
  '^/network': 'My Network',
  '^/campaigns': 'My Campaigns',
  '^/workspace': 'My Campaigns',
  '^/edit-fund': 'Edit Fund',
}

const defaultPageTitle = 'Context365'

const findTitleByLocation = (pathname) =>
  find(pathnameTitles, (_, re) => new RegExp(re).test(pathname))

export default function usePageTitle() {
  const { pathname } = useLocation()
  const reduxTitle = useSelector((state) => state.title.title)

  return reduxTitle ?? findTitleByLocation(pathname) ?? defaultPageTitle
}
