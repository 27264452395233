import * as React from 'react'
import { Button } from '@context365/button'
import { FormLabel, RadioGroup, useRadio } from '@context365/forms'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import {
  NextButton,
  OnboardingFooter,
  OnboardingLayout,
} from './OnboardingLayout'

export function SelectCompany({
  companies,
  cfnCompanies,
  skipOnboarding,
  onSelectCompany,
  onSelectCfn,
}) {
  const [selectedCompanyId, setSelectedCompanyId] = React.useState(null)

  React.useEffect(() => {
    if (isEmpty(companies) && isEmpty(cfnCompanies)) {
      skipOnboarding()
    } else if (isEmpty(companies)) {
      onSelectCfn(cfnCompanies[0].companyID)
    } else if (isEmpty(cfnCompanies) && companies.length === 1) {
      onSelectCompany(companies[0].companyID, { replace: true })
    }
  }, [companies, cfnCompanies, skipOnboarding, onSelectCompany, onSelectCfn])

  return (
    <OnboardingLayout
      header={<h1 className="type-header-lg">Welcome to Context365 by Apex</h1>}
      body={
        <>
          <RadioGroup
            name="company"
            className="flex flex-col gap-4 max-w-xs mx-auto mt-12"
            value={selectedCompanyId}
            onChange={(e) => setSelectedCompanyId(e.target.value)}
          >
            <FormLabel as="span" className="type-header-sm text-center mb-4">
              Select a company to begin
            </FormLabel>
            {companies.map((company) => (
              <CompanyOption
                key={company.companyID}
                companyId={company.companyID}
                name={company.name}
                category={company.category}
              />
            ))}
          </RadioGroup>
          {!isEmpty(cfnCompanies) && (
            <div className="text-center mt-8">
              Or,{' '}
              <Button
                variant="link"
                style={{
                  padding: 0,
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  verticalAlign: 'baseline',
                  textDecoration: 'underline',
                }}
                onClick={() => onSelectCfn(cfnCompanies[0].companyID)}
              >
                select a Context Family Network company
              </Button>
            </div>
          )}
        </>
      }
      footer={
        <OnboardingFooter
          rightContent={
            <NextButton
              disabled={selectedCompanyId == null}
              onClick={() => onSelectCompany(selectedCompanyId)}
            >
              Next
            </NextButton>
          }
        />
      }
    />
  )
}

function CompanyOption({ companyId, name, category, ...props }) {
  const { checked, ...radioProps } = useRadio(companyId, props)

  return (
    <div
      className={cx(
        'rounded p-3 border cursor-pointer',
        checked
          ? 'bg-secondary-5 border-secondary-100'
          : 'bg-white hover:bg-secondary-2'
      )}
      {...radioProps}
    >
      <div className="type-body-regular-md">{name}</div>
      <div className="type-body-regular-sm text-grey-600">
        {startCase(category)}
      </div>
    </div>
  )
}
