import { api } from './services'

export const getEntries = async () => {
  const response = await api.get('/company/mandates')
  return response.data.result
}

export const logProfileView = (profile) =>
  // This is just fire-and-forget, so don't display an error to the user
  api.post('/mandate/viewprofile', profile).catch(() => {})

export const toggleHideMandateApplication = (mandateApplicationId) =>
  api.post(`/mandateApplications/${mandateApplicationId}/hide`)

export const getMandateQuestionnaireResponses = (mandateApplicationId) =>
  api
    .get(`/mandateApplications/${mandateApplicationId}/responses`)
    .then((res) => res.data.result)

export const applyToMandate = (mandateId, fundId, message, responses) =>
  api.post(`mandates/${mandateId}/apply`, {
    fundId,
    message,
    responses,
  })

export const getMandateApplicationQuestions = (mandateId) =>
  api
    .get(`mandates/${mandateId}/applicationquestions`)
    .then((res) => res.data.result)

export const getMandateWizard = (mandateId) =>
  api
    .get(`/mandates/wizard${mandateId ? `/${mandateId}` : ''}`)
    .then((res) => res.data.result)

export const saveMandateWizard = (
  mandateId,
  { selectedCommonQuestionIds, selectedCustomQuestions }
) =>
  api.patch(`/mandates/wizard/${mandateId}`, {
    selectedCommonQuestionIds,
    selectedCustomQuestions,
  })
