import * as React from 'react'
import { Badge } from '@context365/badge'
import { Button } from '@context365/button'
import { Input, Radio, RadioGroup } from '@context365/forms'
import {
  LightbulbOutlined,
  PsychologyOutlined,
  SearchOutlined,
  ThumbUpAltOutlined,
} from '@context365/icons'
import { Tooltip } from 'antd'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import find from 'lodash/find'
import head from 'lodash/head'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import moment from 'moment'
import qs from 'qs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import * as api from '~/api'
import CardButton from '~/components/CardButton'
import MediaTypes from '~/constants/mediaTypes'
import { getUserCompanyContactId } from '~/selectors/auth'
import ContactAvatar from './ContactAvatar'
import ContentTemplate from './ContentTemplate'
import Loading from './Loading'
import MediaViewer from './MediaViewer'
import ReactionModal from './ReactionModal'
import './content.less'

const parserOptions = { ignoreQueryPrefix: true }

const formatDate = (date) => moment(date).format('MMMM DD, YYYY')

const reactionImages = {
  Like: <ThumbUpAltOutlined />,
  Insightful: <LightbulbOutlined />,
  Curious: <PsychologyOutlined />,
}

const Filters = ({ contentCategories }) => (
  <div className="flex flex-col gap-4">
    <div className="type-subtitle-md pb-2 border-b border-brand-100">
      Filters
    </div>
    <NavigationBar contentCategories={contentCategories} />
  </div>
)

const Controls = ({ onSearch }) => {
  const { trackEvent } = useTracking({ component: 'Controls' })

  function trackClick(event) {
    trackEvent({ eventName: 'click', element: event.target.textContent })
  }
  return (
    <div className="flex flex-col gap-2 lg:flex-row lg:justify-between">
      <div>
        <Button
          variant="filled"
          as="a"
          href="mailto:help@context365.com?subject=Can you add my content to Context365 by Apex?"
          onClick={trackClick}
        >
          Suggest Content
        </Button>
      </div>
      <div>
        <Input
          className="w-full md:w-96"
          placeholder="Search"
          icon={<SearchOutlined />}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
    </div>
  )
}

const NavigationBar = ({ contentCategories }) => {
  const { trackEvent } = useTracking({ component: 'Category' })

  function trackClick(event) {
    trackEvent({ eventName: 'click', element: event.target.textContent })
  }

  const history = useHistory()
  const location = useLocation()
  const { c } = qs.parse(location.search, parserOptions)

  const filterByCategory = (e) => {
    trackClick(e)

    const oldSearch = qs.parse(location.search, parserOptions)
    const newSearch = qs.stringify({
      ...oldSearch,
      c: e.target.value.toString(),
    })
    history.push({ search: e.target.value === 'all' ? null : newSearch })
  }

  return (
    <RadioGroup
      value={c?.toString() ?? 'all'}
      className="flex flex-col items-start space-y-2"
      name="category"
    >
      <Radio value="all" role="radio" onClick={filterByCategory}>
        All
      </Radio>
      {map(contentCategories, (category) => (
        <Radio
          key={category.contentCategoryId}
          value={category.contentCategoryId.toString()}
          role="radio"
          onClick={filterByCategory}
        >
          {category.name}
        </Radio>
      ))}
    </RadioGroup>
  )
}

const ReactionSelector = ({
  contentEntryId,
  reactionTypes,
  reactionId,
  setReaction,
}) => {
  const { trackEvent } = useTracking({ element: 'Reaction' })

  const trackClick = (reactionType) =>
    trackEvent({ eventName: 'click', reactionType })

  return (
    <>
      {map(reactionTypes, (reactionType) => {
        return (
          <Tooltip title={reactionType.name} key={reactionType.reactionTypeId}>
            <Button
              status="secondary"
              variant="link"
              size="small"
              onClick={() => {
                trackClick(reactionType.name)
                setReaction({
                  contentEntryId,
                  reactionTypeId: reactionType.reactionTypeId,
                })
              }}
            >
              <div
                className={cx(
                  'align-middle pt-1 px-1 -mx-2 rounded-full',
                  reactionType.reactionTypeId === reactionId &&
                    'bg-secondary-100 text-white'
                )}
              >
                {reactionImages[reactionType.name]}
              </div>
            </Button>
          </Tooltip>
        )
      })}
    </>
  )
}

const MediaLink = ({
  children,
  mediaTypeId,
  mediaUrl,
  contentEntryId,
  ...props
}) => {
  const { trackEvent } = useTracking({ element: 'ContentEntry' })

  const trackClick = () => trackEvent({ eventName: 'click', contentEntryId })

  switch (mediaTypeId) {
    case MediaTypes.Website:
      return (
        <a
          href={mediaUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackClick}
          {...props}
        >
          {children}
        </a>
      )
    default:
      return (
        <Link
          to={(location) => ({
            ...location,
            pathname: [location.pathname, mediaTypeId, contentEntryId].join(
              '/'
            ),
          })}
          onClick={trackClick}
          {...props}
        >
          {children}
        </Link>
      )
  }
}

const Entry = ({
  title,
  subtitle,
  description,
  category,
  publishDate,
  author,
  coverImageUrl,
  contentEntryId,
  media,
  userCompanyContactId,
  reactions,
  reactionTypes,
  setReaction,
}) => {
  const { contentReactions } = useFlags()

  const reactionId = React.useMemo(() => {
    return (
      find(reactions, ['companyContactId', userCompanyContactId])
        ?.reactionTypeId ?? undefined
    )
  }, [reactions, userCompanyContactId])

  return (
    <CardButton key={contentEntryId}>
      <div>
        <MediaLink
          mediaTypeId={media.contentEntryMediaTypeId}
          mediaUrl={media.url}
          contentEntryId={contentEntryId}
        >
          <div className="content-entry__cover-image aspect-ratio-16-9">
            <img alt="" src={coverImageUrl} />
          </div>
          <div className="p-4 space-y-4">
            <Badge status="info">{category}</Badge>
            <div>
              <span className="type-body-semibold-md text-secondary-100">
                {title}
              </span>
              <span className="type-body-regular-sm text-black">
                {getMediaTypeDisplay(media)}
              </span>
            </div>
            <div className="type-body-regular-sm text-black">{subtitle}</div>
            <div className="type-body-regular-xs text-black">{description}</div>
            {author && (
              <ContactAvatar
                contactName={author.contactName}
                jobTitle={author.contactJobTitle}
                imageUrl={author.contactImageUrl}
              />
            )}
            <div className="type-body-regular-xs text-black">
              {formatDate(publishDate)}
            </div>
          </div>
        </MediaLink>
        {contentReactions && (
          <div className="flex justify-between">
            <div className="flex">
              <ReactionSelector
                contentEntryId={contentEntryId}
                reactionTypes={reactionTypes}
                reactionId={reactionId}
                setReaction={setReaction}
              />
            </div>
            <div className="align-middle">
              <ReactionModal
                reactions={reactions}
                contentEntryId={contentEntryId}
              />
            </div>
          </div>
        )}
      </div>
    </CardButton>
  )
}

function useContentData(companyContactId) {
  const client = useQueryClient()

  const { mutate: setReaction } = useMutation(
    ({ contentEntryId, reactionTypeId }) =>
      api.content.toggleReaction(contentEntryId, reactionTypeId),
    {
      onMutate: async ({ contentEntryId, reactionTypeId }) => {
        await client.cancelQueries('contentEntries')
        const previousEntries = client.getQueryData('contentEntries')

        client.setQueryData('contentEntries', (entries) =>
          map(entries, (entry) =>
            entry.contentEntryId === contentEntryId
              ? {
                  ...entry,
                  reactions: map(entry.reactions, (reaction) =>
                    reaction.companyContactId === companyContactId
                      ? {
                          ...reaction,
                          reactionTypeId,
                        }
                      : reaction
                  ),
                }
              : entry
          )
        )

        return { previousEntries }
      },
      onError: (err, data, context) => {
        client.setQueryData('contentEntries', context.previousEntries)
      },
      onSettled: () => {
        client.invalidateQueries('contentEntries')
      },
    }
  )

  const { data: contentEntries, isLoading: loadingContentEntries } = useQuery(
    'contentEntries',
    () => api.content.getEntries(),
    {
      select: (data) => orderBy(data, 'publishDateTimeUtc', 'desc'),
    }
  )

  const { data: contentCategories, isLoading: loadingContentCategories } =
    useQuery('contentCategories', () => api.content.getCategories())

  const { data: reactionTypes } = useQuery('reactionTypes', () =>
    api.content.getReactionTypes()
  )

  return {
    contentEntries,
    contentCategories,
    reactionTypes,
    loading: loadingContentEntries || loadingContentCategories,
    setReaction,
  }
}

function getMediaTypeDisplay(media) {
  switch (media.contentEntryMediaTypeId) {
    case MediaTypes.Website:
      return ' (Website)'
    case MediaTypes.Video:
      return ' (Video)'
    case MediaTypes.PDF:
      return ' (PDF)'
    default:
      return undefined
  }
}

function getCategoryLabel(entry) {
  return head(entry.contentCategories)?.name
}

const Content = () => {
  const location = useLocation()
  const history = useHistory()

  const { communityModule } = useFlags()

  const match = useRouteMatch(
    communityModule
      ? '/community/content/:mediaTypeId/:contentEntryId'
      : '/content/:mediaTypeId/:contentEntryId'
  )
  const {
    contentEntries,
    contentCategories,
    reactionTypes,
    loading,
    setReaction,
  } = useContentData()
  const { Track, trackEvent } = useTracking({ page: 'Content' })
  const [searchText, setSearchText] = React.useState()
  const userCompanyContactId = useSelector(getUserCompanyContactId)

  const contentEntriesById = React.useMemo(
    () =>
      reduce(
        contentEntries,
        (allEntries, entry) => {
          allEntries[entry.contentEntryId] = entry
          return allEntries
        },
        {}
      ),
    [contentEntries]
  )

  const { c: selectedCategoryId } = qs.parse(location.search, parserOptions)

  const visibleEntries = selectedCategoryId
    ? filter(contentEntries, (e) =>
        e.contentCategories.some(
          // eslint-disable-next-line eqeqeq -- string coercion intended
          (c) => c.contentCategoryId == selectedCategoryId
        )
      )
    : contentEntries

  const entries = searchText
    ? filter(
        visibleEntries,
        (e) =>
          e.title?.toLowerCase().includes(searchText) ||
          e.subtitle?.toLowerCase().includes(searchText) ||
          e.description?.toLowerCase().includes(searchText)
      )
    : visibleEntries

  if (loading) {
    return <Loading />
  }

  return (
    <Track>
      <ContentTemplate
        filters={<Filters contentCategories={contentCategories} />}
        controls={
          <Controls
            onSearch={(searchText) => {
              trackEvent({ eventName: 'change', element: 'search', searchText })
              setSearchText(searchText?.toLowerCase())
            }}
          />
        }
        entries={map(entries, (entry) => (
          <Entry
            key={entry.contentEntryId}
            title={entry.title}
            subtitle={entry.subtitle}
            description={entry.description}
            category={getCategoryLabel(entry)}
            publishDate={entry.publishDateTimeUtc}
            author={entry.authorContact}
            coverImageUrl={entry.coverImageUrl}
            media={entry.media}
            contentEntryId={entry.contentEntryId}
            reactionTypes={reactionTypes}
            reactions={entry.reactions}
            userCompanyContactId={userCompanyContactId}
            setReaction={setReaction}
          />
        ))}
      />
      <MediaViewer
        mediaTypeId={match?.params.mediaTypeId}
        contentEntry={contentEntriesById[match?.params.contentEntryId]}
        onClose={() => history.goBack()}
      />
    </Track>
  )
}

export default Content
