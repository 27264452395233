import * as React from 'react'
import { IconButton } from '@context365/button'
import {
  CalendarTodayOutlined,
  ChevronRight,
  HomeOutlined,
  MeetingRoomOutlined,
  Menu as MenuIcon,
  NotificationsOutlined,
  QuestionAnswerOutlined,
} from '@context365/icons'
import { Menu } from '@context365/menu'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { fetchCompanyIfNeeded } from '~/actions/company'
import POLICIES from '~/constants/policies'
import usePageTitle from '~/hooks/usePageTitle'
import useNavItems from '~/lib/useNavItems'
import { getTier } from '~/selectors/auth'
import NotificationListContainer from '~/views/NotificationListContainer'
import CompanySwitcher from '../CompanySwitcher'
import TopbarLink from '../NavUtils/TopbarLink'
import UnreadMessages from '../UnreadMessages'

const TopBar = () => {
  const title = usePageTitle()
  const { role } = useSelector((state) => state.auth)
  const tier = useSelector(getTier)
  const company = useSelector((state) => state.auth.company)
  const dispatch = useDispatch()
  const { trackEvent } = useTracking({ element: 'Topbar' })
  const { mobileMessages, campaignsModule } = useFlags()

  React.useEffect(() => {
    if (
      title === 'Settings' &&
      (role.toLowerCase() === 'allocator' || role.toLowerCase() === 'manager')
    ) {
      dispatch(fetchCompanyIfNeeded(company.companyId))
    }
  }, [company.companyId, dispatch, role, title])

  const navItems = useNavItems()
  const showAllItems = role.toLowerCase() !== 'guest presentation'
  const trackClick = (label) => trackEvent({ eventName: 'click', label })

  return (
    <div className="flex bg-white border-b border-grey-100 p-4 md:px-8 items-center space-x-8 z-10">
      <div className="flex-1 flex items-center">
        <div className="md:hidden mr-4">
          <Menu
            trigger={
              <IconButton
                icon={<MenuIcon />}
                label="Menu"
                variant="filled"
                onClick={() => trackClick('MobileMenu')}
              />
            }
          >
            <NavMenuItem
              label="Home"
              icon={<HomeOutlined />}
              path="/"
              onClick={() => trackClick('Home')}
            />
            {navItems
              .filter((navItem) => navItem.visibleOnMobile)
              .map((navItem) => (
                <NavMenuItem
                  key={navItem.path}
                  {...navItem}
                  onClick={() => trackClick(navItem.label)}
                />
              ))}
            {showAllItems && !campaignsModule && (
              <NavMenuItem
                label="Meeting Lobby"
                icon={<MeetingRoomOutlined />}
                path="/meeting-lobby"
                onClick={() => trackClick('MeetingLobby')}
              />
            )}
            {showAllItems && (
              <NavMenuItem
                label="Calendar"
                icon={<CalendarTodayOutlined />}
                path="/meetings/calendar"
                onClick={() => trackClick('Calendar')}
              />
            )}
            {mobileMessages && showAllItems && (
              <NavMenuItem
                label="Messages"
                icon={<QuestionAnswerOutlined />}
                path="/messages"
                onClick={() => trackClick('Messages')}
              />
            )}
            {showAllItems && (
              <NavMenuItem
                label="Notifications"
                icon={<NotificationsOutlined />}
                path="/notifications"
                onClick={() => trackClick('Notifications')}
              />
            )}
            <Menu.Submenu
              label={
                <div className="w-full flex justify-between items-center">
                  Privacy & Terms
                  <ChevronRight />
                </div>
              }
            >
              <Menu.Item
                as="a"
                href={POLICIES.PRIVACY_POLICY}
                target="_blank"
                onClick={() => trackClick('PrivacyPolicy')}
              >
                Privacy Policy
              </Menu.Item>
              <Menu.Item
                as="a"
                href={POLICIES.TERMS_OF_USE}
                target="_blank"
                onClick={() => trackClick('TermsOfUse')}
              >
                Terms of Use
              </Menu.Item>
              <Menu.Item
                as="a"
                href={POLICIES.TERMS_AND_CONDITIONS}
                target="_blank"
                onClick={() => {
                  trackClick('TermsAndConditions')
                }}
              >
                Terms & Conditions
              </Menu.Item>
              <Menu.Item
                as="a"
                href={POLICIES.SUBSCRIPTION_AGREEMENT}
                target="_blank"
                onClick={() => trackClick('SubscriptionAgreement')}
              >
                Subscription & Service Agreement
              </Menu.Item>
            </Menu.Submenu>
          </Menu>
        </div>
        <div className="type-header-md">{title}</div>
      </div>
      <div className="hidden md:flex space-x-8">
        {showAllItems && (
          <>
            {!campaignsModule && (
              <TopbarLink
                icon={<MeetingRoomOutlined />}
                label="Meeting Lobby"
                to="/meeting-lobby"
              />
            )}
            <TopbarLink
              icon={<CalendarTodayOutlined />}
              label="Calendar & Meetings"
              to="/meetings/calendar"
            />
            <TopbarLink
              icon={<QuestionAnswerOutlined />}
              label="Messages"
              to="/messages"
              badge={<UnreadMessages />}
            />

            <NotificationListContainer />
          </>
        )}
      </div>
      <div>
        <CompanySwitcher tier={tier} />
      </div>
    </div>
  )
}

export default TopBar

function NavMenuItem({ label, path, icon, beta, blocked, onClick }) {
  return (
    <Menu.Item
      key={label}
      as={Link}
      to={path}
      disabled={blocked}
      onClick={onClick}
    >
      <span className="flex items-center gap-2">
        {icon && React.cloneElement(icon, { size: 18 })}
        <span>
          {label}
          {beta && ' (beta)'}
        </span>
      </span>
    </Menu.Item>
  )
}
