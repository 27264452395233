import * as React from 'react'
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Approval, BackHand, ExpandMore } from '@context365/icons'
import { Menu } from '@context365/menu'
import { useFlags } from 'launchdarkly-react-client-sdk'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import some from 'lodash/some'
import { useTracking } from 'react-tracking'
import { ApplyToMandateModal } from '../Application'
import ComposeMessageModal from '../ComposeMessageModal'
import DiscoverTable from '../DiscoverTable/DiscoverTable'
import {
  Applications,
  BroadStrategy,
  Contacts,
  CurrentAssetsRange,
  Description,
  MandateSize,
  MandateType,
  Name,
  NarrowStrategy,
  TargetCompletionDate,
  Updates,
} from './DiscoverMandatesColumns'

const DiscoverMandatesTable = ({
  mandates,
  loadPage,
  currentPage,
  total,
  selectedFund,
  pageSize,
}) => {
  const { campaignsModule } = useFlags()
  const { Track, trackEvent } = useTracking({ page: 'MandateTable' })
  const [currentMandate, setCurrentMandate] = useState()
  const [isApplyToMandateModalVisible, setIsApplyToMandateModalVisible] =
    useState(false)
  const [isAskAQuestionModalvisible, setIsAskAQuestionModalvisible] =
    useState(false)

  const showApplyToMandateModal = React.useCallback(() => {
    trackEvent({
      eventName: 'click',
      element: 'Mandate open apply modal from profile',
    })
    setIsApplyToMandateModalVisible(true)
  }, [trackEvent])

  const hideApplyToMandateModal = React.useCallback(() => {
    setIsApplyToMandateModalVisible(false)
  }, [])

  const showAskAQuestionModal = React.useCallback(() => {
    setIsAskAQuestionModalvisible(true)
  }, [])

  const hideAskAQuestionModal = React.useCallback(() => {
    setIsAskAQuestionModalvisible(false)
  }, [])

  const actionsMenu = {
    id: 'Actions',
    Header: '',
    accessor: (mandate) => {
      return (
        <Menu
          trigger={
            <Button variant="filled" size="small" iconRight={<ExpandMore />}>
              Actions
            </Button>
          }
        >
          {mandate.meetsApplicationRequirements &&
            !mandate.isClosed &&
            !isEmpty(mandate.contacts) && (
              <Menu.Item
                disabled={
                  (selectedFund &&
                    some(
                      mandate.mandateApplications,
                      (ma) => ma.fundId === selectedFund
                    )) ||
                  (!selectedFund && mandate.mandateApplications?.length > 0)
                }
                onClick={() => {
                  setCurrentMandate(mandate)
                  showApplyToMandateModal()
                }}
              >
                <Approval className="mr-2" />
                Apply to Mandate
              </Menu.Item>
            )}
          {!mandate.isAnonymous &&
            !isEmpty(mandate.contacts) &&
            !campaignsModule && (
              <Menu.Item
                onClick={() => {
                  setCurrentMandate(mandate)
                  showAskAQuestionModal()
                }}
              >
                <BackHand className="mr-2" />
                Ask a Question
              </Menu.Item>
            )}
        </Menu>
      )
    },
  }

  const mandatesWithSelectedApplications = map(mandates, (m) => ({
    ...m,
    mandateApplications: filter(
      m.mandateApplications,
      (m) => (selectedFund && m.fundId === selectedFund) || !selectedFund
    ),
  }))

  const columns = useMemo(
    () => [
      Name,
      Description,
      Applications,
      actionsMenu,
      Contacts,
      MandateSize,
      MandateType,
      CurrentAssetsRange,
      TargetCompletionDate,
      BroadStrategy,
      NarrowStrategy,
      Updates,
    ],
    [actionsMenu]
  )

  return (
    <Track>
      <DiscoverTable
        columns={columns}
        data={mandatesWithSelectedApplications}
        currentPage={currentPage}
        total={total}
        loadPage={loadPage}
        tableTitle="DiscoverMandatesTable"
        useColumnCustomization={true}
        pageSize={pageSize}
      />
      {isApplyToMandateModalVisible && (
        <ApplyToMandateModal
          visible
          onClose={hideApplyToMandateModal}
          mandateId={currentMandate.mandateId}
          fundId={selectedFund}
          mandate={currentMandate}
        />
      )}
      {isAskAQuestionModalvisible && (
        <ComposeMessageModal
          visible
          onClose={hideAskAQuestionModal}
          companyID={currentMandate.companyId}
          selectAll
          showContactsAttendingEvent={false}
          fromMandateId={currentMandate.mandateId}
        />
      )}
    </Track>
  )
}

DiscoverMandatesTable.propTypes = {
  mandates: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  loadPage: PropTypes.func.isRequired,
  selectedFund: PropTypes.number,
  pageSize: PropTypes.number,
}

export default DiscoverMandatesTable
