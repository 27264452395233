import {
  ArticleOutlined,
  ExploreOutlined,
  FolderOutlined,
  GroupsOutlined,
  MicOutlined,
  PublicOutlined,
  Speed,
  SubscriptionsOutlined,
} from '@context365/icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { ROLE } from '~/constants/roles'
import useAuth from '~/hooks/useAuth'
import { getTier } from '~/selectors/auth'

const isDiscoverBlocked = ({ tier, role, contact }) =>
  ['Standard'].includes(tier) || (role === ROLE.CFN && !contact.isCFNAllocator)

const userNavItems = [
  {
    label: 'Dashboard',
    path: '/',
    exact: true,
    icon: <Speed />,
  },
  {
    label: 'Discover',
    path: '/discover',
    icon: <ExploreOutlined />,
    isActive: (match, location) => {
      if (!match) return false
      return !location.pathname.startsWith('/discover/mandates')
    },
    blocked: isDiscoverBlocked,
    visible: ({ flags }) => flags.campaignsModule,
    visibleOnMobile: ({ flags }) => flags.mobileDiscover,
  },
  {
    label: 'Discover',
    path: '/discover/funds',
    icon: <ExploreOutlined />,
    blocked: isDiscoverBlocked,
    visible: ({ flags, role }) =>
      role === ROLE.ALLOCATOR && !flags.campaignsModule,
    visibleOnMobile: ({ flags }) => flags.mobileDiscover,
  },
  {
    label: 'Discover',
    path: '/discover/allocators',
    icon: <ExploreOutlined />,
    blocked: isDiscoverBlocked,
    visible: ({ flags, role }) =>
      role !== ROLE.ALLOCATOR && !flags.campaignsModule,
    visibleOnMobile: ({ flags }) =>
      flags.mobileDiscover && !flags.campaignsModule,
  },
  {
    label: 'Mandates',
    path: '/mandates?filter=isAnonymous%3Dtrue%2Cfalse&filter=status%3DOpen%2CPotential',
    icon: <ArticleOutlined />,
    visible: ({ role, flags }) =>
      role !== ROLE.CONTEXT && flags.showMandatesMenu && !flags.campaignsModule,
    visibleOnMobile: ({ flags }) =>
      flags.mobileDiscover && !flags.campaignsModule,
  },
  {
    label: 'My Campaigns',
    path: '/campaigns',
    icon: <FolderOutlined />,
    visible: ({ flags }) => flags.campaignsModule,
    visibleOnMobile: ({ flags }) => flags.campaignsModule,
  },
  {
    label: 'Community',
    path: '/community',
    icon: <GroupsOutlined />,
    visible: ({ flags }) => flags.communityModule,
    visibleOnMobile: ({ flags }) => flags.communityModule,
  },
  {
    label: 'Content',
    path: '/content',
    icon: <SubscriptionsOutlined />,
    visible: ({ flags }) => !flags.communityModule,
    visibleOnMobile: () => true,
  },
  {
    label: 'Summits',
    path: '/summits',
    icon: <PublicOutlined />,
    visibleOnMobile: ({ flags }) => flags.mobileSummits,
  },
]

const guestNavItems = [
  {
    label: 'Presentations',
    path: '/community/presentations',
    icon: <MicOutlined />,
    visible: ({ flags }) => flags.showNewEvent,
  },
]

export default function useNavItems() {
  const tier = useSelector(getTier)
  const { role, contact } = useAuth()
  const flags = useFlags()

  const options = { tier, role, contact, flags }

  const navItems = role === 'Guest Presentation' ? guestNavItems : userNavItems
  return navItems
    .filter(({ visible }) => !visible || (!isEmpty(flags) && visible(options)))
    .map(({ blocked, visibleOnMobile, ...navItem }) => ({
      ...navItem,
      blocked: blocked?.(options),
      visibleOnMobile: visibleOnMobile?.(options),
    }))
}
