import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import CenteredPrimaryRadioButtons from '~/components/CenteredPrimaryRadioButtons'
import ToggleMeetingRequests from '~/components/ToggleMeetingRequests'
import SummitAttendeesCtx from '~/utils/contexts/SummitAttendeesCtx'
import SchedulerNotOpen from '../SchedulerNotOpen'
import SummitAttendeeList from './SummitAttendeeList'
import './AttendeeList.less'

const { TabPane } = Tabs

const BUTTONS = [
  {
    value: 'allocators',
    text: 'allocators',
  },
  {
    value: 'funds',
    text: 'funds',
  },
  {
    value: 'service-providers',
    text: 'service providers',
  },
]

const AttendeeList = ({
  eventId,
  companyEventId,
  isOptimized,
  role,
  schedulerAccessDate,
  creditsAvailable,
  contextPoints,
  attendeeAccess,
  subtab = '',
  onRefresh,
  confirmedMeetings,
  confirmedMeetingLimit,
  disabledMeetingRequest,
  boothSP = [],
}) => {
  const isFundManager = role.toLowerCase() === 'manager'
  const noAccessForManagersOnly = isFundManager && !attendeeAccess
  const [activeTab, setActiveTab] = useState(
    subtab ? subtab : isFundManager ? 'allocators' : 'funds'
  ) // active tab key should corresp//ond to active radio button on CenteredPrimaryRadioButtons
  const { push } = useHistory()

  const radioHandler = (e) => {
    setActiveTab(e.target.value)
    push(`/summits/${eventId}/attendees/${e.target.value}`)
  }

  const isSchedulerOpen = useMemo(
    () =>
      moment(schedulerAccessDate).isValid() &&
      moment.utc(schedulerAccessDate).isBefore(moment().utc()),
    [schedulerAccessDate]
  )

  const buttons = useMemo(
    () =>
      filter(BUTTONS, (button) => {
        if (role === 'Allocator' && button.text === 'allocators') {
          return false
        } else if (role === 'Manager' && button.text === 'funds') {
          return false
        } else if (
          role === 'Service Provider' &&
          button.text === 'service providers'
        ) {
          return false
        }
        return true
      }),
    [role]
  )

  const getAttendeesTabs = () => {
    const getScheduleAccess = () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SchedulerNotOpen
          noAccessForManagers={noAccessForManagersOnly}
          schedulerAccessDate={schedulerAccessDate}
          companyEventId={companyEventId}
          eventId={eventId}
          onRefresh={onRefresh}
        />
      </div>
    )
    return map(buttons, (b) => (
      <TabPane tab={startCase(b.text)} key={b.value}>
        {isSchedulerOpen && attendeeAccess ? (
          <SummitAttendeesCtx.Provider
            value={{
              creditsAvailable,
              contextPoints,
              category: b.value,
              boothSP,
            }}
          >
            <SummitAttendeeList
              isOptimized={isOptimized}
              eventId={eventId}
              companyEventId={companyEventId}
              creditsAvailable={creditsAvailable}
              title={startCase(b.text)}
              contextPoints={contextPoints}
              category={b.value}
            />
          </SummitAttendeesCtx.Provider>
        ) : (
          getScheduleAccess()
        )}
      </TabPane>
    ))
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          width: '100%',
        }}
      >
        <div style={{ width: '95%' }}>
          <CenteredPrimaryRadioButtons
            selectedKey={activeTab}
            buttons={buttons}
            OnClickFunction={radioHandler}
          />
        </div>
        <div style={{ width: '5%', textAlign: 'right' }}>
          {!isNil(confirmedMeetingLimit) &&
            !isNil(confirmedMeetings) &&
            !isNil(eventId) &&
            eventId > 0 && (
              <ToggleMeetingRequests
                confirmedMeetingLimit={confirmedMeetingLimit}
                confirmedMeetings={confirmedMeetings}
                disabledMeetingRequest={disabledMeetingRequest}
                eventId={eventId}
                placement="left"
              />
            )}
        </div>
      </div>
      <Tabs
        animated={false}
        tabBarStyle={{ display: 'none' }}
        activeKey={activeTab}
      >
        {getAttendeesTabs()}
      </Tabs>
    </>
  )
}

AttendeeList.propTypes = {
  eventId: PropTypes.number.isRequired,
  companyEventId: PropTypes.number.isRequired,
  isOptimized: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  schedulerAccessDate: PropTypes.instanceOf(Date),
  creditsAvailable: PropTypes.number.isRequired,
  contextPoints: PropTypes.number.isRequired,
  attendeeAccess: PropTypes.bool.isRequired,
  subtab: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
  confirmedMeetingLimit: PropTypes.number,
  confirmedMeetings: PropTypes.number,
  disabledMeetingRequest: PropTypes.bool,
  boothSP: PropTypes.array,
}

export default AttendeeList
