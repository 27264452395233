import isNil from 'lodash/isNil'
import { Link } from 'react-router-dom'
import AggregatedRangeFilter from './Controls/AggregatedRangeFilter'
import BooleanFilter from './Controls/BooleanFilter'
import CheckboxFilter from './Controls/CheckboxFilter'
import CheckboxGroupFilter from './Controls/CheckboxGroupFilter'
import ContextSignalFilter from './Controls/ContextSignalFilter'
import DateRangeFilter from './Controls/DateRangeFilter'
import RangeFilter from './Controls/RangeFilter'
import SliderFilter from './Controls/SliderFilter'

export default function getFilterComponent({
  item,
  canEditInvestorPreferences,
  header,
  ...props
}) {
  switch (item.questionType) {
    case 'dropdown':
    case 'dropdownDiscoverLists':
    case 'dropdownLiquid':
    case 'dropdownIlliquid':
    case 'dropdownCustom':
      return (
        <div id={isNil(item.field) ? 'dropdown' : `${item.field}_dropdown`}>
          {header}
          <CheckboxGroupFilter
            className="DiscoverFilters-FilterCheckbox--stacked"
            options={item.values}
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            showClearAll
            {...props}
          />
        </div>
      )
    case 'range':
      return (
        <div>
          {header}
          <RangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) =>
              value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            group={item.questionGroup}
            percentage={false}
            {...props}
          />
        </div>
      )
    case 'aggregatedRange':
      return (
        <div>
          {header}
          <AggregatedRangeFilter
            id={item.field.split(',')}
            label={item.name}
            formatter={(value) =>
              value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            group={item.questionGroup}
            percentage={false}
            {...props}
          />
        </div>
      )
    case 'percentageRange':
      return (
        <div>
          {header}
          <RangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            group={item.questionGroup}
            percentage={true}
            {...props}
          />
        </div>
      )
    case 'matchmakingRange':
      return (
        <div>
          {header}
          <div className="type-body-regular-xs">
            The Matchmaking Score is calculated based on your profile
            information.{' '}
            {canEditInvestorPreferences && (
              <span>
                {' '}
                If your interests have changed, please update your{' '}
                <Link to="/settings/preferences">preferences</Link>.
              </span>
            )}
          </div>
          <SliderFilter
            id={item.field}
            label={item.name}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            group={item.questionGroup}
            defaultMin={item.minValue}
            defaultMax={item.maxValue}
            percentage={true}
            marks={{ 0: '0', 100: '100' }}
            {...props}
          />
        </div>
      )
    case 'date':
      return (
        <div>
          {header}
          <DateRangeFilter
            id={item.field}
            label={item.name}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            group={item.questionGroup}
            {...props}
          />
        </div>
      )
    case 'bool':
      return (
        <div>
          {header}
          <BooleanFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        </div>
      )
    case 'checkbox':
      if (
        item.questionGroup === 'Context Signal' ||
        item.questionGroup === 'Trending'
      ) {
        return (
          <ContextSignalFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      } else {
        return (
          <CheckboxFilter
            id={item.field}
            label={item.name}
            group={item.questionGroup}
            {...props}
          />
        )
      }
    default:
      return null
  }
}
