import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  Exposure,
  Icon,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTracking } from 'react-tracking'

const DiscoverServiceProviderResultFooter = ({
  fromMandate,
  serviceProvider,
  onToggleHidden,
  onSendMessage,
  onSendMeetingRequest,
  onShowAddToListModal,
}) => {
  const { trackEvent } = useTracking()
  const { campaignsModule } = useFlags()

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row justify-start space-x-2">
        <Button
          className="color-secondary-90"
          status="secondary"
          iconLeft={<Exposure />}
          onClick={() => onShowAddToListModal()}
        >
          Lists
        </Button>
      </div>
      {fromMandate && (
        <Tooltip
          text={
            serviceProvider.isHiddenForMandate
              ? 'Add application to the list'
              : 'Hide application from the list. This applicant will not be notified when you hide their application.'
          }
        >
          <IconButton
            status="secondary"
            icon={
              <Icon
                as={
                  serviceProvider.isHiddenForMandate
                    ? ThumbUpOutlined
                    : ThumbDownOutlined
                }
              />
            }
            label={
              serviceProvider.isHiddenForMandate
                ? 'Unhide application'
                : 'Hide application'
            }
            onClick={() => {
              const mandateApplicationId =
                serviceProvider.mandateApplications[0].mandateApplicationId
              trackEvent({
                eventName: 'click',
                element: 'Hide Service Provider',
                serviceProviderId: serviceProvider.companyId,
                mandateApplicationId,
              })
              onToggleHidden(mandateApplicationId)
            }}
          />
        </Tooltip>
      )}
      {!campaignsModule && (
        <div className="flex flex-row justify-start space-x-2">
          <Button status="secondary" onClick={() => onSendMessage()}>
            Send Message
          </Button>
          <Button variant="filled" onClick={() => onSendMeetingRequest()}>
            Send meeting request
          </Button>
        </div>
      )}
    </div>
  )
}

DiscoverServiceProviderResultFooter.propTypes = {
  fromMandate: PropTypes.bool.isRequired,
  serviceProvider: PropTypes.shape({
    companyId: PropTypes.number.isRequired,
    isHiddenForMandate: PropTypes.bool,
    mandateApplications: PropTypes.arrayOf(
      PropTypes.shape({
        mandateApplicationId: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onToggleHidden: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onSendMeetingRequest: PropTypes.func.isRequired,
  onShowAddToListModal: PropTypes.func.isRequired,
}

export default DiscoverServiceProviderResultFooter
