import * as React from 'react'
import * as Sentry from '@sentry/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom-v5-compat'
import { changeCompany } from './actions/auth'
import { CurrentBreakpoint } from './components/CurrentBreakpoint'
import ErrorBoundary from './components/ErrorBoundary'
import GlobalModals from './components/GlobalModals'
import Loading from './components/Loading'
import { FeatureFlags } from './config'
import useAuth from './hooks/useAuth'
import useIntercom from './hooks/useIntercom'
import useSearchParams from './hooks/useSearchParams'
import { isAuthenticated } from './selectors/auth'
import { initializeAnalytics, trackLogin } from './utils/analytics'
import { useErrorReporting } from './utils/errorReporting'
import { loadFlagsIdentity } from './utils/storage'
import AppRoutes from './views'
// eslint-disable-next-line import/no-extraneous-dependencies -- simplebar is included by simplebar-react
import 'simplebar/dist/simplebar.min.css'

const intercomPaths = ['', 'summits', 'settings']

const App = () => {
  const { pathname } = useLocation()
  const { contact, role } = useAuth()
  const [switchingCompany, setSwitchingCompany] = React.useState(true)
  const { company } = useAuth()
  const location = useLocation()
  const { show, hide } = useIntercom()

  React.useEffect(() => {
    const splitPath = location.pathname.split('/')
    if (intercomPaths.includes(splitPath[1])) {
      show()
    } else {
      hide()
    }
  }, [location, show, hide])

  useAppTracking()

  useErrorReporting(contact?.email)

  useCompanySwitch(setSwitchingCompany, company)

  const redirectPath = getRedirectPath({ pathname, contact, role })
  if (redirectPath) {
    return <Navigate to={redirectPath} replace />
  }

  if (switchingCompany && !isNil(company?.companyId)) {
    return <LoggingIn />
  }

  return (
    <ErrorBoundary>
      <AppRoutes />
      <GlobalModals />
      <CurrentBreakpoint />
    </ErrorBoundary>
  )
}

const _ProfiledApp = Sentry.withProfiler(App)

const _App = FeatureFlags.enabled
  ? withLDProvider({
      clientSideID: FeatureFlags.clientSideId,
      user: loadFlagsIdentity(),
    })(_ProfiledApp)
  : _ProfiledApp

export default _App

const onboardingLink = '/onboard'
const agreementLink = '/agreement'
const presentationLink = '/community/presentations'
function getRedirectPath({ pathname, contact, role }) {
  if (pathname === '/sso') {
    return undefined
  }

  if (
    !isNil(contact) &&
    contact.hasOnBoarded === false &&
    contact.subscriptionAgreementApproved &&
    !pathname.includes(onboardingLink)
  ) {
    return onboardingLink
  } else if (
    !isNil(contact) &&
    contact.subscriptionAgreementApproved === false &&
    !pathname.includes(agreementLink)
  ) {
    return agreementLink
  }
  if (role === 'Guest Presentation' && pathname === '/') {
    return presentationLink
  }

  return undefined
}

function useAppTracking() {
  const { contact, company, role, claims } = useAuth()
  const impersonation = claims?.impersonation != null
  const authenticated = useSelector(isAuthenticated)

  React.useLayoutEffect(() => {
    if (!authenticated) {
      return
    }
    initializeAnalytics(contact, company, role, impersonation)
  }, [authenticated, contact, company, role, impersonation])

  React.useLayoutEffect(() => {
    if (!authenticated) {
      return
    }
    trackLogin(contact, company, role)
  }, [authenticated, company, contact, role])
}

function useCompanySwitch(setSwitchingCompany, company) {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const userAuthenticated = useSelector(isAuthenticated)
  const { replace } = useHistory()
  const location = useLocation()

  React.useLayoutEffect(() => {
    if (isEmpty(searchParams.switchToCompany)) {
      setSwitchingCompany(false)
    } else if (company?.companyId.toString() === searchParams.switchToCompany) {
      setSearchParams({ ...searchParams, switchToCompany: null })
      setSwitchingCompany(false)
    } else if (userAuthenticated) {
      dispatch(changeCompany(searchParams.switchToCompany)).then(() => {
        setSwitchingCompany(false)
        setSearchParams({ ...searchParams, switchToCompany: null })
      })
    } else {
      setSwitchingCompany(false)
      replace(
        {
          pathname: '/login',
          search: location.search,
        },
        location.pathname + location.search
      )
    }
  }, [
    company?.companyId,
    dispatch,
    searchParams,
    setSearchParams,
    setSwitchingCompany,
    userAuthenticated,
    replace,
    location.search,
    location.pathname,
  ])
}

function LoggingIn() {
  return (
    <div className="flex items-center justify-center h-screen w-screen gap-6">
      <Loading />
      <div>
        <h1>Logging in...</h1>
        <p>Please wait while we log you in.</p>
      </div>
    </div>
  )
}
