import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  CheckCircleOutline,
  CommentOutlined,
  CompareArrows,
  EmailOutlined,
  Exposure,
  FolderOutlined,
  OpenInNew,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from '@context365/icons'
import { Placement, Tooltip } from '@context365/popovers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { ApplyToCampaignButton, ViewApplicationButton } from '../Application'
import FundSubscriptionModal from './FundSubscriptionModal'

const DiscoverFundResultFooter = ({
  fromMandate,
  fromPreview = false,
  fund,
  role,
  isSelectedForCompare,
  onSendMeetingRequest,
  onSendMessage,
  onShowAddToListModal,
  onShowDetailsModal,
  onToggleHideFund,
  onToggleCompare,
}) => {
  const { trackEvent } = useTracking()
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const email = useSelector((state) => state.auth.contact?.email)
  const { campaignsModule } = useFlags()

  function toggleCompare() {
    trackEvent({
      eventName: 'click',
      element: 'Compare',
      fundId: fund.fundId,
      action: isSelectedForCompare ? 'remove' : 'add',
    })
    onToggleCompare()
  }

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row justify-start space-x-2">
        {!fromPreview && (
          <Button
            status="secondary"
            iconLeft={<Exposure />}
            onClick={() => onShowAddToListModal()}
          >
            Lists
          </Button>
        )}
        {!fromMandate && !fromPreview && (
          <Button
            status={isSelectedForCompare ? 'success' : 'secondary'}
            iconLeft={
              isSelectedForCompare ? <CheckCircleOutline /> : <CompareArrows />
            }
            onClick={toggleCompare}
          >
            Compare
          </Button>
        )}
        {fromMandate && fund.mandateApplications?.[0]?.message && (
          <Tooltip
            text={fund.mandateApplications[0].message}
            placement={Placement.BottomStart}
          >
            <div>
              <CommentOutlined className="relative top-2 mr-1" />
              View message
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex flex-row space-x-2">
        {fromMandate && (
          <Tooltip
            text={
              fund.isHiddenForMandate
                ? 'Add application to the results'
                : 'Hide application from the results. This applicant will not be notified when you hide their application.'
            }
          >
            <IconButton
              variant="link"
              icon={
                fund.isHiddenForMandate ? (
                  <ThumbUpOutlined />
                ) : (
                  <ThumbDownOutlined />
                )
              }
              label={fund.isHiddenForMandate ? 'Unhide fund' : 'Hide fund'}
              onClick={() => {
                trackEvent({
                  eventName: 'click',
                  element: 'Hide Fund',
                  fundId: fund.fundId,
                  mandateApplicationId:
                    fund.mandateApplications[0].mandateApplicationId,
                })
                onToggleHideFund(
                  fund.mandateApplications[0].mandateApplicationId
                )
              }}
            />
          </Tooltip>
        )}
        {!fromPreview && (
          <>
            <Tooltip
              text={
                fund.alreadySubscribed
                  ? 'You are already subscribed to this fund'
                  : 'Subscribe to this fund manager’s email distribution list'
              }
            >
              <div>
                <Button
                  disabled={fund.alreadySubscribed}
                  iconLeft={<EmailOutlined />}
                  onClick={() => {
                    trackEvent({
                      eventName: 'click',
                      element: 'Subscribe to fund',
                      fundId: fund.fundId,
                    })
                    setShowSubscriptionModal(true)
                  }}
                >
                  Subscribe
                </Button>
              </div>
            </Tooltip>
            {campaignsModule ? (
              <Button
                as={Link}
                to={`/fund/${fund.fundId}?tab=attachments`}
                iconLeft={<FolderOutlined />}
                onClick={() => {
                  trackEvent({
                    eventName: 'click',
                    element: 'View attachments',
                    fundId: fund.fundId,
                  })
                }}
              >
                Open Dataroom
              </Button>
            ) : (
              <Button
                className="flex items-center space-x-2"
                iconLeft={<FolderOutlined />}
                onClick={() => {
                  trackEvent({
                    eventName: 'click',
                    element: 'open dataroom',
                    fundId: fund.fundId,
                  })
                  onShowDetailsModal(true)
                }}
              >
                Open Dataroom
              </Button>
            )}
            {role !== 'Service Provider' && !campaignsModule && (
              <Button onClick={onSendMessage}>Ask a Question</Button>
            )}
            {campaignsModule ? (
              <>
                <ViewApplicationButton
                  campaignEntity={{
                    ...fund,
                    campaignId: fund.campaignId,
                    campaignName: fund.name,
                  }}
                />
                <ApplyToCampaignButton
                  campaignEntity={{
                    ...fund,
                    campaignId: fund.campaignId,
                    campaignName: fund.name,
                    campaignThreshold: fund.threshold,
                  }}
                  campaignType="funds"
                />
              </>
            ) : (
              <Button variant="filled" onClick={onSendMeetingRequest}>
                Send Meeting Request
              </Button>
            )}
          </>
        )}
        {fromPreview && (
          <Button
            variant="filled"
            as="a"
            href={`/discover/funds?search=${fund.name}&selectedId=${fund.fundId}`}
            target="_blank"
            iconRight={<OpenInNew />}
          >
            Open
          </Button>
        )}
        {showSubscriptionModal && (
          <FundSubscriptionModal
            visible={showSubscriptionModal}
            fundId={fund.fundId}
            email={email}
            onCancel={() => setShowSubscriptionModal(false)}
            onSubscribe={() => {
              setShowSubscriptionModal(false)
            }}
          />
        )}
      </div>
    </div>
  )
}

DiscoverFundResultFooter.propTypes = {
  fromMandate: PropTypes.bool.isRequired,
  fromPreview: PropTypes.bool,
  fund: PropTypes.shape({
    fundId: PropTypes.number.isRequired,
    name: PropTypes.string,
    isHiddenForMandate: PropTypes.bool,
    mandateApplications: PropTypes.arrayOf(
      PropTypes.shape({
        mandateApplicationId: PropTypes.number.isRequired,
        message: PropTypes.string,
      })
    ),
    campaignId: PropTypes.number,
    threshold: PropTypes.number,
    alreadySubscribed: PropTypes.bool,
  }).isRequired,
  role: PropTypes.string.isRequired,
  isSelectedForCompare: PropTypes.bool,
  onSendMeetingRequest: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onShowAddToListModal: PropTypes.func.isRequired,
  onShowDetailsModal: PropTypes.func.isRequired,
  onToggleHideFund: PropTypes.func,
  onToggleCompare: PropTypes.func.isRequired,
}

export default DiscoverFundResultFooter
