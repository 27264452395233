import PropTypes from 'prop-types'
import { Modal } from 'antd'
import PremiumTierBody from './PremiumTierBody'
import './PremiumTierModal.less'

const PremiumTierModal = ({
  onlyPro = true,
  bypassContainer = false,
  closable = false,
  visible = true,
  onModalClosed = () => {},
}) => {
  return (
    <Modal
      className="cc-modal-block"
      visible={visible}
      zIndex={5}
      width={onlyPro ? '655px' : '900px'}
      footer={null}
      closable={closable}
      mask={true}
      getContainer={bypassContainer ? null : '#cc-block-view'}
      onCancel={onModalClosed}
    >
      <PremiumTierBody onlyPro={onlyPro} />
    </Modal>
  )
}

PremiumTierModal.propTypes = {
  onlyPro: PropTypes.bool,
  bypassContainer: PropTypes.bool,
  closable: PropTypes.bool,
  visible: PropTypes.bool,
  onModalClosed: PropTypes.func,
}

export default PremiumTierModal
