import PropTypes from 'prop-types'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, Row, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { api as http } from '~/api/services'
import './PremiumTierModal.less'

const PremiumTierModalStarterPro = ({ starterList, proList }) => {
  const { goBack } = useHistory()

  const handleUpgrade = (tier) => {
    http
      .get(`/tiers/upgrademail/${tier}`)
      .then(() => {
        message.info(
          'Thanks for your interest! A member of our team will be in touch with you shortly.'
        )
      })
      .catch((err) => {
        if (err.response) {
          const { message: msg } = err.response.data
          message.error(msg)
        }
      })
      .finally(() => {
        setTimeout(() => {
          goBack()
        }, 2000)
      })
  }

  return (
    <Row>
      <Col span={11}>
        <h3 className="cc-modal-block-header-starter">
          Context365 by Apex Starter
        </h3>
        <Divider />
        <div className="cc-modal-block-listHeader">
          All features included in Free plus:
        </div>
        <div className="cc-modal-block-listSubHeader">Generate Leads</div>
        <div className="cc-modal-block-list">
          {starterList.map((text, index) => (
            <div key={index} className="cc-modal-block-check">
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#2FC5D2"
                style={{ marginRight: '5px', marginTop: '4px' }}
              />
              <p className="cc-modal-block-check-text">{text}</p>
            </div>
          ))}
        </div>
        <Button
          className="cc-modal-block-upgrade cc-modal-block-upgrade-starter"
          onClick={() => handleUpgrade('Starter')}
        >
          <FontAwesomeIcon
            style={{ marginRight: '5px' }}
            icon={faStar}
            color="#ffffff"
          />{' '}
          Contact Sales
        </Button>
        {/* <Button className="cc-modal-block-more-starter">Learn More</Button> */}
      </Col>
      <Col span={2}>
        <Divider style={{ height: '95%' }} type="vertical" />
      </Col>

      <Col span={11}>
        <h3 className="cc-modal-block-header-pro">Context365 by Apex Pro</h3>
        <Divider />
        <div className="cc-modal-block-listHeader">
          All features included in Free plus:
        </div>
        <div className="cc-modal-block-listSubHeader">Conduct Outreach</div>
        <div className="cc-modal-block-list">
          {proList.map((text, index) => (
            <div key={index} className="cc-modal-block-check">
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#C09F69"
                style={{ marginRight: '5px', marginTop: '4px' }}
              />
              <p className="cc-modal-block-check-text">{text}</p>
            </div>
          ))}
        </div>
        <Button
          className="cc-modal-block-upgrade cc-modal-block-upgrade-pro"
          onClick={() => handleUpgrade('Pro')}
        >
          <FontAwesomeIcon
            style={{ marginRight: '5px' }}
            icon={faStar}
            color="#ffffff"
          />{' '}
          Contact Sales
        </Button>
        {/* <Button className="cc-modal-block-more-pro">Learn More</Button> */}
      </Col>
    </Row>
  )
}

PremiumTierModalStarterPro.propTypes = {
  starterList: PropTypes.array.isRequired,
  proList: PropTypes.array.isRequired,
}

export default PremiumTierModalStarterPro
