import get from 'lodash/get'
import isNil from 'lodash/isNil'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import moment from 'moment'
import { api as http } from '~/api/services'
import {
  FETCH_EVENTS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
} from '~/constants/types/events'

export const getEvents = () => ({
  type: FETCH_EVENTS,
})

export const getEventsSuccess = (events, eventId = 0) => {
  const nextAttending = get(
    minBy(events.attendingEvents, (e) => moment(e.startDate)),
    'eventId',
    0
  )

  const lastAttended = get(
    maxBy(events.pastEvents, (e) => moment(e.endDate)),
    'eventId',
    0
  )

  const selectedEventId =
    eventId !== 0 ? eventId : nextAttending !== 0 ? nextAttending : lastAttended

  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: {
      selectedEventId,
      ...events,
    },
  }
}

export const getEventsFailure = (error) => ({
  type: FETCH_EVENTS_FAILURE,
  payload: error,
})

const shouldFetchEvents = (state) => !state.events.isFetching

export const fetchEvents =
  (eventId = 0) =>
  (dispatch) => {
    dispatch(getEvents())

    http
      .get('/events')
      .then((response) =>
        dispatch(getEventsSuccess(response.data.result, eventId))
      )
      .catch((thrownError) => {
        const err = isNil(thrownError.response)
          ? thrownError.message
          : thrownError.response.data.message
        dispatch(getEventsFailure(err))
      })
  }

export const fetchEventsIfNeeded =
  (eventId = 0) =>
  (dispatch, getState) => {
    if (shouldFetchEvents(getState())) {
      dispatch(fetchEvents(eventId))
    }
  }

export const getEventAgenda = (eventId) =>
  http.get(`events/${eventId}/agenda?api-version=2`)
