import PropTypes from 'prop-types'
import { Image, Modal } from 'antd'
import WinnerBanner from '~/assets/winner_banner.svg'
import Winner from '~/assets/winner.svg'
import PremiumTierModalPro from '../PremiumTierModal/PremiumTierModalPro'

const CfnNoOnboardingModal = ({
  onlyPro = true,
  bypassContainer = false,
  closable = false,
  visible = true,
  onModalClosed = () => {},
}) => {
  const options = [
    'Search for and discover managers across various investment interest categories.',
    'Access unique data and view user profiles',
    'Save searches and create customized platform alerts',
    'Send direct messages and meeting requests',
    'Schedule meetings and invite colleagues',
    'Phone, email, and chat support',
  ]
  return (
    <Modal
      className="cc-modal-block"
      visible={visible}
      width={onlyPro ? '655px' : '900px'}
      footer={null}
      closable={closable}
      mask={true}
      getContainer={bypassContainer ? null : '#cc-block-view'}
      onCancel={onModalClosed}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Image
          src={WinnerBanner}
          className="cc-modal-block-banner-small"
          preview={false}
        />
        <Image src={Winner} className="cc-modal-block-winner" preview={false} />

        <div className="cc-modal-block-body">
          <div className="cc-modal-block-header">Discover fund managers</div>
          <PremiumTierModalPro proList={options} />
        </div>
      </div>
    </Modal>
  )
}

CfnNoOnboardingModal.propTypes = {
  onlyPro: PropTypes.bool.isRequired,
  bypassContainer: PropTypes.bool,
  closable: PropTypes.bool,
  visible: PropTypes.bool,
  onModalClosed: PropTypes.func,
}
export default CfnNoOnboardingModal
