import { useState } from 'react'
import { Alert } from '@context365/alert'
import { Button } from '@context365/button'
import { Radio, RadioGroup, TimePicker, Toggle } from '@context365/forms'
import { MoreTime } from '@context365/icons'
import { Modal } from '@context365/modals'
import { DatePicker } from 'antd'
import { getHours, getMinutes, getTime } from 'date-fns'
import moment from 'moment'
import * as api from '~/api'

const CreateMeetingModal = ({ visible, conversationId, onSubmit, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedTime, setSelectedTime] = useState()
  const [duration, setDuration] = useState('30')
  const [format24hour, setFormat24Hour] = useState(false)

  const selectedTimeParsed = getTime(new Date(selectedTime))
  const meetingDateTime =
    selectedDate &&
    selectedTime &&
    selectedDate.set({
      hour: getHours(selectedTimeParsed),
      minute: getMinutes(selectedTimeParsed),
      second: 0,
    })

  const currentTimezone = new Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.replace('_', ' ')

  const resetForm = () => {
    setSelectedDate(moment())
    setSelectedTime(null)
    onClose()
  }

  return (
    <Modal
      title="Schedule online meeting"
      visible={visible}
      footerRight={
        <div className="flex flex-row gap-2">
          <Button onClick={resetForm}>Cancel</Button>
          <Button
            variant="filled"
            disabled={!meetingDateTime || meetingDateTime <= moment()}
            iconLeft={<MoreTime />}
            onClick={() => {
              api.meeting
                .createMeetingFromConversation(conversationId, {
                  meetingDateTime,
                  meetingDuration: duration,
                })
                .then(() => onSubmit())
            }}
          >
            Schedule Meeting
          </Button>
        </div>
      }
    >
      <div className="flex-col space-y-16 items-center p-4 lg:w-96">
        <Alert status="warning" className="-mb-8">
          This is for an online meeting. To meet in-person, please visit the
          Summits page.
        </Alert>
        <div>
          <div className="type-body-regular-sm mb-2">Meeting date</div>
          <DatePicker
            className="w-full"
            id="datePicker"
            value={selectedDate}
            disabledDate={(current) => current < moment().subtract(1, 'days')}
            onChange={(value) => setSelectedDate(value)}
          />
        </div>
        <div>
          <div className="w-full flex justify-between items-center">
            <div className="type-body-regular-sm">Start time</div>
            <Toggle
              checked={format24hour}
              onChange={(e) => setFormat24Hour(e.target.checked)}
              label="24 hour format"
              size="small"
              className=" mb-2"
            />
          </div>
          <TimePicker
            className="w-full"
            onChange={(value) => setSelectedTime(value)}
            value={selectedTime}
            twelveHour={!format24hour}
          />

          <div className="type-body-regular-xs">
            Use current timezone: {currentTimezone}
          </div>
        </div>
        <div>
          <div className="type-body-regular-sm mb-2">Meeting length</div>
          <RadioGroup
            name="duration"
            className="flex space-x-4"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            <Radio value="30">30 minutes</Radio>
            <Radio value="60">1 hour</Radio>
          </RadioGroup>
        </div>
        {selectedDate && selectedTime ? (
          <div className="type-body-semibold-sm text-grey-600 text-center">
            <div>Meeting will be scheduled for</div>
            <div>
              {`${meetingDateTime.format(
                `MMMM DD, YYYY ${format24hour ? 'HH:mm' : 'hh:mmA'}`
              )}
              `}
            </div>
          </div>
        ) : (
          <div className="type-body-semibold-sm text-grey-600 text-center">
            <div>Select a date and time for the meeting to take place</div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CreateMeetingModal
