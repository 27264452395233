import { Check, Comment, Notes, VisibilityOffOutlined } from '@context365/icons'
import { Tooltip } from '@context365/popovers'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'
import { formatCurrency, formatDate } from '~/utils/helpers'
import AvatarGroup from '../AvatarGroup'
import ShortenedText from '../ShortenedText'

export const Name = {
  Header: 'NAME',
  id: 'Name',
  accessor: (mandate) => {
    return <ShortenedText text={mandate.title} maxLength={35} />
  },
}
export const Description = {
  Header: 'DESCRIPTION',
  id: 'Description',
  accessor: (mandate) => {
    return <ShortenedText text={mandate.description} maxLength={35} />
  },
}
export const CurrentAssetsRange = {
  Header: 'ASSETS RANGE',
  id: 'Current Assets Range',
  accessor: (mandate) => {
    return mandate.currentAssetsRange && mandate.currentAssetsRange
  },
}
export const Contacts = {
  id: 'Contacts',
  Header: 'CONTACTS',
  accessor: (mandate) => {
    return (
      <div className="whitespace-nowrap text-left">
        {mandate.isAnonymous ? (
          <VisibilityOffOutlined className="flex justify-center w-full" />
        ) : (
          <AvatarGroup
            avatars={map(mandate.contacts, (contact) => {
              return {
                id: contact.contactId,
                firstName: contact.firstName,
                lastName: contact.lastName,
                initials: `${contact.firstName[0]}${contact.lastName[0]}`,
                description: `${contact.contactName} (${contact.jobTitle})`,
                img: contact.imageUrl,
              }
            })}
          />
        )}
      </div>
    )
  },
}
export const Applications = {
  id: 'Applications',
  Header: '',
  accessor: (mandate) => {
    const selectedApplication = mandate.mandateApplications[0]

    return (
      selectedApplication && (
        <div className="whitespace-nowrap">
          <span className="text-green-100">
            <Check className="relative top-1" />
            Applied{' '}
            {selectedApplication.fundName
              ? `with ${selectedApplication.fundName}`
              : ''}
          </span>
          <Tooltip text={selectedApplication.message || 'No message'}>
            <Comment className="ml-2 relative top-2" />
          </Tooltip>
        </div>
      )
    )
  },
}
export const BroadStrategy = {
  id: 'Broad Strategy',
  Header: 'BROAD STRATEGY',
  accessor: (mandate) => {
    return (
      mandate.strategyInterest && (
        <ShortenedText
          text={join(mandate.strategyInterest, ', ')}
          maxLength={35}
        />
      )
    )
  },
}
export const NarrowStrategy = {
  id: 'Narrow Strategy',
  Header: 'NARROW STRATEGY',
  accessor: (mandate) => {
    return (
      mandate.strategySubInterest && (
        <ShortenedText
          text={join(mandate.strategySubInterest, ', ')}
          maxLength={35}
        />
      )
    )
  },
}
export const MandateSize = {
  id: 'Mandate Size',
  Header: 'MANDATE SIZE',
  accessor: (mandate) => {
    return mandate.size && formatCurrency(mandate.size)
  },
}
export const MandateType = {
  id: 'Mandate Type',
  Header: 'MANDATE TYPE',
  accessor: (mandate) => {
    return (
      mandate.mandateType && (
        <ShortenedText text={mandate.mandateType} maxLength={35} />
      )
    )
  },
}
export const TargetCompletionDate = {
  id: 'Target Completion Date',
  Header: 'TARGET COMPLETION DATE',
  accessor: (mandate) => {
    return (
      mandate.targetCompletionDate && formatDate(mandate.targetCompletionDate)
    )
  },
}
export const Updates = {
  id: 'Updates',
  Header: 'UPDATES',
  accessor: (mandate) => {
    const updates = [
      ...mandate.mandateUpdates,
      {
        content: 'Mandate added to Context365 by Apex',
        addedOn: mandate.addedOn,
      },
    ]
    return (
      <Tooltip
        text={
          <div className="text-left">
            {map(updates, (update) => (
              <p>
                {moment.utc(update.addedOn).format('MMMM DD, YYYY')}
                {': '}
                {update.content}
              </p>
            ))}
          </div>
        }
      >
        <Notes className="flex" />
      </Tooltip>
    )
  },
}
