import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { Send } from '@context365/icons'
import Profile from '~/components/Profile'
import SendConnectionModal from '~/components/SendConnectionModal'
import ComposeMessageModal from '../ComposeMessageModal'

export default function ContactProfileHeader({ contact, profileUrl }) {
  const [composeModalVisible, setComposeModalVisible] = useState(false)
  const [sendConnectionModalVisible, setSendConnectionModalVisible] =
    useState(false)

  return (
    <>
      <Profile.Header
        profileUrl={profileUrl}
        imageUrl={contact.imageUrl}
        title={contact.fullName}
        subtitle={contact.jobTitle}
        actions={
          <>
            {contact.isConnected && (
              <Button
                iconLeft={<Send />}
                onClick={() => setComposeModalVisible(true)}
              >
                Send Message
              </Button>
            )}
            {contact.canSendConnection && (
              <Button
                variant="filled"
                onClick={() => setSendConnectionModalVisible(true)}
              >
                Send Connection Request
              </Button>
            )}
          </>
        }
      />
      <SendConnectionModal
        visible={sendConnectionModalVisible}
        contact={contact}
        onClose={() => setSendConnectionModalVisible(false)}
      />
      <ComposeMessageModal
        visible={composeModalVisible}
        onClose={() => setComposeModalVisible(false)}
        companyID={null}
        companies={contact.companies}
        requiredContactId={contact.contactId}
        selectAll={false}
      />
    </>
  )
}

ContactProfileHeader.propTypes = {
  contact: PropTypes.object.isRequired,
  profileUrl: PropTypes.string,
}
