import PropTypes from 'prop-types'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Row, message } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { api as http } from '~/api/services'
import './PremiumTierModal.less'

const PremiumTierModalPro = ({ proList }) => {
  const { goBack } = useHistory()
  const { role } = useSelector((state) => state.auth)

  const handleUpgrade = (tier) => {
    http
      .get(`/tiers/upgrademail/${tier}`)
      .then(() => {
        message.info(
          'Success! A member of our team will be in touch with you shortly.'
        )
      })
      .catch((err) => {
        if (err.response) {
          const { message: msg } = err.response.data
          message.error(msg)
        }
      })
      .finally(() => {
        setTimeout(() => {
          goBack()
        }, 2000)
      })
  }
  const handleClickForCfn = () => {
    http
      .get('/family-network/onboard/allocator')
      .then(() => {
        message.info(
          'Success! A member of our team will be in touch with you shortly.'
        )
      })
      .catch((err) => {
        if (err.response) {
          const { message: msg } = err.response.data
          message.error(msg)
        }
      })
      .finally(() => {
        setTimeout(() => {
          goBack()
        }, 2000)
      })
  }

  return (
    <Row style={{ display: 'block' }}>
      {role !== 'CFN' && (
        <h3 className="cc-modal-block-header-pro">Context365 by Apex Pro</h3>
      )}
      <Divider />
      <div className="cc-modal-block-listHeader">
        {role === 'CFN'
          ? ' '
          : 'Upgrade your plan to Context365 by Apex Pro to access all features.'}
      </div>
      <div className="cc-modal-block-list">
        {proList.map((text, index) => (
          <div key={index} className="cc-modal-block-check">
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#C09F69"
              style={{ marginRight: '5px', marginTop: '4px' }}
            />
            <p className="cc-modal-block-check-text">{text}</p>
          </div>
        ))}
      </div>
      <Button
        className="cc-modal-block-upgrade cc-modal-block-upgrade-pro"
        onClick={() =>
          role === 'CFN' ? handleClickForCfn() : handleUpgrade('Pro')
        }
      >
        <FontAwesomeIcon
          style={{ marginRight: '8px', textAlign: 'center' }}
          icon={faStar}
          color="#ffffff"
        />
        {role === 'CFN'
          ? 'Complete Investor Preferences Questionnaire'
          : 'Contact Sales'}
      </Button>
      {/* <Button className="cc-modal-block-more-pro">Learn More</Button> */}
    </Row>
  )
}

PremiumTierModalPro.propTypes = {
  proList: PropTypes.array.isRequired,
}

export default PremiumTierModalPro
