import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import * as api from '~/api'
import Loading from '~/components/Loading'
import ContactCard from '~/views/community/ContactCard'

export default function People() {
  const { companyId } = useParams()
  const { data, isLoading } = useQuery(['allocator-contacts', companyId], () =>
    api.allocators.getAllocatorContacts(companyId)
  )

  if (isLoading) {
    return (
      <div className="text-center my-16">
        <Loading />
      </div>
    )
  }

  return (
    <div>
      <div className="type-subtitle-sm text-primary-100 mb-4">
        Apex365 Members
      </div>
      <div className="grid grid-cols-3 gap-4">
        {data.map((x, i) => (
          <ContactCard
            key={i}
            contactId={x.contactId}
            contactName={`${x.firstName} ${x.lastName}`}
            imageUrl={x.imageUrl}
            jobTitle={x.title}
            matchmakingScore={x.matchmakingScore}
          />
        ))}
      </div>
    </div>
  )
}
