import PropTypes from 'prop-types'
import { BackHand } from '@context365/icons'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import './DiscoverTabSwitch.less'

const SummitAlert = ({ category, eventName, isVirtual = false }) => {
  const { push } = useHistory()

  return (
    <div className="SummitAlert">
      <BackHand color="#F19F00" className="SummitAlert-icon" />
      <span className="SummitAlert-text">
        Hold on there! You&apos;re currently viewing all {category} on the
        platform. If you&apos;re looking for {eventName} attendees{' '}
        <Button
          type="link"
          className="SummitAlert-link"
          onClick={() => push('/summits')}
        >
          navigate to the Summits event page to set up your
          {isVirtual ? ' ' : ' in-person '}meetings.
        </Button>
      </span>
    </div>
  )
}

SummitAlert.propTypes = {
  category: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  isVirtual: PropTypes.bool,
}

export default SummitAlert
